// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { handleActions } from 'redux-actions';
import { assoc, compose } from 'lodash/fp';
import { uniqBy } from 'lodash';

import {
  doGetMyEvents,
  doCreateEventReply,
  doUpdateEventReply,
  doDeleteEventReply,
  doClearMyEvents,
  doGetUnRespondedEventsCount,
  doGetFilteredUnrespondedEventsCount,
} from 'store/actionCreators/profile';
import {
  doGetMailEvents,
  doClearMailEvents,
} from 'store/actionCreators/currentCommunity';
import {
  EventResponse,
  EventType,
  EventTask,
  MyAppointmentsEvent,
} from 'types';

export type MyEventsStateLoaders = {
  events: boolean;
  event: string;
  mainLoader: boolean;
};

export type MyEventStateData = {
  eventResponses?: { [key: string]: EventResponse[] };
  eventTypes?: EventType[];
  tasks?: EventTask[];
  events?: MyAppointmentsEvent[];
  totalCount?: number;
  unrespondedEventsCount?: number;
  filteredUnrespondedEventsCount?: number;
};

export interface EventsState {
  data: MyEventStateData;
  errors: any;
  loaders: MyEventsStateLoaders;
}

const initialState: EventsState = {
  data: {
    unrespondedEventsCount: 0,
  },
  errors: {},
  loaders: {
    events: false,
    event: '',
    mainLoader: false,
  },
};

export default handleActions<EventsState, any>(
  {
    [doGetMailEvents.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'events'], true))(state),
    [doGetMailEvents.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'events'], false),
        assoc(['data'], {
          events: Array.isArray(state.data.events)
            ? state.data.events.concat(payload.events)
            : payload.events,
          totalCount: payload.totalCount,
        }),
      )(state),
    [doGetMailEvents.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'events'], false),
        assoc(['errors', 'events'], payload),
      )(state),
    [doClearMailEvents.TRIGGER]: (state) => compose(assoc(['data'], {}))(state),
    [doGetMyEvents.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'events'], true))(state),
    [doGetMyEvents.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'events'], false),
        assoc(
          ['loaders', 'mainLoader'],
          payload?.noPendingEvents ? true : false,
        ),
        assoc(['data'], {
          events: Array.isArray(state.data.events)
            ? state.data.events.concat(payload.events)
            : payload.events,
          tasks: uniqBy(
            [...payload.tasks].concat(state.data.tasks || []),
            (i) => i.id,
          ),
          eventResponses: state.data.eventResponses
            ? {
                ...state.data.eventResponses,
                ...payload.eventResponses,
              }
            : payload.eventResponses,
          totalCount: payload.totalCount,
          eventTypes: uniqBy(
            [...payload.eventTypes].concat(state.data.eventTypes || []),
            (i) => i.id,
          ),
          unrespondedEventsCount: state.data.unrespondedEventsCount,
          filteredUnrespondedEventsCount: payload.totalUnrespondedEventsCount,
        }),
      )(state),
    [doGetMyEvents.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'events'], false),
        assoc(['errors', 'events'], payload),
      )(state),
    [doClearMyEvents.TRIGGER]: (state, { payload }) =>
      compose(
        assoc(
          ['data'],
          payload?.avoidEventTypes
            ? {
                eventTypes: state.data.eventTypes,
                unrespondedEventsCount: state.data.unrespondedEventsCount,
              }
            : { unrespondedEventsCount: state.data.unrespondedEventsCount },
        ),
      )(state),
    [doCreateEventReply.TRIGGER]: (state, { payload }) =>
      compose(assoc(['loaders', 'event'], payload.eventId))(state),
    [doCreateEventReply.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'event'], null),
        assoc(
          ['data', 'events'],
          state.data.events.map((e) => {
            if (
              !payload.response.some((r) => r.subEventReply?.eventId === e.id)
            ) {
              return e;
            }

            const newEventTaskReplies = e.subEventTaskReplies.filter(
              (r) => r.userId !== payload.user.id,
            );

            const usersTaskReplies = payload.response
              .find((r) => r.subEventReply?.eventId === e.id)
              ?.subEventTaskReplies.filter((r) => r.userId === payload.user.id)
              .map((r) => ({
                ...r,
                user: payload.user,
              }));

            return {
              ...e,
              subEventReplies: [
                ...e.subEventReplies,
                {
                  ...payload.response.find(
                    (res) => res.subEventReply?.eventId === e.id,
                  )?.subEventReply,
                  user: payload.user,
                },
              ],
              subEventTaskReplies: newEventTaskReplies.concat(
                usersTaskReplies || [],
              ),
            };
          }),
        ),
      )(state),
    [doCreateEventReply.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'event'], null),
        assoc(['errors', 'events'], payload),
      )(state),
    [doUpdateEventReply.TRIGGER]: (state, { payload }) =>
      compose(assoc(['loaders', 'event'], payload.eventId))(state),
    [doUpdateEventReply.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'event'], null),
        assoc(
          ['data', 'events'],
          state.data.events.map((e) => {
            if (
              !payload.response.some((r) => r.subEventReply?.eventId === e.id)
            ) {
              return e;
            }

            const newEventReplies = e.subEventReplies.map((r) => {
              if (r.userId !== payload.user.id) {
                return r;
              }

              return {
                ...r,
                ...(payload.response.find(
                  (res) => res.subEventReply?.id === r.id,
                )?.subEventReply || {}),
              };
            });

            const newEventTaskReplies = e.subEventTaskReplies.filter(
              (r) => r.userId !== payload.user.id,
            );

            const usersTaskReplies = payload.response
              .find((r) => r.subEventReply?.eventId === e.id)
              ?.subEventTaskReplies.filter((r) => r.userId === payload.user.id)
              .map((r) => ({
                ...r,
                user: payload.user,
              }));

            return {
              ...e,
              subEventReplies: newEventReplies,
              subEventTaskReplies: newEventTaskReplies.concat(
                usersTaskReplies || [],
              ),
            };
          }),
        ),
      )(state),
    [doUpdateEventReply.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'event'], null),
        assoc(['errors', 'events'], payload),
      )(state),
    [doDeleteEventReply.TRIGGER]: (state, { payload }) =>
      compose(assoc(['loaders', 'event'], payload.eventId))(state),
    [doDeleteEventReply.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'event'], null),
        assoc(
          ['data', 'events'],
          state.data.events.map((e) => {
            if (!payload.eventIds.includes(e.id)) {
              return e;
            }

            const newEventReplies = e.subEventReplies.filter(
              (r) => r.userId !== payload.userId,
            );
            const newEventTaskReplies = e.subEventTaskReplies.filter(
              (r) => r.userId !== payload.userId,
            );

            return {
              ...e,
              subEventReplies: newEventReplies,
              subEventTaskReplies: newEventTaskReplies,
            };
          }),
        ),
      )(state),
    [doDeleteEventReply.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'event'], null),
        assoc(['errors', 'events'], payload),
      )(state),
    [doGetUnRespondedEventsCount.SUCCESS]: (state, { payload }) =>
      compose(assoc(['data', 'unrespondedEventsCount'], payload))(state),
    [doGetUnRespondedEventsCount.FAILURE]: (state) =>
      compose(assoc(['data', 'unrespondedEventsCount'], 0))(state),
    [doGetFilteredUnrespondedEventsCount.TRIGGER]: (state, { payload }) =>
      compose(assoc(['loaders', 'events'], true))(state),
    [doGetFilteredUnrespondedEventsCount.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(
          ['data', 'filteredUnrespondedEventsCount'],
          payload.filteredUnrespondedEventsCount,
        ),
        assoc(['loaders', 'events'], null),
      )(state),
    [doGetFilteredUnrespondedEventsCount.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'events'], null),
        assoc(['data', 'filteredUnrespondedEventsCount', 0]),
      )(state),
  },
  initialState,
);
