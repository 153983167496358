import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Sidebar,
  SidebarHeader,
  SidebarContent,
  ColorInput,
  SidebarInputsWrapper,
  ColorInputBlock,
  InputsBlock,
  TextArea,
  Divider,
} from 'components';
import SidebarFooter from '../common/SidebarFooter';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { useEditCommunityEventType } from './hooks';
import {
  EVENT_TYPE_DEF_AND_SETTING,
  EVENT_TYPE_FIELDS,
  EVENT_TYPE_SETTING_FIELDS,
} from './form';
import { closeModal } from 'store/actionCreators/modal';
import { getData } from 'store/selectors/modal';
import { EventTypeAndDefaultEventSetting } from 'types';
import {
  DefaultResFiledWrapper,
  ResponseWrapper,
  Responses,
  StyledLabel,
  TextH3,
  TextP,
  TextWrapper,
} from 'components/Sidebar/shared';
import { getEventResponses } from 'store/selectors/eventResponses';
import {
  StyledResponseButton,
  StyledResponseLabel,
  StyledResponseRadio,
} from 'sidebars/GiveReply/styled';
import { CommentIcon } from 'static';
import { StyledTimePickersBlock } from 'sidebars/CreateEventOverall/Event/styled';
import TimePicker from 'components/Inputs/TimePicker/TimePickerV2';
import { getdefaultEventSettingLoading } from 'store/selectors/defaultEventSetting';

const EditCommunityEventType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(getdefaultEventSettingLoading);
  const eventType = useSelector(getData) as EventTypeAndDefaultEventSetting;
  const responses = useSelector(getEventResponses);

  const handleSidebarClose = () => {
    dispatch(closeModal());
  };

  const {
    register,
    onSubmit,
    errors,
    control,
    eventResponseFieldProps,
    watchedValues,
    setValue,
  } = useEditCommunityEventType(eventType);

  return (
    <Sidebar>
      <form onSubmit={onSubmit}>
        <SidebarHeader
          label={
            eventType ? t('event.eventTypeEdit') : t('event.eventTypeCreate')
          }
          onCloseClick={handleSidebarClose}
        />
        <SidebarContent height={'calc(100svh - 108px)'}>
          <SidebarInputsWrapper gap={'10px'}>
            <InputsBlock>
              <TextInput
                name={`${EVENT_TYPE_DEF_AND_SETTING.EVENT_TYPE}.${EVENT_TYPE_FIELDS.TITLE}`}
                register={register}
                label={t('event.eventTypeName')}
                width="225px"
                error={
                  errors[EVENT_TYPE_DEF_AND_SETTING.EVENT_TYPE]?.[
                    EVENT_TYPE_FIELDS.TITLE
                  ]
                }
              />
              <ColorInputBlock>
                <ColorInput
                  name={`${EVENT_TYPE_DEF_AND_SETTING.EVENT_TYPE}.${EVENT_TYPE_FIELDS.COLOR}`}
                  control={control}
                />
              </ColorInputBlock>
            </InputsBlock>
            {watchedValues[
              `${EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING}.${EVENT_TYPE_SETTING_FIELDS.IS_SET_DEFAULT_RES}`
            ] && (
              <>
                <div>
                  <Divider customMargin="16px 0px 16px 0px" />
                  <TextWrapper>
                    <TextH3>{t('event.setPreferences')}</TextH3>
                    <TextP>{t('event.preferencesText')}.</TextP>
                  </TextWrapper>
                  <Divider customMargin="16px 0px 0px 0px" />
                </div>
                <DefaultResFiledWrapper>
                  <TextInput
                    width="100%"
                    label={t('event.eventName')}
                    name={`${EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING}.${EVENT_TYPE_SETTING_FIELDS.EVENT_TITLE}`}
                    register={register}
                    error={
                      errors[EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING]?.[
                        EVENT_TYPE_SETTING_FIELDS.EVENT_TITLE
                      ]
                    }
                  />
                  <TextInput
                    width="100%"
                    label={t('event.location')}
                    name={`${EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING}.${EVENT_TYPE_SETTING_FIELDS.LOCATION}`}
                    register={register}
                    error={
                      errors[EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING]?.[
                        EVENT_TYPE_SETTING_FIELDS.LOCATION
                      ]
                    }
                  />
                  <StyledTimePickersBlock>
                    <TimePicker
                      label={t('common.startTime')}
                      width="100%"
                      name={`${EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING}.${EVENT_TYPE_SETTING_FIELDS.START_TIME}`}
                      control={control}
                      setValue={setValue}
                      watchFieldName={`${EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING}.${EVENT_TYPE_SETTING_FIELDS.END_TIME}`}
                      formWatcher={watchedValues}
                    />
                    <TimePicker
                      label={t('common.endTime')}
                      width="100%"
                      name={`${EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING}.${EVENT_TYPE_SETTING_FIELDS.END_TIME}`}
                      control={control}
                      setValue={setValue}
                      watchFieldName={`${EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING}.${EVENT_TYPE_SETTING_FIELDS.START_TIME}`}
                      formWatcher={watchedValues}
                    />
                  </StyledTimePickersBlock>
                  <TextArea
                    width="100%"
                    height="100px"
                    label={t('common.description')}
                    name={`${EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING}.${EVENT_TYPE_SETTING_FIELDS.DESCRIPTION}`}
                    register={register}
                    error={
                      errors[EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING]?.[
                        EVENT_TYPE_SETTING_FIELDS.DESCRIPTION
                      ]
                    }
                  />
                  <ResponseWrapper>
                    <StyledLabel>{t('event.dfRes')}</StyledLabel>
                    <Responses>
                      {responses.map((response) => (
                        <StyledResponseButton key={response.id}>
                          <StyledResponseRadio
                            color={response.color}
                            name="responses"
                            type="radio"
                            id={response.id}
                            value={response.id}
                            checked={
                              eventResponseFieldProps.value === response.id
                            }
                            onClick={() =>
                              eventResponseFieldProps.onChange(
                                eventResponseFieldProps.value === response.id
                                  ? null
                                  : response.id,
                              )
                            }
                          />
                          <StyledResponseLabel
                            color={response.color}
                            htmlFor={response.id}
                          >
                            {response.title}
                            {response.isCommentRequired && (
                              <CommentIcon width={18} height={18} />
                            )}
                          </StyledResponseLabel>
                        </StyledResponseButton>
                      ))}
                    </Responses>
                  </ResponseWrapper>
                </DefaultResFiledWrapper>
              </>
            )}
          </SidebarInputsWrapper>
        </SidebarContent>
        <SidebarFooter isLoading={isLoading} isDeleteButtonVisible={false} />
      </form>
    </Sidebar>
  );
};

export default EditCommunityEventType;
