import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  padding: 34px 17px 0px 17px;
  gap: 16px;
`;

export const Name = styled.div`
  padding-top: 16px;
`;

export const Description = styled.div`
  padding-top: 16px;
`;

export const ListWrapper = styled.div``;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
`;

export const SkeletonListWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
  flex-direction: column;
  gap: 8px;
  padding-inline: 16px;
`;
