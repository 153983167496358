import { createSelector } from 'reselect';

import { IRootState } from '../reducers';
import { AuthState } from '../reducers/auth';

const authSelector = (state: IRootState): AuthState => state.auth;

export const getAuthAuthenticated = createSelector(
  authSelector,
  (auth) => auth.isAuthenticated,
);

export const getFullAuthUser = createSelector(
  authSelector,
  (auth) => auth.user || null,
);

export const getAuthLoading = createSelector(
  authSelector,
  (auth) => auth.isLoading,
);

export const getAuthUser = createSelector(
  authSelector,
  (auth) => auth.user?.profile || null,
);

export const getCurrentIdToken = createSelector(
  authSelector,
  (auth) => auth.user?.idToken || null,
);

export const getMyId = createSelector(
  getAuthUser,
  (user) => user?.userId || '',
);

export const getNotifications = createSelector(
  authSelector,
  (auth) => auth.user?.notifications?.data,
);

export const getNotificationsUnreadCount = createSelector(
  authSelector,
  (auth) => auth.user?.notifications?.unreadCount,
);

export const getMessageUnSeenCount = createSelector(
  authSelector,
  (auth) => auth.user?.unseenChatCount,
);

export const getMessageSeenCountLoading = createSelector(
  authSelector,
  (auth) => auth.loaders.unSeenMessageCount,
);

export const getSettings = createSelector(
  authSelector,
  (auth) => auth.user?.settings,
);

export const getEditProfileLoading = createSelector(
  authSelector,
  (auth) => auth.loaders.editProfile,
);

export const getChangeProfileSettingsLoading = createSelector(
  authSelector,
  (auth) => auth.loaders.changeProfileSettings,
);

export const getLinkCalendarLoading = createSelector(
  authSelector,
  (auth) => auth.loaders.linkGoogleCalendar,
);

export const getUnlinkCalendarLoading = createSelector(
  authSelector,
  (auth) => auth.loaders.unlinkGoogleCalendar,
);

export const getAppleCalendarConnectLoading = createSelector(
  authSelector,
  (auth) => auth.loaders.linkAppleCalendar,
);

export const getAppleCalendarDisconnectLoading = createSelector(
  authSelector,
  (auth) => auth.loaders.unLinkAppleCalendar,
);
