import styled from 'styled-components';

import { CheckboxCheckedIcon, CheckboxDefaultIcon } from 'static';

export const StyledSelectAllButton = styled.button`
  width: 14px;
  height: 14px;
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-image: ${({ checked }) =>
    `url(${checked ? CheckboxCheckedIcon : CheckboxDefaultIcon})`};
  cursor: pointer;
`;

export const StyledHeader = styled.div`
  min-height: 30px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: transparent;
  font-weight: normal;
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
`;

export const StyledHeaderLabel = styled.span`
  margin-left: 11px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.baliHai};
  font-weight: 600;
`;

export const StyledListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledListItem = styled.div`
  height: 61px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.porcelain}`};
  font-weight: 400;
  font-size: 15px;
  ${({ disabled }) =>
    disabled && {
      backgroundColor: '#EBEBE4',
    }};
`;

export const StyledNameWrapper = styled.div`
  width: 290px;
`;

export const StyledGroupItem = styled.div`
  background-color: #ebf2f7;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #8298ab;
  padding-left: 15px;
  font-size: 12px;
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  height: ${({ height }) => height};
  overflow-x: hidden;
  > div {
    height: auto !important;
  }
  overflow-x: hidden;
`;

export const StyledSearchWrapper = styled.div`
  padding: 13px 16px 14px 15px;

  input {
    padding-right: 48px;
  }
`;
