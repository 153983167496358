import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, ProfileName } from 'components';
import { getProfileName } from 'utils';
import {
  getTaskValues,
  getUserValues,
} from '../../CommunityOverallScheduler/Schedule/values';
import {
  Community,
  EventResponse,
  EventTask,
  CommunityUser,
  EventToRender,
  EventReply,
  User,
  EventGridItem,
  GroupUser,
} from 'types';

export const useGridData = ({
  tasks,
  adminMembers,
  groupLeads,
  members,
  eventsToRender,
  responses,
  openGiveReplySidebar,
  openTaskDetailsSidebar,
  currentUser,
  currentUserInGroup,
  zoom,
  openViewMemberSidebar,
  community,
  searchFilter,
}: {
  searchFilter: string;
  tasks: EventTask[];
  adminMembers: GroupUser[];
  groupLeads: GroupUser[];
  members: GroupUser[];
  eventsToRender: EventToRender[];
  responses: EventResponse[];
  openGiveReplySidebar: (props: {
    id: string;
    eventId: string;
    eventDate: Date;
    subReply?: EventReply;
    subTaskReplies?: string[];
    isViewOnly: boolean;
    isAdmin: boolean;
    name: string;
  }) => void;
  openTaskDetailsSidebar: (task: EventTask, userIds: string[]) => void;
  currentUser: CommunityUser;
  currentUserInGroup: any;
  zoom: number;
  openViewMemberSidebar: (user: User) => void;
  community: Community;
}): EventGridItem[] => {
  const { t } = useTranslation();
  const tasksData = useMemo<EventGridItem[]>(() => {
    return Array.isArray(tasks)
      ? [
          {
            type: 'group',
            id: 'tasks',
            label: t('common.tasks'),
          },
          ...tasks.map((task) => ({
            type: 'task',
            id: task.id,
            group: 'tasks',
            label: task.title,
            values: getTaskValues(task, eventsToRender, openTaskDetailsSidebar),
            icon: <Icon icon={task.icon} />,
          })),
        ]
      : [];
  }, [tasks, eventsToRender]);

  const getUserData = (user: GroupUser, groupName: string) => ({
    type: 'user',
    id: user.profile.userId,
    group: groupName,
    label: (
      <ProfileName
        searchFilter={searchFilter}
        avatar={user.profile.smallLogo || user.profile.logo}
        name={getProfileName(user.profile)}
        communityPermission={user.communityPermission}
        groupPermission={user.groupPermission}
        size="sm"
        zoom={zoom}
        onClick={() => {
          openViewMemberSidebar(user);
        }}
      />
    ),
    values: getUserValues({
      userId: user.profile.userId,
      responses,
      eventsToRender,
      openGiveReplySidebar,
      currentUser,
      currentUserInGroup,
      name: getProfileName(user.profile),
      community,
    }),
    onClick: () => openViewMemberSidebar(user),
  });

  const groupsData = useMemo<EventGridItem[]>(() => {
    return [
      {
        type: 'group',
        id: 'admin',
        label: t('common.admin'),
      },
      ...adminMembers.map((member) => getUserData(member, 'admin')),
      {
        type: 'group',
        id: 'groupLead',
        label: t('common.group_lead'),
      },
      ...groupLeads.map((member) => getUserData(member, 'groupLead')),
      {
        type: 'group',
        id: 'member',
        label: t('common.member'),
      },
      ...members.map((member) => getUserData(member, 'member')),
    ];
  }, [eventsToRender, adminMembers, groupLeads, members, zoom]);

  return useMemo(() => {
    return [...tasksData, ...groupsData];
  }, [tasksData, groupsData]);
};
