import { Profile, User, UserWithProfile } from './user';
import { Group, GroupUser } from './group';
import { Subscription } from './subscription';
import { EventResponse } from './eventResponse';
import { Documentation } from './document';
import { EventType, EventTypeAndDefaultEventSetting } from './eventType';
import { EventTask } from './eventTask';
import { Event } from './event';

export type CommunitySocialLink = {
  label: string;
  value: string;
};

export enum CommunityRoles {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member',
  BLOCKED = 'blocked',
}

export enum CommunityInviteStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum CommunityNotificationClass {
  IN_APP = 'inApp',
  EMAIL = 'email',
  PUSH = 'push',
}

export enum CommunityNotificationFilters {
  ENABLED = 'enabled',
  PENALTY = 'penalty',
  ATTENDED = 'attended',
  AWAITING = 'awaiting',
  RATES = 'rates',
  REPLIES = 'replies',
  FIELDS = 'fields',
}

export enum CommunityNotificationActions {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  REMINDERS = 'reminders',
}

export type CommunityNotificationValues = {
  [CommunityNotificationFilters.ENABLED]: boolean;
  [CommunityNotificationFilters.PENALTY]?: boolean;
  [CommunityNotificationFilters.ATTENDED]?: boolean;
  [CommunityNotificationFilters.AWAITING]?: boolean;
  [CommunityNotificationFilters.RATES]?: {
    modifier: 'minutes' | 'hours' | 'days';
    value: number;
  };
  [CommunityNotificationFilters.REPLIES]?: string[];
  [CommunityNotificationFilters.FIELDS]?: string[];
};

export type CommunityNotificationByType = {
  [key in CommunityNotificationClass]: CommunityNotificationValues;
};

export type CommunityPermission = {
  role: CommunityRoles;
  communityId: string;
  userId: string;
};

export type InviteRequest = {
  id: string;
  communityId: string;
  userId: string;
  status: CommunityInviteStatus;
  user: User & { profile: Profile };
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type CommunitySubscriptionInfo = {
  bucketSize: number;
  communities: number;
  groups: number;
  users: number;
};

export type CommunityNotifications = {
  events: {
    [key in CommunityNotificationActions]: CommunityNotificationByType;
  };
};

export type CommunityGroup = {
  id: string;
  name: string;
  order: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  communityId: string;
  users: GroupUser[];
};

type CommunityCommonData = {
  id: string;
  name: string;
  description: string;
  logo: string | null;
  cover: string | null;
  category: string;
  inviteLink: string;
  invitationCode: string | null;
  links: CommunitySocialLink[];
  notifications: CommunityNotifications;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  groups: CommunityGroup[];
  usersCount: number;
  communityOwner: Profile;
  groupsCount: number;
  requests: InviteRequest[];
  isSubscriptionActive?: boolean;
};

export type CommunityCoverDetails = {
  mimetype?: string;
  filename: string;
  size?: number; //in GB
  ext?: string;
  delete: boolean;
};

export type CommunityListItem = CommunityCommonData & {
  users: (User & { communityPermission: CommunityPermission })[];
};

export type ChatUser = Profile & { online: boolean };

export type CommunityUser = UserWithProfile & {
  communityPermission: CommunityPermission;
  groups?: CommunityGroup[];
  unreadCount?: number;
};

export type CommunityOverallSchedulerUser = UserWithProfile & {
  communityPermission: CommunityPermission;
};

export type CommunityTotalItem = {
  count: string;
  date: string;
  eventResponseId: string;
  guests: string;
  id: string;
};

export type CommunityTotal = {
  data?: CommunityTotalItem[];
};

export type CommunityOverallScheduler = CommunityCommonData & {
  events: Event[];
  users: CommunityOverallSchedulerUser[];
  total: CommunityTotal;
  totalCountEvents?: number;
};

export type OverallStatisticsReply = {
  attended: string;
  penalty: string;
  eventResponseId: string;
  guests: string;
  userId: string;
  count: string;
};

export type OverallStatisticsEventType = {
  eventTypeId: string;
  userId: string;
  count: string;
};

export type OverallStatistics = {
  replies: OverallStatisticsReply[];
  repliesToEventTypes: OverallStatisticsEventType[];
};

export type CommunityMembersByGroups = {
  id: string;
  name: string;
  order: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  communityId: string;
  users: GroupUser[];
};

export type PushTemplate = {
  id: string;
  title: string;
  message: string;
  createdAt: string;
  isTemplate: boolean;
};

export type Community = CommunityCommonData & {
  users: CommunityUser[];
  info: CommunitySubscriptionInfo;
  subscription: Subscription;
  // chatUsers: ChatUser[];
  eventResponses: EventResponse[];
  documentation: Documentation;
  eventTypes: EventType[];
  defaultEventSetting?: EventTypeAndDefaultEventSetting[];
  eventTasks: EventTask[];
  overallScheduler?: CommunityOverallScheduler;
  events: Event[];
  membersByGroups?: CommunityMembersByGroups[];
  statistic?: OverallStatistics;
  invites?: InviteRequest[];
  totalCountEvents?: number;
  userPushTemplates?: PushTemplate[];
  bulkEventUpload?: {
    uploadEventsCount: number;
    uploadStatus: 'completed' | '';
  };
};
