import React, { FC } from 'react';

import { Wrapper, DateLabel } from './styled';
// import { Divider } from 'components';

interface DateSeparatorProps {
  date: string;
  handleScroll: (date: string) => void;
}

const DateSeparator: FC<DateSeparatorProps> = ({ date, handleScroll }) => {
  return (
    <Wrapper onClick={() => handleScroll(date)} id={date}>
      <DateLabel>{date}</DateLabel>
    </Wrapper>
  );
};

export default DateSeparator;
