import React, { useEffect, useMemo, useRef } from 'react';
import { AutoSizer, List } from 'react-virtualized';

import { useRoles, useGrid } from './hooks';

import { SectionWrapper } from '../styled';

const GroupMembers = ({
  t,
  filterValue,
  overallScheduler,
  headerHeight,
  communityId,
}) => {
  const listRef = useRef(null);
  const usersData = useRoles(t, overallScheduler);
  const { rowRenderer, onClick, filteredData } = useGrid(
    usersData,
    filterValue,
    communityId,
  );

  const headHeight = useMemo(() => {
    return headerHeight;
  }, [headerHeight]);

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.recomputeRowHeights();
    }
  }, [filteredData]);

  return (
    <SectionWrapper className={'members'} height={headHeight}>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <div onClick={onClick}>
          <AutoSizer>
            {({ width }) => (
              <List
                ref={listRef}
                rowRenderer={rowRenderer}
                height={
                  filteredData.filter((item) => item.type === 'group').length *
                    37 +
                  filteredData.filter((item) => item.type !== 'group').length *
                    61
                }
                rowCount={filteredData.length}
                width={width}
                rowHeight={({ index }) =>
                  filteredData[index].type === 'group' ? 37 : 61
                }
              />
            )}
          </AutoSizer>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default GroupMembers;
