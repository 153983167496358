import React, { useState, useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CheckBox,
  Loader,
  PopupEventWrapper,
  Tooltip,
} from '../../index';
import { CommunityGroup, Document } from 'types';

import { Wrapper, LeftList, RightList } from './styled';

const IMG_MIME_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];

interface DocumentationPopupProps {
  isOpen: boolean;
  data: Document[];
  groups: CommunityGroup[];
  selectedDocuments: string[];
  onSubmit: (values: Document[]) => void;
  closePopup: () => void;
  setSelectedDocuments: (values: string[]) => void;
  onRemoveDocument: (document: Document) => void;
}

const DocumentationPopup: FC<DocumentationPopupProps> = ({
  isOpen,
  data = [],
  groups,
  selectedDocuments,
  onSubmit,
  closePopup,
  setSelectedDocuments,
  onRemoveDocument = () => {},
}) => {
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] = useState<string>('overall');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const groupsOptions = [
    { value: 'overall', label: t('profileCommunities.table.labelCommunity') },
    ...groups.map((g) => ({ value: g.id, label: g.name })),
  ];

  const getFilteredDocuments = useCallback(
    () =>
      data.filter((i) =>
        selectedGroup === 'overall' ? !i.groupId : i.groupId === selectedGroup,
      ),
    [selectedGroup, data],
  );

  const handleDocumentClick = (id) => {
    if (selectedDocuments.includes(id)) {
      setSelectedDocuments(selectedDocuments.filter((i) => i !== id));
      onRemoveDocument(data.find((i) => i.id === id));
    } else {
      setSelectedDocuments([...selectedDocuments, id]);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    await onSubmit(data.filter((i) => selectedDocuments.includes(i.id)));
    setSubmitting(false);
    closePopup();
  };

  return (
    <PopupEventWrapper
      darkBackground={true}
      innerComponent={
        <Wrapper>
          <LeftList>
            {groupsOptions.map((i) => (
              <LeftList.Item
                key={i.value}
                selected={i.value === selectedGroup}
                onClick={() => setSelectedGroup(i.value)}
              >
                {i.label}
              </LeftList.Item>
            ))}
          </LeftList>
          <RightList>
            {getFilteredDocuments().map((i) => (
              <RightList.Item
                key={i.id}
                onClick={() => handleDocumentClick(i.id)}
              >
                <div>
                  <CheckBox
                    isSelected={selectedDocuments.includes(i.id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleDocumentClick(i.id);
                    }}
                  />
                  <Tooltip
                    isVisible
                    opacity={IMG_MIME_TYPES.includes(i.mime) ? '1' : '0.7'}
                    position={
                      IMG_MIME_TYPES.includes(i.mime)
                        ? 'left-center'
                        : 'top-center'
                    }
                    offset={IMG_MIME_TYPES.includes(i.mime) ? 42 : 20}
                    visibleAfterWidth={
                      IMG_MIME_TYPES.includes(i.mime) ? 0 : 325
                    }
                    text={
                      IMG_MIME_TYPES.includes(i.mime) ? (
                        <img
                          src={i.url}
                          style={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'cover',
                          }}
                        />
                      ) : (
                        i.name
                      )
                    }
                    tooltipStyle={{
                      whiteSpace: 'pre-wrap',
                      maxWidth: '200px',
                      height: 'auto',
                    }}
                  >
                    <div>{i.name}</div>
                  </Tooltip>
                </div>
                <a
                  href={i.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <RightList.Item.PreviewIcon />
                </a>
              </RightList.Item>
            ))}
          </RightList>
          <Button
            type="button"
            width="210px"
            variant={submitting ? 'secondary' : 'primary'}
            onClick={handleSubmit}
          >
            {submitting ? (
              <Loader type="button" size="28px" thickness={2} />
            ) : (
              t('documentation.attachFiles')
            )}
          </Button>
        </Wrapper>
      }
      isOpen={isOpen}
      popupMessageText={t('email.attachCommunityDocs')}
      buttonContainerStyles={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
      popupStyles={{
        maxWidth: '990px',
        width: 'auto',
        height: 'auto',
        padding: '30px 30px 80px 30px',
      }}
      onPopupClose={closePopup}
    />
  );
};

export default DocumentationPopup;
