import styled, { keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';

const pulse = keyframes`
    0% {
        transform: scale(1.3);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.3);
    }
`;

const pulseRing = keyframes`
    0% {
        transform: scale(.33);
    }
    80%, 100% {
        opacity: 0;
    }
`;

const Layout = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px 0 rgba(48, 70, 89, 0.2);
  background-color: ${({ theme }) => theme.color.dew};
  height: 100svh;
  margin-left: 60px;
  position: fixed;
  z-index: 100000000;
  background: ${({ theme }) => theme.color.aquaSqueeze};
`;

Layout.Header = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 0 17px;
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  position: relative;
`;

Layout.Header.Name = styled.span`
  width: 100%;
  font-size: 16px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-weight: bold;
  padding-right: 35px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
`;

Layout.Notifications = styled(NavLink)`
  position: relative;
  &.active {
    & > button {
      background-color: #16330021;
    }
    path:nth-child(1) {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  row-gap: 2px;
  padding: 0px 4px 0px 4px;
  a {
    text-decoration: none;
    &:hover > div {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
    &:hover > div path {
      fill: ${({ theme }) => theme.color.pickledBluewood};
      stroke: ${({ theme }) => theme.color.pickledBluewood};
    }
    &:hover > div circle {
      stroke: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;

List.Item = styled(NavLink)`
  position: relative;
  display: flex;
  padding: 10px 15px 10px 16px;
  border-radius: 6px;
  align-items: center;
  background-color: ${({ theme, isActive }) =>
    isActive ? '#ebf2f7' : '#F6FBFA'};

  ${({ isActive }) =>
    isActive &&
    `
        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 2px;
          height: 18px;
          left: 5px;
          background-color: ${({ theme }) => theme.color.pickledBluewood};
        }
      `}

  &:hover {
    [aria-label='NEW'] {
      fill: ${({ theme }) => theme.color.pickledBluewood} !important;
    }
    background-color: #ebf2f7;
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 2px;
      height: 18px;
      left: 5px;
      background-color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }

  & > div {
    color: ${({ theme, isActive }) =>
      isActive ? theme.color.pickledBluewood : theme.color.baliHai};
    path {
      fill: ${({ theme, isActive }) =>
        isActive ? theme.color.pickledBluewood : theme.color.baliHai};
      stroke: ${({ theme, isActive }) =>
        isActive ? theme.color.pickledBluewood : theme.color.baliHai};
    }
    circle {
      stroke: ${({ theme, isActive }) =>
        isActive ? theme.color.pickledBluewood : theme.color.baliHai};
    }
  }
`;

List.Item.Icon = styled.div`
  position: absolute;

  svg {
    width: 12px;
    height: 12px;
    ${({ iconStyles }) => iconStyles};
  }
`;

List.Item.Link = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  margin-left: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: left;
`;

const UnrespondedEventsCircle = styled.div`
  width: ${({ small }) => (small ? '18px' : '25px')};
  height: ${({ small }) => (small ? '18px' : '25px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  right: ${({ positionRight = '12px' }) => positionRight};
  left: ${({ positionLeft }) => positionLeft};
  top: ${({ positionTop }) => positionTop};

  span {
    font-size: ${({ small }) => (small ? '10px' : '10px')};
    color: white;
    font-weight: 700;
    z-index: 100;
    animation: ${pulse} 1.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: ${({ small }) => (small ? '40px' : '60px')};
    height: ${({ small }) => (small ? '40px' : '60px')};
    box-sizing: border-box;
    border-radius: 50%;
    background-color: #f89387;
    animation: ${pulseRing} 1.7s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    animation-delay: 0.85s;
    z-index: 100;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: ${({ small }) => (small ? '18px' : '25px')};
    height: ${({ small }) => (small ? '18px' : '25px')};
    background-color: ${({ theme }) => theme.color.bittersweet};
    border-radius: 50%;
    box-shadow: 0 0 8px #f65542;
    animation: ${pulse} 1.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
`;

export { Layout, List, UnrespondedEventsCircle };
