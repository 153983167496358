import styled, { css } from 'styled-components';

import { EyeIcon, EyeHideIcon } from 'static';

export const ShowPasswordIcon = styled(EyeIcon)`
  width: 20px;
  height: 20px;
`;

export const HidePasswordIcon = styled(EyeHideIcon)`
  width: 20px;
  height: 20px;
`;
export const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  label:last-child {
    margin: 10px 10px 2px 0px;
  }
`;

export const ShowPasswordButton = styled.button`
  border: none;
  background: none;
  outline: none;
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  padding: 9px;
  right: 5px;

  &:hover ${ShowPasswordIcon} {
    path {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;

export const StyledInput = styled.input`
  height: ${({ height = '39px' }) => height};
  width: 100%;
  max-width: ${({ width }) => width || '340px'};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.color.bittersweet : theme.color.botticelli};
  border-radius: 4px;
  outline: none;
  padding-left: 14px;
  font-weight: 600;
  font-size: 13px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  padding-right: ${({ withShowIcon }) => (withShowIcon ? '47px' : '10px')};
  line-height: normal;

  &:focus:enabled {
    border-color: ${({ theme }) => theme.color.brightTurquoise};
  }

  &:disabled,
  &:read-only {
    border-color: ${({ theme }) => theme.color.blackSqueeze};
    color: ${({ theme }) => theme.color.pickledBluewood};
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  ${({ position }) =>
    position &&
    css`
      position: relative;
    `}
`;

export const StyledIconsBlock = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;
`;

export const StyledRightIcon = styled.img`
  cursor: pointer;
  margin-left: 16px;
  ${({ filter }) =>
    filter &&
    `
        filter: brightness(0) saturate(100%) invert(73%) sepia(79%) saturate(2725%) hue-rotate(128deg) brightness(95%) contrast(87%);
        cursor: pointer;
    `};
  ${({ disabled }) =>
    disabled &&
    `
        filter: brightness(0) saturate(100%) invert(89%) sepia(7%) saturate(1004%) hue-rotate(171deg) brightness(84%) contrast(91%);
    `};
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledInfoIcon = styled.div`
  display: flex;
  cursor: pointer;
`;
