import React, { FC, useMemo } from 'react';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { DndRow } from 'components';
import { CellsTypes } from 'constants/index';
import { CommunityRoles, CommunityUser, GroupRoles, GroupUser } from 'types';
import { breakpoints } from 'theme';

import { Container, Header, HeaderColumn, PlusButtonColumn } from './styled';

interface GroupRolesListProps {
  users: GroupUser[];
  usersRole: GroupRoles;
  config: any;
  currentUser: CommunityUser;
  currentUserInGroup: GroupUser;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  search: string;
}

const GroupRolesList: FC<GroupRolesListProps> = ({
  users,
  usersRole,
  onEdit,
  config,
  currentUser,
  onDelete,
  currentUserInGroup,
  search,
}) => {
  const { t } = useTranslation();
  const isDownMd = useMedia(breakpoints.downMd);

  const canDrop = useMemo(() => {
    if (
      [CommunityRoles.OWNER, CommunityRoles.ADMIN].includes(
        currentUser.communityPermission.role,
      )
    ) {
      return true;
    }

    switch (currentUserInGroup?.groupPermission.role) {
      case GroupRoles.MEMBER:
        return false;
      case GroupRoles.GROUP_LEAD:
        return [GroupRoles.GROUP_LEAD, GroupRoles.MEMBER].includes(usersRole);
      case GroupRoles.ADMIN:
        return true;
      default:
        return false;
    }
  }, [usersRole, currentUser, currentUserInGroup]);

  return (
    <Droppable droppableId={usersRole} isDropDisabled={!canDrop}>
      {(provided) => (
        <Container {...provided.droppableProps} ref={provided.innerRef}>
          <Header>
            <HeaderColumn width="5%"></HeaderColumn>
            <HeaderColumn width="28%">{t(`common.${usersRole}`)}</HeaderColumn>
            <HeaderColumn width="7%" />
            <HeaderColumn width="53%">{t('common.email')}</HeaderColumn>
            <PlusButtonColumn width="7%" />
          </Header>
          {users.map((user, index) => (
            <Draggable
              key={user.id}
              draggableId={user.id}
              index={index}
              isDragDisabled={!canDrop}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={{
                    ...provided.draggableProps.style,
                    width: isDownMd ? 1000 : '100%',
                  }}
                >
                  <DndRow
                    search={search}
                    dragHandleProps={provided.dragHandleProps}
                    canDrag={canDrop}
                    dataItem={user}
                    config={config}
                    onEditRow={onEdit}
                    onDelete={onDelete}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
          {isEmpty(users) ? (
            <DndRow
              search={search}
              dataItem={{
                id: '1111',
                text: t('common.tablePlaceholder'),
              }}
              config={{
                title: config.title,
                withDnD: config.withDnD,
                role: config.role,
                headers: [
                  {
                    id: 'placeholder',
                    width: '100%',
                    type: CellsTypes.PLACEHOLDER,
                  },
                ],
              }}
            />
          ) : null}
        </Container>
      )}
    </Droppable>
  );
};

export default GroupRolesList;
