import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import { Community } from '../types';

export enum messageTypes {
  CREATE_COMMUNITY_EVENT_REPLY = 'CREATE_COMMUNITY_EVENT_REPLY',
  UPDATE_COMMUNITY_EVENT_REPLY = 'UPDATE_COMMUNITY_EVENT_REPLY',
  DELETE_COMMUNITY_EVENT_REPLY = 'DELETE_COMMUNITY_EVENT_REPLY',
  CREATE_GROUP_EVENT_REPLY = 'CREATE_GROUP_EVENT_REPLY',
  UPDATE_GROUP_EVENT_REPLY = 'UPDATE_GROUP_EVENT_REPLY',
  DELETE_GROUP_EVENT_REPLY = 'DELETE_GROUP_EVENT_REPLY',
  CREATE_COMMUNITY_EVENT = 'CREATE_COMMUNITY_EVENT',
  UPDATE_COMMUNITY_EVENT = 'UPDATE_COMMUNITY_EVENT',
  DELETE_COMMUNITY_EVENT = 'DELETE_COMMUNITY_EVENT',
  CREATE_GROUP_EVENT = 'CREATE_GROUP_EVENT',
  UPDATE_GROUP_EVENT = 'UPDATE_GROUP_EVENT',
  DELETE_GROUP_EVENT = 'DELETE_GROUP_EVENT',
  CREATE_EVENT_TASK = 'CREATE_EVENT_TASK',
  UPDATE_EVENT_TASK = 'UPDATE_EVENT_TASK',
  DELETE_EVENT_TASK = 'DELETE_EVENT_TASK',
  CREATE_GROUP = 'CREATE_GROUP',
  UPDATE_GROUP = 'UPDATE_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  UPDATE_COMMUNITY = 'UPDATE_COMMUNITY',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  GET_COMMUNITY_INVITES = 'GET_COMMUNITY_INVITES',
  NOTIFICATION = 'notification',
  COMMUNITY_INVITE_APPROVED = 'COMMUNITY_INVITE_APPROVED',
  ACTIVATE_SUBSCRIPTION = 'ACTIVATE_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
  SUBSCRIPTION_REMINDER = 'SUBSCRIPTION_REMINDER',
  CREATE_CHAT_GROUP = 'CREATE_CHAT_GROUP',
}

const disabled = (): false => false;

const isOnPageWithOverallEvents = (): boolean => {
  const location = window.location;

  return (
    location.pathname.includes(COMMUNITY_ROUTES.OVERALL) ||
    (location.pathname.includes(COMMUNITY_ROUTES.GROUP) &&
      !location.pathname.includes(COMMUNITY_ROUTES.SETTINGS))
  );
};

const isOnGroupScheduler = (): boolean => {
  const location = window.location;

  return (
    location.pathname.includes(COMMUNITY_ROUTES.GROUP) &&
    !location.pathname.includes(COMMUNITY_ROUTES.SETTINGS)
  );
};

const isOnCommunityWithUser = ({
  currentCommunity,
  userId,
}: {
  currentCommunity: Community;
  userId: string;
}): boolean => {
  return currentCommunity?.users?.some((u) => u.id === userId);
};

const isOnCommunityPage = ({
  communityId,
}: {
  communityId: string;
}): boolean => {
  const location = window.location;

  return location.pathname.includes(`${ROUTES.COMMUNITY}/${communityId}`);
};

const anyRoute = (): boolean => true;

export const typeToRoutes = {
  [messageTypes.CREATE_COMMUNITY_EVENT_REPLY]: isOnPageWithOverallEvents,
  [messageTypes.UPDATE_COMMUNITY_EVENT_REPLY]: isOnPageWithOverallEvents,
  [messageTypes.DELETE_COMMUNITY_EVENT_REPLY]: isOnPageWithOverallEvents,
  [messageTypes.CREATE_GROUP_EVENT_REPLY]: isOnGroupScheduler,
  [messageTypes.UPDATE_GROUP_EVENT_REPLY]: isOnGroupScheduler,
  [messageTypes.DELETE_GROUP_EVENT_REPLY]: isOnGroupScheduler,
  [messageTypes.CREATE_COMMUNITY_EVENT]: disabled,
  [messageTypes.UPDATE_COMMUNITY_EVENT]: isOnPageWithOverallEvents,
  [messageTypes.DELETE_COMMUNITY_EVENT]: isOnPageWithOverallEvents,
  [messageTypes.CREATE_GROUP_EVENT]: disabled,
  [messageTypes.UPDATE_GROUP_EVENT]: isOnGroupScheduler,
  [messageTypes.DELETE_GROUP_EVENT]: isOnGroupScheduler,
  [messageTypes.CREATE_EVENT_TASK]: isOnPageWithOverallEvents,
  [messageTypes.UPDATE_EVENT_TASK]: isOnPageWithOverallEvents,
  [messageTypes.DELETE_EVENT_TASK]: isOnPageWithOverallEvents,
  [messageTypes.CREATE_GROUP]: isOnCommunityPage,
  [messageTypes.UPDATE_GROUP]: isOnCommunityPage,
  [messageTypes.DELETE_GROUP]: isOnCommunityPage,
  [messageTypes.UPDATE_COMMUNITY]: isOnCommunityPage,
  [messageTypes.UPDATE_PROFILE]: isOnCommunityWithUser,
  [messageTypes.GET_COMMUNITY_INVITES]: anyRoute,
  [messageTypes.COMMUNITY_INVITE_APPROVED]: anyRoute,
  [messageTypes.ACTIVATE_SUBSCRIPTION]: anyRoute,
  [messageTypes.CANCEL_SUBSCRIPTION]: anyRoute,
  [messageTypes.NOTIFICATION]: anyRoute,
  [messageTypes.SUBSCRIPTION_REMINDER]: anyRoute,
};
