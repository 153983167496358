import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'pages/PersonalChat/ChatUserList/styled';
import {
  Sidebar,
  Button,
  SidebarFooter,
  SidebarHeader,
  SidebarContent,
  SidebarInputsWrapper,
  Loader,
  SidebarImageInputWrapper,
  ImageInput,
  EmojiArea,
} from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { useCreateGroup } from './hooks';
import { FIELDS } from './form';
import {
  getCreateGroupLoading,
  getCurrentCommunity,
} from 'store/selectors/currentCommunity';
import { closeModal } from 'store/actionCreators/modal';

import { BackBtnWrapper, TextInputBlock } from './styled';
import {
  createChatGroupLoading,
  updateChatGroupLoading,
} from 'store/selectors/chat';
import { ChatUser } from 'store/reducers/chat';
import { Chat } from 'store/reducers/chat';
import { getData } from 'store/selectors/modal';
import { getAvailableActiveCommunities } from 'store/selectors/communities';
import CommunitySelect from './CommunitySelect';
import { Title } from 'pages/UserProfile/Content/styled';
import { useParams } from 'react-router-dom';
import { LeftCloseArrow } from 'static';

const CreateGroup = ({ chat }: { chat?: boolean }) => {
  const { t } = useTranslation();
  const { communityId: communityIdFromParams } = useParams<{
    communityId: string;
  }>();
  const communities = useSelector(getAvailableActiveCommunities);
  const dispatch = useDispatch();
  const [nextStep, setNextStep] = useState<boolean>(false);
  const data = useSelector(getData) as {
    chat: Chat;
    members: ChatUser[];
    communityId: string;
    isChatUpdate: boolean;
  };
  const [selectedCommunityId, setSelectedCommunityId] = useState<string | null>(
    null,
  );
  const community = useSelector(getCurrentCommunity);
  const isLoading = useSelector(getCreateGroupLoading);
  const isLoadingChatGroup = useSelector(createChatGroupLoading);
  const isUpdateLoading = useSelector(updateChatGroupLoading);
  const {
    chat: chatData = null,
    members = [],
    isChatUpdate = false,
  } = data || {};

  const { onSubmit, register, errors, unregister, setValue, control } =
    useCreateGroup(
      selectedCommunityId || community?.id,
      !!chat,
      chatData,
      members,
      isChatUpdate,
    );

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Sidebar>
      <form onSubmit={onSubmit}>
        <SidebarHeader
          label={
            isChatUpdate
              ? 'Update chat group'
              : chat
              ? 'Create chat group'
              : t('group.createGroup')
          }
          onCloseClick={handleClose}
        />
        <SidebarContent fullHeight>
          <SidebarInputsWrapper>
            {!communityIdFromParams && !isChatUpdate && !nextStep ? (
              <>
                <Title customPadding={'26px 0px'}>
                  <p>{'Select community'}</p>
                </Title>
                <CommunitySelect
                  value={selectedCommunityId}
                  nextStep={() => setNextStep(true)}
                  onChange={setSelectedCommunityId}
                  communities={communities}
                />
              </>
            ) : (
              <>
                {!communityIdFromParams && !isChatUpdate && nextStep && (
                  <BackBtnWrapper>
                    <Layout.Header.Close onClick={() => setNextStep(false)}>
                      <LeftCloseArrow width={24} height={24} />
                    </Layout.Header.Close>
                  </BackBtnWrapper>
                )}
                {chat && (
                  <SidebarImageInputWrapper>
                    <ImageInput
                      label={t('profile.selectImage')}
                      name={FIELDS.IMAGE}
                      register={register}
                      unregister={unregister}
                      setValue={setValue}
                      defaultValue={chatData?.logo || ''}
                      error={errors[FIELDS.IMAGE]}
                    />
                  </SidebarImageInputWrapper>
                )}
                <TextInputBlock>
                  <TextInput
                    register={register}
                    width="100%"
                    name={FIELDS.GROUP_NAME}
                    label={t('group.groupName')}
                    error={errors[FIELDS.GROUP_NAME]}
                  />
                </TextInputBlock>
                {chat && (
                  <EmojiArea
                    rows={6}
                    width="100%"
                    label={t('profile.about')}
                    name={FIELDS.ABOUT}
                    register={register}
                    error={errors[FIELDS.ABOUT]}
                    control={control}
                  />
                )}
              </>
            )}
          </SidebarInputsWrapper>
        </SidebarContent>
        <SidebarFooter>
          <Button
            type="submit"
            variant={
              isLoading || isLoadingChatGroup || isUpdateLoading
                ? 'secondary'
                : null
            }
            disabled={isLoading || isLoadingChatGroup || isUpdateLoading}
          >
            {isLoading || isLoadingChatGroup || isUpdateLoading ? (
              <Loader type="button" size="28px" thickness={2} />
            ) : isChatUpdate ? (
              'Update'
            ) : chat ? (
              'Crete And Select Member'
            ) : (
              t('common.create')
            )}
          </Button>
        </SidebarFooter>
      </form>
    </Sidebar>
  );
};

export default CreateGroup;
