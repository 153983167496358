import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { reduce, assoc, filter, includes } from 'lodash/fp';
import { ProfileName } from 'components';
import { getProfileName } from 'utils';
import { openModal } from 'store/actionCreators/modal';

import {
  StyledListItem,
  StyledNameWrapper,
  StyledGroupItem,
  StyledAvatarNoPhoto,
  ChatBtnWrapper,
} from './styled';
import { VIEW_MEMBER } from '../../../constants/sidebars';
import { Down } from 'static';
import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import { useNavigate } from 'react-router-dom';
import {
  IconSendMessage,
  StyledMessageBlock,
  StyledSendMessageLabel,
  StyledLink,
} from 'sidebars/ViewMember/styled';
import { useTranslation } from 'react-i18next';
import { getAuthUser } from 'store/selectors/auth';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

export const useRoles = (t, overallScheduler) => {
  const getGroupsData = (overallScheduler) => {
    const groups = Array.isArray(overallScheduler?.groups)
      ? overallScheduler.groups
      : [];
    const groupsData = groups
      .filter((group) => group.users)
      .map((group) => [
        {
          type: 'group',
          id: group.id,
          label: group.name,
        },
        ...group.users.map((member) => ({
          type: 'user',
          id: member.profile.userId,
          group: group.id,
          permission: {
            groupPermission: member?.groupPermission,
            communityPermission: overallScheduler.users.find(
              (u) => u.id === member.id,
            )?.communityPermission,
          },
          icon: member.profile.smallLogo || member.profile.logo || (
            <StyledAvatarNoPhoto>
              {getProfileName(member.profile)[0].toUpperCase()}
            </StyledAvatarNoPhoto>
          ),
          firstName: member.profile.firstName,
          lastName: member.profile.lastName,
          profileName: member.profile.profileName,
          email: member.profile.email,
          user: member,
        })),
      ])
      .flat();
    const usersWitoutGroup =
      overallScheduler.users?.filter(
        ({ id }) => !groupsData.some((item) => item.id === id),
      ) || [];

    return groupsData
      .concat([
        {
          type: 'group',
          id: 'no_group',
          label: t('common.withoutGroup'),
        },
        ...usersWitoutGroup.map((member) => ({
          type: 'user',
          id: member.id,
          group: 'no_group',
          label: getProfileName(member.profile),
          icon: member.profile.smallLogo || member.profile.logo || (
            <StyledAvatarNoPhoto>
              {getProfileName(member.profile)[0].toUpperCase()}
            </StyledAvatarNoPhoto>
          ),
          firstName: member.profile.firstName,
          lastName: member.profile.lastName,
          profileName: member.profile.profileName,
          email: member.profile.email,
          user: member,
        })),
      ])
      .flat();
  };

  const [usersData, setUsersData] = useState([
    ...getGroupsData(overallScheduler),
  ]);

  useEffect(() => {
    setUsersData([...getGroupsData(overallScheduler)]);
  }, [overallScheduler]);

  return usersData;
};

export const useGrid = (data, filterValue, communityId) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId: currentUserId } = useSelector(getAuthUser);

  const [groups, setGroups] = useState(
    reduce(
      (acc, item) => {
        if (item.type === 'group') {
          acc[item.id] = {
            ...item,
            isShow: true,
          };
        }
        return acc;
      },
      {},
      data,
    ),
  );

  const filteredData = useMemo(
    () =>
      filter((item) => {
        if (item.type !== 'group') {
          return (
            groups[item.group].isShow &&
            (filterValue
              ? includes(
                  filterValue.toLowerCase(),
                  getProfileName(item)?.toLowerCase(),
                )
              : true)
          );
        }
        return true;
      }, data),
    [data, groups, filterValue],
  );

  const rowRenderer = useCallback(
    ({ index, key, style }) => {
      const handleSendingMessage = (e, userId) => {
        e.stopPropagation();
        if (currentUserId === userId) {
          toast.error('Can not send  message to yourself');
          return;
        }
        e.preventDefault();
        navigate(
          `${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.CHAT}?peerId=${userId}`,
        );
      };

      if (filteredData[index].type === 'group') {
        return (
          <div key={key} style={style}>
            <StyledGroupItem className="grid-group">
              <span
                data-clickable="true"
                data-type="group"
                data-id={filteredData[index].id}
                className={cn(
                  'grid-group-toogle-container',
                  'grid-clickable-item',
                  {
                    'grid-group-toogle-container-close':
                      !groups[filteredData[index].id].isShow,
                  },
                )}
              >
                <Down />
              </span>
              {filteredData[index].label}
            </StyledGroupItem>
          </div>
        );
      }

      return (
        <div
          key={key}
          style={style}
          className="grid-row"
          onClick={() =>
            dispatch(
              openModal({
                type: VIEW_MEMBER,
                position: 'right',
                data: filteredData[index].user,
              }),
            )
          }
        >
          <StyledListItem>
            <StyledNameWrapper>
              <ProfileName
                searchFilter={filterValue}
                name={getProfileName(filteredData[index])}
                avatar={filteredData[index].icon}
                communityPermission={
                  filteredData[index].permission?.communityPermission
                }
                groupPermission={
                  filteredData[index].permission?.groupPermission
                }
              />
            </StyledNameWrapper>
            <ChatBtnWrapper>
              <StyledLink
                onClick={(e) =>
                  handleSendingMessage(e, filteredData[index].user.id)
                }
                disabled={currentUserId === filteredData[index].user.id}
              >
                <StyledMessageBlock showBorder>
                  <IconSendMessage />
                  <StyledSendMessageLabel>
                    {t('common.sendMessage')}
                  </StyledSendMessageLabel>
                </StyledMessageBlock>
              </StyledLink>
            </ChatBtnWrapper>
          </StyledListItem>
        </div>
      );
    },
    [filteredData, groups],
  );

  // click event functions

  const toogleGroup = useCallback(
    (id) => {
      if (groups[id]) {
        setGroups(assoc([id, 'isShow'], !groups[id].isShow, groups));
      }
    },
    [groups, setGroups],
  );

  const onClick = useCallback(
    (event) => {
      const elem = event.target.closest('[data-clickable="true"]');
      if (elem) {
        const { dataset } = elem;
        if (dataset.type === 'group') {
          toogleGroup(dataset.id);
        }
      }
    },
    [toogleGroup],
  );

  return {
    rowRenderer,
    onClick,
    filteredData,
  };
};
