import { call, put, select, takeEvery } from 'redux-saga/effects';
import i18n from 'i18next';
import { get } from 'lodash/fp';

import {
  doCreateGroup,
  doUpdateGroup,
  doDeleteGroup,
  doAddMembersToGroup,
  doRequestStatistic,
} from 'store/actionCreators/groups';
import { doCheckIsAuthorized } from 'store/actionCreators/auth';
import {
  createGroup,
  updateGroup,
  deleteGroup,
  addMembersToGroup,
  getStatistic,
} from 'services/groups';
import {
  selectCurrentUserSubscription,
  selectIsCommunitySubscriptionActive,
  selectCommunitySubscriptionPending,
} from 'store/selectors/subscriptions';
import { openModal } from 'store/actionCreators/modal';
import { POPUP_MESSAGE } from 'constants/popups';
import { isCurrentUserOwner } from 'store/selectors/currentCommunity';
import { checkSubscription } from './helper';
import { doGetGroupUsers } from '../actionCreators/currentGroup';
// import { doGetChats } from '../actionCreators/chat';

export function* onCreateGroup({ payload }: ReturnType<typeof doCreateGroup>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const { maxGroups, info } = yield select(selectCurrentUserSubscription);
    const isOwner = yield select(isCurrentUserOwner);
    if (info.groups >= maxGroups) {
      yield put(doCreateGroup.failure());
      yield put(
        openModal.trigger({
          type: POPUP_MESSAGE,
          data: {
            popupHeaderText: i18n.t('group.limitExceededHeader'),
            popupMessageText: i18n.t(
              isOwner
                ? 'group.limitExceededMessageOwner'
                : 'group.limitExceededMessage',
            ),
            popupButtonText: 'Ok',
          },
        }),
      );
    } else {
      const data = yield call(createGroup, payload);
      yield put(doCreateGroup.success(data));
      yield call(payload.onSuccess, data);
    }
  } catch ({ response }) {
    yield put(doCreateGroup.failure(response));
    yield put(
      openModal.trigger({
        type: POPUP_MESSAGE,
        data: {
          popupHeaderText: response.data.message,
          popupButtonText: 'Ok',
        },
      }),
    );
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onUpdateGroup({ payload }: ReturnType<typeof doUpdateGroup>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const data = yield call(updateGroup, payload.id, payload);
    yield put(doUpdateGroup.success(data));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doUpdateGroup.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onDeleteGroup({ payload }: ReturnType<typeof doDeleteGroup>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const { communityId, groupId } = payload.data;
    yield call(deleteGroup, payload.data.groupId);
    yield put(doDeleteGroup.success({ communityId, groupId }));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doDeleteGroup.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onAddMembersToGroup({
  payload,
}: ReturnType<typeof doAddMembersToGroup>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const { maxMembers, info } = yield select(selectCurrentUserSubscription);
    const isOwner = yield select(isCurrentUserOwner);
    if (info.users >= maxMembers) {
      yield put(doAddMembersToGroup.failure());
      yield put(
        openModal.trigger({
          type: POPUP_MESSAGE,
          data: {
            popupHeaderText: i18n.t('subscriptions.limitExceededHeader'),
            popupMessageText: i18n.t(
              isOwner
                ? 'subscriptions.limitExceededMessageOwner'
                : 'subscriptions.limitExceededMessage',
            ),
            popupButtonText: 'Ok',
          },
        }),
      );
    } else {
      const { communityId, groupId } = payload;
      const data = yield call(
        addMembersToGroup,
        communityId,
        groupId,
        payload.data,
      );
      const currentGroup = yield select((state) => state.currentGroup.data);

      if (currentGroup && currentGroup.id === groupId) {
        yield put(doGetGroupUsers.trigger(payload));
      }

      yield put(doAddMembersToGroup.success(data));
      yield call(payload.onSuccess);
    }
  } catch ({ response }) {
    yield put(doAddMembersToGroup.failure(response));
    yield put(
      openModal.trigger({
        type: POPUP_MESSAGE,
        data: {
          popupHeaderText: response.data.message,
          popupButtonText: 'Ok',
        },
      }),
    );
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onGetGroupStatistic({
  payload,
}: ReturnType<typeof doRequestStatistic>) {
  try {
    yield put(doRequestStatistic.request(payload));
    const { replies, repliesToEventTypes } = yield call(getStatistic, payload);
    yield put(doRequestStatistic.success({ replies, repliesToEventTypes }));
  } catch ({ response }) {
    yield put(doRequestStatistic.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export default function* () {
  yield takeEvery(doUpdateGroup, onUpdateGroup);
  yield takeEvery(doDeleteGroup, onDeleteGroup);
  yield takeEvery(doAddMembersToGroup, onAddMembersToGroup);
  yield takeEvery(doCreateGroup, onCreateGroup);
  yield takeEvery(doRequestStatistic, onGetGroupStatistic);
}
