import styled from 'styled-components';
import { SendMessageIcon } from 'static';

export const StyledImage = styled.img`
  width: 100%;
  max-height: 300px;
  padding: 10px 10px 0px 10px;
  object-fit: contain;
  pointer-events: none;
`;
export const ContentWrapper = styled.div`
  display: block;
  margin: 0px auto;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16vh;
  max-width: 340px;
  padding: 10px 4px 0px 4px;
`;
export const EditBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 10px;
  gap: 12px;
  right: 0px;
  background-color: ${({ theme }) => theme.color.white};
  position: sticky;
  top: 0px;
  z-index: 123654;
  button {
    background-color: ${({ theme }) => theme.color.white};
    svg {
      path {
        fill: ${({ theme }) => theme.color.baliHai};
      }
    }
    span {
      color: #10ccc6;
    }
    &:hover {
      background-color: rgb(235, 242, 247);
      border-color: transparent !important;
      span {
        color: ${({ theme }) => theme.color.pickledBluewood} !important;
      }
      svg {
        path {
          fill: ${({ theme }) => theme.color.pickledBluewood} !important;
        }
      }
    }
    @media (max-width: 428px) {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

export const StyledSendMessageLabel = styled.span`
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  text-transform: capitalize;
  color: #10ccc6;
  &:hover {
    color: ${({ theme }) => theme.color.pickledBluewood};
  }
  line-height: 16px;
`;
export const IconSendMessage = styled(SendMessageIcon)`
  width: 18px;
  height: 18px;
  path {
    stroke: ${({ theme }) => theme.color.baliHai};
    stroke-width: 2;
  }
`;
export const StyledMessageBlock = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  width: 100%;
  max-height: 38px;
  padding: 9px 12px;
  border-radius: 36px;
  border: 0.5px solid #8298ab;
  justify-content: center;
  transition: color, background-color 0.15s ease-in-out;

  &:hover ${StyledSendMessageLabel} {
    color: ${({ theme }) => theme.color.pickledBluewood};
  }
  &:hover ${IconSendMessage} {
    path {
      stroke: ${({ theme }) => theme.color.pickledBluewood};
      stroke-width: 2;
    }
  }
  &:hover {
    border-color: ${({ showBorder, theme }) =>
      showBorder ? theme.color.baliHai : theme.color.blackSqueeze};
    border: 0.5px solid;
    background-color: #ebf2f7;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const StyledDescriptionBlock = styled.div`
  max-width: 340px;
  display: flex;
  width: 100%;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
`;

export const StyledDescriptionTitle = styled.p`
  margin: 10px 0 2px 10px;
  font-size: 12px;
  line-height: 20px;
  color: #8298ab !important;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

export const StyledDescriptionText = styled.p`
  font-weight: initial;
  word-break: break-word;
  font-family: sans-serif;
  border: 1px solid #ebf2f7;
  white-space: pre-wrap;
  background-color: #f7faf9;
  border-radius: 2px;
  line-height: normal;
  padding: 8.5px 14px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  a {
    font-weight: 600;
    color: ${({ theme }) => theme.color.brightTurquoise};
  }
`;

export const MainBtnWrapper = styled.div`
  position: sticky;
  z-index: 123645;
  top: 0px;
  background-color: ${({ theme }) => theme.color.white};
`;
export const SocialLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  gap: 12px;
`;

export const LinkAndUpdateBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  padding: 10px 12px;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  button {
    padding-left: 12px !important;
    padding-right: 12px !important;
    background-color: ${({ theme }) => theme.color.white};
    svg {
      path {
        fill: ${({ theme }) => theme.color.baliHai};
      }
    }
    span {
      color: #10ccc6;
    }
    &:hover {
      background-color: rgb(235, 242, 247);
      border-color: transparent !important;
      span {
        color: ${({ theme }) => theme.color.pickledBluewood} !important;
      }
      svg {
        path {
          fill: ${({ theme }) => theme.color.pickledBluewood} !important;
        }
      }
    }
    @media (max-width: 428px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 428px) {
    padding: 10px 0px;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  button {
    margin-right: 0px !important;
  }
`;

export const StyledLink = styled.a`
  width: 100%;
  text-decoration: none;
  @media (max-width: 428px) {
    width: 100%;
  }
  ${({ disabled }) =>
    disabled && {
      opacity: '65%',
    }};
`;
