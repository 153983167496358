import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  DeepMap,
  UseFormMethods,
  FieldError,
  Control,
  ControllerRenderProps,
} from 'react-hook-form';

import {
  EmojiArea,
  TextInput,
  Button,
  CheckBox,
  Divider,
  Loader,
} from 'components';
import SelectInputControlledMulti from 'components/Inputs/SelectInputControlled/SelectInputControlledMultiV2';

import { PushNotificationModuleFormValues, FORMFIELDS } from '../form';
import {
  CheckBoxWrapper,
  PushMessageContainer,
  MessageWrapper,
  ButtonWrapper,
  ContainerWrapper,
  SelectBoxContainer,
  SelectBoxWrapper,
  LoaderWrapper,
} from './styled';

import { getProfileName } from 'utils';
import { useSelector } from 'react-redux';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import TextInputEmoji from 'components/Inputs/TextInput/TextInputEmoji';
import { useTranslation } from 'react-i18next';
import { StyledSectionHeader } from '../styled';
import { theme } from 'theme';

interface PushMessageProps
  extends Partial<
    Pick<UseFormMethods<PushNotificationModuleFormValues>, 'register'>
  > {
  selectedRecipients: string[];
  errors: DeepMap<PushNotificationModuleFormValues, FieldError>;
  setError?: (name: string, value: string) => void;
  sending: boolean;
  sendCopyToMe: boolean;
  onSendCopyChange: (e: ChangeEvent<HTMLInputElement>) => void;
  control: Control<PushNotificationModuleFormValues>;
  onSubmit: () => void;
  setSelectedParticipants: (data: string[]) => void;
  ref?: React.MutableRefObject<Element>;
  eventResponsesFilter?: { [key: string]: any }[];
  options: { [key: string]: any }[];
  eventResponseOptions?: { [key: string]: any }[];
  handleEventResponsesChange?: (responses: { [p: string]: any }[]) => void;
  pushMessageView?: 'single' | 'double' | 'triple';
  handleSaveTemplate?: () => void;
  saveTemplateLoader?: boolean;
  recipientFieldProps?: ControllerRenderProps<PushNotificationModuleFormValues>;
}

export const BUTTONSTYLE = {
  color: 'black',
};

const PushMessage: React.FC<PushMessageProps> = ({
  register,
  errors,
  selectedRecipients,
  sendCopyToMe,
  control,
  onSubmit,
  onSendCopyChange,
  setSelectedParticipants,
  eventResponsesFilter,
  eventResponseOptions,
  handleEventResponsesChange,
  pushMessageView,
  handleSaveTemplate,
  recipientFieldProps,
  saveTemplateLoader,
}) => {
  const [maxWidth, setMaxWidth] = useState<number>(0);
  const community = useSelector(getCurrentCommunity);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>();
  const getUsersValues = (data) => {
    return data.map((id) => ({
      value: id,
      label: `${getProfileName(
        community.users.find((i) => i.id === id)?.profile || {},
      )} (${community.users.find((i) => i.id === id)?.profile?.email})`,
    }));
  };
  useEffect(() => {
    setMaxWidth(containerRef.current.clientWidth);
    const handleWidthChange = () => {
      if (containerRef.current) {
        setMaxWidth(containerRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleWidthChange);

    return () => {
      window.removeEventListener('resize', handleWidthChange);
    };
  }, [containerRef.current]);

  const getUsersOptions = (arr) =>
    arr.map((u) => ({
      value: u.id,
      label: `${getProfileName(u.profile)} (${u.profile?.email})`,
    }));

  return (
    <ContainerWrapper ref={containerRef}>
      <SelectBoxContainer>
        <SelectBoxWrapper maxWidth={maxWidth}>
          <SelectInputControlledMulti
            width="100%"
            label={t('pushNotification.filterByResponse')}
            value={eventResponsesFilter}
            options={eventResponseOptions}
            closeMenuOnSelect={false}
            onChange={handleEventResponsesChange}
            labelAlignment="left"
            labelStyle={{ marginTop: '0px' }}
          />
        </SelectBoxWrapper>
        <SelectBoxWrapper maxWidth={maxWidth}>
          <SelectInputControlledMulti
            label={t('pushNotification.filterByRecipient')}
            name={FORMFIELDS.RECIPIENTS}
            onChange={(values) => {
              setSelectedParticipants(values.map((i) => i.value));
            }}
            options={getUsersOptions(community.users)}
            value={getUsersValues(selectedRecipients)}
            closeMenuOnSelect={false}
            width="100%"
            labelStyle={{ marginTop: '0px' }}
            error={errors[recipientFieldProps.name]}
          />
        </SelectBoxWrapper>
        {pushMessageView === 'single' && (
          <>
            <SelectBoxWrapper>
              <CheckBoxWrapper>
                <CheckBox
                  width="max-content"
                  isSelected={sendCopyToMe}
                  onChange={onSendCopyChange}
                  label={t('pushNotification.sendCopy')}
                />
              </CheckBoxWrapper>
            </SelectBoxWrapper>
            <SelectBoxWrapper>
              <TextInput
                register={register}
                label={t('pushNotification.pasteUrl')}
                name={FORMFIELDS.REDIRECT_LINK}
                type="text"
                error={errors[FORMFIELDS.REDIRECT_LINK]}
                width="100%"
                extraWrapperStyle={{ width: 'calc(100% - 0px)' }}
                inputValueStyle={{ color: theme.color.brightTurquoise }}
                showInfoIcon={true}
                infoText={t('pushNotification.urlInfoOne')}
              />
            </SelectBoxWrapper>
          </>
        )}
      </SelectBoxContainer>

      <MessageWrapper>
        <StyledSectionHeader>
          {t('pushNotification.pushMessage')}
        </StyledSectionHeader>
        <Divider />

        <PushMessageContainer>
          <div>
            <TextInputEmoji
              name={FORMFIELDS.TITLE}
              type="text"
              label={t('pushNotification.title')}
              error={errors[FORMFIELDS.TITLE]}
              register={register}
              control={control}
              extraWrapperStyle={{ width: '100%' }}
              withShowIcon
              maxLength={75}
              labelFontSize={10}
              labelFontWeight={400}
            />
          </div>

          <EmojiArea
            name={FORMFIELDS.MESSAGE}
            label={t('email.message')}
            error={errors[FORMFIELDS.MESSAGE]}
            register={register}
            control={control}
            positionRight="4px"
            width="100%"
            positionTop="30px"
            rows={6}
            withEmojiIcon
            maxLength={175}
            countFontSize={10}
            countFontWeight={400}
          />
          <ButtonWrapper>
            <Button
              width="100%"
              {...{ rounded: true }}
              style={BUTTONSTYLE}
              onClick={() => {
                onSubmit();
              }}
            >
              {t('auth.send')}
            </Button>
            {saveTemplateLoader ? (
              <LoaderWrapper>
                <Loader type="button" size="28px" />
              </LoaderWrapper>
            ) : (
              <Button
                {...{ rounded: true }}
                width="100%"
                variant="secondary"
                onClick={handleSaveTemplate}
              >
                {t('pushNotification.saveAsTemplate')}
              </Button>
            )}
          </ButtonWrapper>
        </PushMessageContainer>
      </MessageWrapper>
    </ContainerWrapper>
  );
};

export default PushMessage;
