import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledButton,
  StyledLabel,
  StyledIcon,
  SettingWrapper,
} from './styled';

interface SettingsButtonProps {
  withLabel?: boolean;
  onClick?: () => void;
}

const SettingsButton: FC<SettingsButtonProps> = ({ withLabel, onClick }) => {
  const { t } = useTranslation();

  return (
    <SettingWrapper>
      {withLabel && <StyledLabel>{t('groupSettings.title')}</StyledLabel>}
      <StyledButton onClick={onClick}>
        <StyledIcon />
      </StyledButton>
    </SettingWrapper>
  );
};

export default SettingsButton;
