import React, { useEffect, useRef, useState } from 'react';
import { ContentWrapper, PageContainer } from 'components';
import ChatUserList from './ChatUserList';
import Chat from './Chat';
import {
  doGetChatSearchUsers,
  doGetChatUsers,
  doUpdateChatUsers,
} from 'store/actionCreators/chat';
import { useDispatch } from 'react-redux';
import { getAuthUser } from 'store/selectors/auth';
import { useSelector } from 'react-redux';
import {
  getChatUser,
  getChatUserLoading,
  getSearchUserLoading,
} from 'store/selectors/chat';
import { ChatUser } from 'store/reducers/chat';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getIsMenuOpen } from 'store/selectors/currentCommunity';
import { COMMUNITY_ROUTES, PROFILE_ROUTES, ROUTES } from 'configs';
import { useDebounce } from 'hooks';
import { useToasts } from 'react-toast-notifications';
import { openModal } from 'store/actionCreators/modal';
import { ModalTypes } from 'types';

const PersonalChat = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const talkId = queryParams.get('talkId');
  const peerId = queryParams.get('peerId');
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { communityId } = useParams<{ communityId: string }>();
  const { userId } = useSelector(getAuthUser);
  const [openUserList, setOpenUserList] = useState<boolean>(true);
  const dispatch = useDispatch();
  const searchLoading = useSelector(getSearchUserLoading);
  const chatUser = useSelector(getChatUser);

  const slugRef = useRef(false);
  const isMenuOpen = useSelector(getIsMenuOpen);
  const [userListType, setUserListType] = useState<'all' | 'unseen'>('all');
  const { removeAllToasts } = useToasts();
  const [search, setSearch] = useState<string | null>(null);

  const onChange = ({ target }) => {
    setSearch(target.value);
  };

  const debouncedSearch: string = useDebounce(search, 200);
  useEffect(() => {
    removeAllToasts();
  }, []);

  useEffect(() => {
    if (search !== null) {
      if (communityId) {
        navigate(`${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.CHAT}`);
      } else {
        navigate(`${ROUTES.PROFILE}/${PROFILE_ROUTES.CHAT}`);
      }
      dispatch(
        doGetChatSearchUsers({
          userId,
          search: debouncedSearch || '',
          type: userListType !== 'all',
          communityId,
        }),
      );
      if (!search || search.length === 0) {
        setSearch(null);
      }
    }
  }, [debouncedSearch]);

  const count = React.useMemo(() => {
    return (
      chatUser?.find(
        ({ chatId, peerUsers }) =>
          chatId === talkId || peerUsers?.userId === peerId,
      )?.chatUnreadCount || 0
    );
  }, [chatUser, talkId, peerId]);

  const toggleSidebar = () => {
    setOpenUserList((preState) => !preState);
  };

  const handleSelectUserType = (type: 'all' | 'unseen') => {
    setUserListType(type);

    // TODO commented
    // if (communityId) {
    //   navigate(`${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.CHAT}`);
    // } else {
    //   navigate(`${ROUTES.PROFILE}/${PROFILE_ROUTES.CHAT}`);
    // }
  };

  useEffect(() => {
    if (slugRef.current || !communityId) {
      dispatch(
        doGetChatUsers({ userId, type: userListType !== 'all', communityId }),
      );
    } else {
      slugRef.current = true;
    }
  }, [dispatch, userId, communityId]);

  const openCreateChatPopUp = () => {
    if (communityId) {
      setTimeout(() => {
        dispatch(
          openModal({
            type: ModalTypes.CHAT_POPUP,
          }),
        );
      }, 500);
    } else {
      setIsOpen(true);
    }
  };

  //TODO commented
  // useEffect(() => {
  //   if (chatUser && !chatUserLoading && userListType !== 'unseen') {
  //     const peerUsers = chatUser?.find(
  //       (user) => user.chatId === talkId || user?.peerUsers?.userId === peerId,
  //     );
  //     if ((peerId || talkId) && !peerUsers && !debouncedSearch) {
  //       // dispatch(doUpdateChatUsers({ userId: conversationId }));
  //     }
  //   }
  // }, [peerId, talkId, chatUser]);

  return (
    <ContentWrapper isMenuOpen={isMenuOpen}>
      <PageContainer>
        <ChatUserList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          communityId={communityId}
          searchLoading={searchLoading}
          search={search}
          onChange={onChange}
          handleSelectUserType={handleSelectUserType}
          userListType={userListType}
          openUserList={openUserList}
          toggleSidebar={toggleSidebar}
          openCreateChatPopUp={openCreateChatPopUp}
          peerId={peerId}
          talkId={talkId}
          chatUser={chatUser}
        />
        <Chat
          communityId={communityId}
          openCreateChatPopUp={openCreateChatPopUp}
          searchLoading={searchLoading}
          openUserList={openUserList}
          toggleSidebar={toggleSidebar}
          count={count}
          peerId={peerId}
          talkId={talkId}
        />
      </PageContainer>
    </ContentWrapper>
  );
};

export default PersonalChat;
