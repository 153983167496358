import React, {
  FC,
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
} from 'react';
import { noop, isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Layout,
  PlaceholderContainer,
  ChatLayout,
  ChatListWrapper,
  RedirectSection,
  RedirectWrapper,
  MessageContainer,
  MessageWrapper,
  StyledShadow,
  Wrapper,
  DivChat,
} from './styled';
import { Divider, Loader } from 'components';
import Placeholder from './Placeholder';
import DateSeparator from './DateSeparator';
import Message from './Message';
import { getDate } from 'utils';
import {
  getDateStringWithDay,
  getTimeWithA,
  lastUpdateTime,
} from 'utils/dates';
import { useDispatch } from 'react-redux';
import {
  doGetConversation,
  doSendMessage,
  doSetConversation,
} from 'store/actionCreators/chat';
import { useSelector } from 'react-redux';
import { getAuthUser, getSettings } from 'store/selectors/auth';
import {
  getChatUserLoading,
  getChat,
  getConversationLoading,
  sendMessageLoading,
} from 'store/selectors/chat';
import ChatInput from '../ChatInput';
import { useInfoPopup } from 'hooks';
import {
  getCurrentMembers,
  getIsMenuOpen,
} from 'store/selectors/currentCommunity';
import { ChatUser, Conversation, Messages } from 'store/reducers/chat';
import { getProfileNameCapital } from 'utils/common';
import Header from './Header';
import { FORMFIELDS, useCreateChatForm } from './form';
import { CommunityUser, ModalTypes } from 'types';
import { VIEW_MEMBER } from 'constants/sidebars';
import { openModal } from 'store/actionCreators/modal';
import { isImageFile } from './Message/utils';

interface ChatProps {
  openUserList: boolean;
  toggleSidebar: noop;
  count: number | null;
  searchLoading: boolean;
  openCreateChatPopUp: () => void;
  talkId: string;
  peerId: string;
  communityId?: string;
}
const AVAILABLE_SIZE = 5 * 1024;

const Chat: FC<ChatProps> = ({
  openUserList,
  toggleSidebar,
  count,
  searchLoading,
  openCreateChatPopUp,
  talkId,
  peerId,
  communityId,
}) => {
  const { t } = useTranslation();
  const isSidebarOpen = useSelector(getIsMenuOpen);
  const { showInfoPopup } = useInfoPopup();
  const dispatch = useDispatch();
  const settings = useSelector(getSettings);
  const chatUserLoading = useSelector(getChatUserLoading);
  const chatList = useSelector(getChat);
  const chatListLoading = useSelector(getConversationLoading);
  const sendMessageLoader = useSelector(sendMessageLoading);
  const user = useSelector(getAuthUser);
  const [chats, setChats] = useState<Conversation | null>(null);
  const [selectedPeerUser, setSelectedPeerUser] = useState<ChatUser | null>(
    null,
  );
  const hasMessages = chats?.messages && chats.messages.length > 0;
  const validImageSize = (imageFile) =>
    imageFile && Math.round(imageFile.size / 1024) < AVAILABLE_SIZE;
  const members = useSelector(getCurrentMembers);
  let member: CommunityUser;
  if (peerId) {
    member = members.find((member) => member.id === peerId);
  }

  const scrollEl = useRef<HTMLElement>(null);

  const defaultValues = {
    message: '',
    attachments: null,
  };

  const openViewMemberSidebar = useCallback(
    (userId: string) => {
      if (!communityId && userId) {
        return;
      }
      const user: CommunityUser | undefined = userId
        ? members?.find((member) => member.id === userId)
        : undefined;
      console.log('chats?.peerUsers', chats?.peerUsers, {
        user,
        member,
        userId,
      });

      if (chatList?.isGroupChat && !userId) {
        dispatch(
          openModal({
            type: ModalTypes.ABOUT_CHAT_GROUP,
            placement: 'right',
            data: {
              chat: {
                id: talkId,
                chatName: chatList?.chatName,
                description: chatList?.description,
                logo: chatList?.logo || null,
              },
              createdAt: chatList?.createdAt,
              members: chats?.peerUsers,
              communityId: communityId || chatList.communityId,
            },
          }),
        );
      } else {
        dispatch(
          openModal({
            type: VIEW_MEMBER,
            placement: 'right',
            data: peerId ? user : member || user,
          }),
        );
      }
    },
    [communityId, members, chatList, chats, talkId, dispatch],
  );

  const {
    errors,
    register,
    handleSubmit,
    clearErrors,
    control,
    setValue,
    trigger: triggerFormValidation,
    watchedValues,
    attachmentsFieldProps,
    setError,
    reset,
  } = useCreateChatForm(defaultValues);

  useEffect(() => {
    setChats(chatList);
    if (!chatList?.isGroupChat) {
      setSelectedPeerUser(
        chatList?.peerUsers.find((u) => u.userId !== user.userId),
      );
    }
  }, [chatList]);

  useEffect(() => {
    if (scrollEl.current) {
      const currentEl = scrollEl.current;
      const scrollToBottom = () => {
        const start = currentEl.scrollTop;
        const end = currentEl.scrollHeight;
        const duration = 500; // Duration in ms

        const startTime = performance.now();

        const animateScroll = (currentTime: number) => {
          const timeElapsed = currentTime - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
          currentEl.scrollTop = start + (end - start) * progress;

          if (progress < 1) {
            requestAnimationFrame(animateScroll);
          }
        };

        requestAnimationFrame(animateScroll);
      };

      setTimeout(scrollToBottom, 0);
    }
  }, [chats, peerId, talkId]);

  useEffect(() => {
    if (peerId || talkId) {
      clearErrors();
      reset(defaultValues);
      //todo
      // getConversationFromDB(talkId).then((res) => {
      // if (res) {
      //   dispatch(doSetConversation.success(res));
      //   setChats(res);
      //   if (!res?.isGroupChat) {
      //     setSelectedPeerUser(
      //       res?.peerUsers.find((user) => user.userId == user.userId),
      //     );
      //   }
      // }
      // if (!res) {
      //   dispatch(
      //     doGetConversation({
      //       userId: user.userId,
      //       chatId: talkId,
      //       receiverId: peerId,
      //       count,
      //     }),
      //   );
      // }
      // });
      // .catch((err) => {});
      setChats(null);
      setSelectedPeerUser(null);
      dispatch(
        doGetConversation({
          userId: user.userId,
          chatId: talkId,
          receiverId: peerId,
          count,
        }),
      );
    }
  }, [talkId, peerId]);

  const onSendMessage = async () => {
    handleSubmit(async (data) => {
      const mediaDetail = data[FORMFIELDS.ATTACHMENTS];
      if (!data[FORMFIELDS.MESSAGE] && !data[FORMFIELDS.ATTACHMENTS]) {
        setError(FORMFIELDS.MESSAGE, {
          type: 'required',
          message: t('errors.required'),
        });
        return;
      }
      const type = data[FORMFIELDS.ATTACHMENTS]
        ? isImageFile(data[FORMFIELDS.ATTACHMENTS])
          ? 'image'
          : 'file'
        : 'text';

      try {
        dispatch(
          doSendMessage.trigger({
            userId: user.userId,
            chatId: talkId || chats?.id,
            content: data[FORMFIELDS.MESSAGE],
            type: type,
            communityId,
            media: data[FORMFIELDS.ATTACHMENTS],
            isGroup: !!chats?.isGroupChat,
            mediaDetail:
              !validImageSize(data[FORMFIELDS.ATTACHMENTS]) && mediaDetail
                ? {
                    ext: mediaDetail.type.split('/')?.[1],
                    filename: mediaDetail.name,
                    mimetype: mediaDetail.type,
                    size: mediaDetail.size / 1000000000,
                  }
                : null,
            ...(!chats?.isGroupChat && {
              receiverId: selectedPeerUser?.userId || peerId,
            }),
            onSuccess: () => {
              setValue(FORMFIELDS.MESSAGE, '');
              setValue(FORMFIELDS.ATTACHMENTS, null);
            },
          }),
        );
      } catch (err) {
        console.log('err', err);
        showInfoPopup({
          title: 'Something went wrong',
          message: 'Please try later',
          type: 'error',
          loop: false,
        });
      }
    })();
  };

  const onSubmit = async () => {
    const isValid = await triggerFormValidation();
    if (isValid) onSendMessage();
  };

  const scrollToElement = useCallback((id: string) => {
    if (scrollEl.current) {
      const targetElement = document.getElementById(id);
      if (targetElement && targetElement.nextElementSibling) {
        const nextSibling = targetElement.nextElementSibling as HTMLElement;
        const container = scrollEl.current;
        if (container) {
          const targetOffset =
            nextSibling.getBoundingClientRect().top -
            container.getBoundingClientRect().top;
          const containerScrollTop = container.scrollTop;

          const stickyOffset = 40;
          container.scrollTop =
            containerScrollTop + targetOffset - stickyOffset;
        }
      }
    }
  }, []);

  const findPeerUser = useCallback(
    (id: string) => {
      return chats?.peerUsers.find((peer) => peer.userId === id);
    },
    [chats],
  );

  const urlUser = useMemo(
    () => (id: string) => {
      const peerUser = findPeerUser(id);
      return peerUser
        ? peerUser.logo || peerUser.smallLogo
        : user?.logo || user?.smallLogo;
    },
    [chats, user],
  );

  const getUserName = useMemo(
    () => (id: string) => {
      const peerUser = findPeerUser(id);
      return peerUser
        ? peerUser.firstName || peerUser.profileName
        : user?.firstName || user?.profileName;
    },
    [chats, user],
  );

  const senderLastName = useMemo(
    () => (id: string) => {
      const peerUser = findPeerUser(id);
      return peerUser
        ? peerUser.lastName || peerUser.profileName
        : user?.lastName || user?.profileName;
    },
    [chats, user],
  );

  // const download = async (url: string, filename: string) => {
  //   const response = await fetch(url, { mode: 'no-cors' });
  //   const data = await response.blob();
  //   console.log('data', data);

  //   const file = new File([data], filename);

  //   return file;
  // };

  const handleDocumentsAttach = async (data) => {
    // TODO For Multiple file upload
    // const newFiles = await Promise.all(
    //   data
    //     .filter(
    //       (i) =>
    //         !watchedValues[FORMFIELDS.ATTACHMENTS].some(
    //           (f) => f.name === i.name,
    //         ),
    //     )
    //     .map((i) => download(i.url, i.name)),
    // );
    // return attachmentsFieldProps.onChange([
    //   ...watchedValues[FORMFIELDS.ATTACHMENTS],
    //   ...newFiles,
    // ]);
  };

  return (
    <Layout isOpen={openUserList}>
      <Header
        userId={user.userId}
        isSidebarOpen={isSidebarOpen}
        openUserList={openUserList}
        toggleSidebar={toggleSidebar}
        selectedPeerUser={selectedPeerUser}
        communityId={communityId || chats?.communityId[0]}
        peerId={peerId}
        talkId={talkId}
        isGroup={chatList?.isGroupChat}
        groupName={chatList?.chatName}
        member={member}
        members={chats?.peerUsers}
        description={chatList?.description}
        logo={chatList?.logo}
        createdAt={chatList?.createdAt}
        admin={chatList?.admin}
        openViewMemberSidebar={openViewMemberSidebar}
      />
      <Divider customMargin="0px" />
      {!isEmpty(talkId) || !isEmpty(peerId) ? (
        <ChatLayout>
          <DivChat>
            {chatListLoading || chatUserLoading ? (
              <Loader size="60px" type={null} />
            ) : hasMessages ? (
              <ChatListWrapper isOpen={openUserList}>
                <PerfectScrollbar
                  containerRef={(ref) => (scrollEl.current = ref)}
                  options={{
                    swipeEasing: true,
                    wheelSpeed: 1,
                    wheelPropagation: false,
                    minScrollbarLength: 4,
                  }}
                >
                  {chats.messages.map((message: Messages, index) => {
                    const sameAuthor =
                      index > 0 &&
                      message?.senderId === chats.messages[index - 1]?.senderId;
                    const sameDay =
                      index > 0 &&
                      getDate(message?.createdAt)?.toLowerCase() ===
                        getDate(
                          chats.messages[index - 1]?.createdAt,
                        )?.toLowerCase();
                    const time = getTimeWithA(
                      message?.createdAt,
                      settings.timeFormat,
                    );
                    const date = getDate(message?.createdAt);

                    return (
                      <>
                        {(!sameDay || index === 0) && (
                          <DateSeparator
                            date={date}
                            handleScroll={scrollToElement}
                          />
                        )}
                        <MessageContainer key={message.id}>
                          <MessageWrapper
                            reverse={message.senderId !== user.userId}
                          >
                            <Message
                              openViewMemberSidebar={openViewMemberSidebar}
                              userId={user.userId}
                              isOpen={openUserList}
                              urlUser={urlUser(message.senderId)}
                              senderFirstName={getUserName(message.senderId)}
                              senderLastName={senderLastName(message.senderId)}
                              content={message.content}
                              date={message.createdAt}
                              sameAuthor={sameAuthor}
                              time={time}
                              sameDay={sameDay}
                              mediaType={message.type}
                              mediaUrl={message.mediaUrl}
                              senderId={message.senderId}
                            />
                          </MessageWrapper>
                        </MessageContainer>
                      </>
                    );
                  })}
                </PerfectScrollbar>
              </ChatListWrapper>
            ) : (
              <Wrapper>
                {t('chat.emptyStatus.sendFirstMessage', {
                  recipient: chatList?.isGroupChat
                    ? chatList?.chatName
                    : getProfileNameCapital({
                        firstName:
                          selectedPeerUser?.firstName ||
                          member?.profile?.firstName,
                        lastName:
                          selectedPeerUser?.lastName ||
                          member?.profile?.lastName,
                      }),
                })}
              </Wrapper>
            )}
          </DivChat>
          <RedirectWrapper>
            <StyledShadow />
            <Divider customMargin="0px" />
            <ChatInput
              clearErrors={clearErrors}
              attachmentsFieldProps={attachmentsFieldProps}
              sendMessageLoader={sendMessageLoader || chatListLoading}
              isOpen={openUserList}
              onSubmit={onSubmit}
              control={control}
              errors={errors}
              register={register}
              onDocumentsAttach={handleDocumentsAttach}
              isActiveSend={watchedValues[FORMFIELDS.MESSAGE].length >= 1}
              communityId={communityId}
            />
          </RedirectWrapper>
        </ChatLayout>
      ) : (
        <PlaceholderContainer>
          <Placeholder
            imageUrl=""
            text={t('chat.emptyStatus.noChatSelected.firstLine')}
            linkText={t('chat.emptyStatus.noChatSelected.LinkText')}
            openCreateChatPopUp={openCreateChatPopUp}
          />
        </PlaceholderContainer>
      )}
    </Layout>
  );
};

export default Chat;
