import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledSearchWrapper = styled.div`
  padding: 13px 16px 14px 15px;
`;

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height || 'calc(100svh - 206px)'};
`;

export const SidebarContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  @media ${breakpoints.downMd} {
    padding-bottom: 160px;
  }
`;
