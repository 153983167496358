import React, { FC, useState, useEffect, useRef } from 'react';
import { isString } from 'lodash/fp';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { invokeIfArgExists } from 'utils';
import FileBox from './CoverBox';
import { useInfoPopup } from 'hooks';

import {
  StyledWrapper,
  HiddenInput,
  DeleteImageButton,
  StyledDeleteIcon,
  CoverContainer,
} from './styled';

import { CommunityCoverDetails } from 'types';

export const AVAILABLE_SIZE = 20 * 1024;
export const AVAILABLE_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];

const validImageType = (imageFile) => AVAILABLE_TYPES.includes(imageFile.type);
const validImageSize = (imageFile) =>
  Math.round(imageFile.size / 1024) < AVAILABLE_SIZE;
const getUrl = invokeIfArgExists((file) =>
  isString(file) ? file : URL.createObjectURL(file),
);

interface CoverInputProps
  extends Partial<Pick<UseFormMethods, 'register' | 'unregister'>> {
  name: string;
  text?: string;
  tipText?: string;
  defaultValue: string;
  setValue: (name: string, image: string) => void;
  setCoverDetail?: React.Dispatch<React.SetStateAction<CommunityCoverDetails>>;
}

const CoverInput: FC<CoverInputProps> = ({
  name,
  text,
  tipText,
  defaultValue,
  register,
  unregister,
  setValue,
  setCoverDetail,
}) => {
  const [image, setImage] = useState(defaultValue || null);
  const { showInfoPopup } = useInfoPopup();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();

  const [fileSizeTitle, fileSizeMessage] = t('errors.fileSizeCustom', {
    size: 20,
  }).split(':');

  const getCoverData = (imageFile: File) => {
    const { name: filename, size, type: mimetype } = imageFile;
    const fileSize = size / 1000000000;
    const ext = mimetype.split('/')?.[1];
    return { filename, size: fileSize, mimetype, ext, delete: false };
  };

  useEffect(() => {
    register({ name });
    return () => unregister(name);
  }, [register, unregister, name]);

  useEffect(() => {
    setValue(name, image);
  }, [setValue, name, image]);

  const handleChange = (e) => {
    const imageFile = e.target.files[0];
    inputRef.current.value = null;

    if (!imageFile) return false;
    if (!validImageSize(imageFile)) {
      showInfoPopup({
        title: fileSizeTitle,
        message: fileSizeMessage,
        loop: true,
        type: 'warning',
      });
    }
    if (!validImageType(imageFile) || !validImageSize(imageFile)) return false;

    setImage(imageFile);
    setCoverDetail(getCoverData(imageFile));
    return true;
  };

  const handleDelete = () => {
    setImage(null);
    setCoverDetail((prevVal) => ({ ...prevVal, delete: true, filename: '' }));
  };

  return (
    <CoverContainer>
      <StyledWrapper>
        <FileBox
          image={image}
          src={getUrl(image)}
          text={text}
          tipText={tipText}
        />
        <HiddenInput
          type="file"
          name={name}
          onChange={handleChange}
          accept={AVAILABLE_TYPES.join(', ')}
          ref={inputRef}
        />
      </StyledWrapper>
      <DeleteImageButton visible={!!image} type="button" onClick={handleDelete}>
        <StyledDeleteIcon />
      </DeleteImageButton>
    </CoverContainer>
  );
};

export default CoverInput;
