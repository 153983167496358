import React from 'react';
import { CommunitySelectWrapper } from './styled';
import { FlexBox } from 'pages/PersonalChat/ChatUserList/styled';
import { DropdownItem } from 'pages/PersonalChat/ChatUserList/styled';
import { DropdownItemImage } from 'pages/PersonalChat/ChatUserList/styled';
import {
  DropdownItemName,
  Layout,
} from 'pages/PersonalChat/ChatUserList/styled';
import { LeftCloseArrow } from 'static';

interface CommunityOption {
  id: string;
  name: string;
  logo?: string | null;
}

interface CommunitySelectProps {
  value: string | null;
  nextStep: () => void;
  onChange: (value: string) => void;
  communities: CommunityOption[];
}

const CommunitySelect: React.FC<CommunitySelectProps> = ({
  value,
  nextStep,
  onChange,
  communities,
}) => {
  const handleSelect = (id: string) => {
    onChange(id);
    nextStep();
  };

  return (
    <CommunitySelectWrapper>
      {communities.map((option) => {
        const isSelected = value === option.id;

        return (
          <DropdownItem
            key={option.id}
            onClick={() => handleSelect(option.id)}
            selected={isSelected}
            role="option"
            aria-selected={isSelected}
            style={{ maxWidth: '100%' }}
          >
            <FlexBox>
              <DropdownItemImage>
                {option.logo ? (
                  <img
                    src={option.logo}
                    alt={option.name || 'Community Logo'}
                  />
                ) : (
                  <DropdownItemImage.LogoText>
                    {option.name.charAt(0).toUpperCase()}
                  </DropdownItemImage.LogoText>
                )}
              </DropdownItemImage>
              <DropdownItemName>{option.name}</DropdownItemName>
            </FlexBox>
            <FlexBox>
              <Layout.Header.Close rotate>
                <LeftCloseArrow width={22} height={22} />
              </Layout.Header.Close>
            </FlexBox>
          </DropdownItem>
        );
      })}
    </CommunitySelectWrapper>
  );
};

export default CommunitySelect;
