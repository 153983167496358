import React, { CSSProperties, FC, ReactNode } from 'react';

import {
  StyledMenuItemBlock,
  StyledIcon,
  StyledTitle,
  StyledMenuItemLinkBlock,
  StyledRightIcon,
  CountWrapper,
} from './styled';
import { PlusButtonWrapper } from '../CommunityMenuListWrapper/styled';
import PlusButton from 'components/Buttons/PlusButton';
import { UnrespondedEventsCircle } from 'components/ProfileMenu/styled';

interface CommunityMenuItemProps {
  title: string;
  icon: ReactNode;
  to?: string;
  left?: boolean;
  iconStyles?: CSSProperties;
  RightIcon?: JSX.Element;
  menuBlockStyles?: React.CSSProperties;
  toolTipText?: string;
  count?: number;
  isAdminOrOwner?: boolean;
  onClick?: (data?: string) => void;
  isAddButtonContained?: boolean;
  rightIconRight?: string;
}

const CommunityMenuItem: FC<CommunityMenuItemProps> = ({
  title,
  left,
  icon,
  to,
  iconStyles,
  RightIcon = <></>,
  menuBlockStyles,
  count,
  isAddButtonContained,
  onClick,
  rightIconRight,
  toolTipText,
}) => {
  const tooltipProps = {
    tooltipStyle: {
      fontSize: 12,
      whiteSpace: 'pre-wrap',
      maxWidth: 200,
    },
  };
  return (
    <>
      {to ? (
        <StyledMenuItemLinkBlock
          end
          to={to}
          left={left}
          menuBlockStyles={menuBlockStyles}
        >
          <StyledIcon iconStyles={iconStyles}>{icon}</StyledIcon>
          <StyledTitle>{title}</StyledTitle>
          <StyledRightIcon rightIconRight={rightIconRight}>
            {RightIcon}
            {!!count && (
              <CountWrapper>
                <UnrespondedEventsCircle
                  small
                  positionTop="0px"
                  positionRight="-4px"
                >
                  <span>{count}</span>
                </UnrespondedEventsCircle>
              </CountWrapper>
            )}
          </StyledRightIcon>
          {isAddButtonContained && (
            <PlusButtonWrapper right="10px">
              <PlusButton
                text={toolTipText}
                tooltipStyle={tooltipProps.tooltipStyle}
                position="top-center"
                width="28px"
                contained={isAddButtonContained}
                onClick={() => onClick('chat')}
                adjustToolTipPosition={{ x: -0.7 }}
              />
            </PlusButtonWrapper>
          )}
        </StyledMenuItemLinkBlock>
      ) : (
        <StyledMenuItemBlock>
          <StyledIcon iconStyles={iconStyles}>{icon}</StyledIcon>
          <StyledTitle>{title}</StyledTitle>
        </StyledMenuItemBlock>
      )}
    </>
  );
};

export default CommunityMenuItem;
