import { createSelector } from 'reselect';

import { IRootState } from '../reducers';
import { CommunityRoles } from 'types';
import { CommunitiesState } from '../reducers/communities';

export const communitiesSelector = (state: IRootState): CommunitiesState =>
  state.communities;

export const getCommunities = createSelector(
  communitiesSelector,
  (communities) => communities?.data || [],
);

export const getAvailableCommunities = createSelector(
  getCommunities,
  (communities) =>
    communities.filter(
      (c) => c.users[0]?.communityPermission?.role !== CommunityRoles.BLOCKED,
    ),
);

export const getAvailableActiveCommunities = createSelector(
  getCommunities,
  (communities) =>
    communities.filter(
      (c) =>
        c.users?.[0]?.communityPermission?.role !== CommunityRoles.BLOCKED &&
        c?.isSubscriptionActive,
    ),
);

export const getCommunitiesLoading = createSelector(
  communitiesSelector,
  (communities) => !!communities?.loaders.communities,
);

export const getCommunitiesLoaded = createSelector(
  communitiesSelector,
  (communities) => !!communities?.loaders.communitiesLoaded,
);

export const getCreateCommunityLoading = createSelector(
  communitiesSelector,
  (communities) => !!communities?.loaders.createCommunity,
);

export const getUpdateCommunityLoading = createSelector(
  communitiesSelector,
  (communities) => !!communities?.loaders.updateCommunity,
);

export const getDeleteCommunityLoading = createSelector(
  communitiesSelector,
  (communities) => !!communities?.loaders.deleteCommunity,
);
