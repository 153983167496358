import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import isEmpty from 'lodash/isEmpty';
import i18n from 'i18next';
import moment from 'moment-timezone';

import {
  doCheckIsAuthorized,
  doLogin,
  doLogout,
} from 'store/actionCreators/auth';
// import { doLoginChat } from 'store/actionCreators/chat';
import { getCurrentUserSubscription } from 'store/actionCreators/subscriptions';
import { getCurrentIdToken, getFullAuthUser } from 'store/selectors/auth';
import { getCurrentUser } from 'services/auth';
import { setHeaders, clearHeaders } from 'services';
import { toast } from 'utils/toastAbstraction';
import {
  doGetNotifications,
  doGetTotalChatCount,
  doGetUnRespondedEventsCount,
  editProfile,
} from '../actionCreators/profile';
import { doClearCommunities } from '../actionCreators/communities';

function* checkIsAuthorized(payload: ReturnType<typeof doCheckIsAuthorized>) {
  try {
    let cognitoSession = yield Auth.currentSession();
    const currentUserToken = yield select(getCurrentIdToken);

    if (
      new Date(cognitoSession.idToken?.payload.exp * 1000).getTime() <=
      new Date().getTime()
    ) {
      const cognitoUser = yield Auth.currentAuthenticatedUser();

      yield new Promise((resolve, reject) => {
        cognitoUser.refreshSession(
          cognitoSession.refreshToken,
          (err, session) => {
            if (err) reject();

            const { idToken } = session;
            cognitoSession = session;

            setHeaders(idToken.jwtToken);
            resolve(null);
          },
        );
      });
    } else if (
      !currentUserToken ||
      cognitoSession.idToken.jwtToken !== currentUserToken ||
      !cognitoSession.idToken?.payload?.id
    ) {
      yield setHeaders(cognitoSession.idToken.jwtToken);
    }

    if (
      !currentUserToken ||
      cognitoSession.idToken.jwtToken !== currentUserToken ||
      !cognitoSession.idToken?.payload?.id
    ) {
      const user = yield call(
        getCurrentUser,
        cognitoSession.idToken.payload.id || cognitoSession.idToken.payload.sub,
      );

      yield put(
        doCheckIsAuthorized.success({
          ...user,
          idToken: cognitoSession.idToken.jwtToken,
        }),
      );
      if (payload?.payload?.onSuccess) {
        yield payload.payload.onSuccess(user, cognitoSession);
      }
      yield put(doGetNotifications({ userId: user.id }));
      yield put(doGetTotalChatCount({ userId: user.id }));
      yield put(doGetUnRespondedEventsCount({ userId: user.id }));

      const timezone = moment.tz.guess();

      if (
        user.profile &&
        (!user.profile.timeZone || user.profile.timeZone !== timezone) &&
        timezone
      ) {
        yield put(
          editProfile({
            data: {
              timeZone: timezone,
            },
            id: user.id,
          }),
        );
      }
      if (i18n.language !== user.settings.language) {
        yield delay(0);
        yield i18n.changeLanguage(user.settings.language);
      }
    } else {
      const user = yield select(getFullAuthUser);
      yield put(doCheckIsAuthorized.success(user));
      if (payload?.payload?.onSuccess) {
        yield payload.payload.onSuccess(user, cognitoSession);
      }
      if (i18n.language !== user.settings.language) {
        yield delay(0);
        yield i18n.changeLanguage(user.settings.language);
      }
    }
    yield put(getCurrentUserSubscription.trigger());
  } catch (e) {
    yield put(doCheckIsAuthorized.failure());
    if (e.code && e.message) {
      yield toast(
        i18n.t([`errors.${e.code}`, 'errors.errorToast'], {
          message: e.message,
        }),
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
  }
}

function* login({ payload }: ReturnType<typeof doLogin>) {
  try {
    yield put(doLogin.request());
    const cognitoUser = yield Auth.currentUserInfo();
    if (isEmpty(cognitoUser)) {
      yield Auth.signIn(payload.email, payload.password);
      yield put(doLogin.success());
    }
    yield put(doCheckIsAuthorized.trigger({}));
  } catch (error) {
    yield put(doLogin.failure());

    if (payload.onFailure) {
      yield call(payload.onFailure, error);
    }
  }
}

function* logout() {
  try {
    yield Auth.signOut();
    yield clearHeaders();
    yield put(doClearCommunities.trigger());
    yield put(doCheckIsAuthorized.failure());
  } catch (e) {
    yield put(doLogout.failure(e));
  }
}

export default function* () {
  yield takeLatest(doLogout.TRIGGER, logout);
  yield takeLatest(doCheckIsAuthorized.TRIGGER, checkIsAuthorized);
  yield takeLatest(doLogin.TRIGGER, login);
}
