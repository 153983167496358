import React, { useCallback, useState } from 'react';
import {
  ModalWrapper,
  CloseButtonWrapper,
  ModalImage,
  ButtonWrapper,
} from './styled';
import { createPortal } from 'react-dom';
import { Button, CloseButton, PlusButton } from 'components';

const ImageModal = ({ isOpen, imgSrc, altText, onClose }) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const handleZoomIn = useCallback(
    () => setZoomLevel((prev) => Math.min(prev + 0.2, 3)),
    [],
  );

  const handleZoomOut = useCallback(
    () => setZoomLevel((prev) => Math.max(prev - 0.2, 1)),
    [],
  );

  const resetZoom = useCallback(() => setZoomLevel(1), []);

  return isOpen === true
    ? createPortal(
        <ModalWrapper>
          <CloseButtonWrapper>
            <CloseButton
              style={{ width: '40px', height: '40px' }}
              hidden={false}
              onClick={onClose}
              height="16px"
              width="16px"
            />
          </CloseButtonWrapper>
          <div style={{ position: 'relative' }}>
            <ModalImage
              src={imgSrc}
              alt={altText}
              style={{
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.3s ease',
                cursor: zoomLevel > 1 ? 'move' : 'default',
              }}
            />
            <ButtonWrapper>
              {/* <Button onClick={handleZoomIn} width="40px">
                <StyledPlusIcon width="18" height="18" />
              </Button> */}
              <PlusButton
                text={'zoom in'}
                // tooltipStyle={tooltipProps.tooltipStyle}
                position="top-center"
                width="40px"
                contained={true}
                onClick={handleZoomIn}
                // adjustToolTipPosition={{ x: -0.7 }}
              />
              <Button onClick={resetZoom} variant="secondary" width="auto">
                Reset
              </Button>
              <PlusButton
                text={'zoom out'}
                // tooltipStyle={tooltipProps.tooltipStyle}
                position="top-center"
                width="40px"
                contained={true}
                onClick={handleZoomOut}
                // adjustToolTipPosition={{ x: -0.7 }}
                isMinusIcon
              />
            </ButtonWrapper>
          </div>
        </ModalWrapper>,
        document.getElementById('modal'),
      )
    : null;
};

export default ImageModal;
