import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { get } from 'lodash/fp';

import { doCheckIsAuthorized, doLogout } from 'store/actionCreators/auth';
import {
  doGetNotifications,
  editProfile,
  doMarkNotificationsAsRead,
  doChangeProfileSettings,
  doDeleteProfile,
  doChangeProfileLanguage,
  editMemberProfile,
  doCreateEventReply,
  doUpdateEventReply,
  doDeleteEventReply,
  doLinkGoogleCalendar,
  doUnlinkGoogleCalendar,
  doGetUnRespondedEventsCount,
  doGetMyEvents,
  doClearMyEvents,
  doGetFilteredUnrespondedEventsCount,
  doLinkAppleCalendar,
  doUnlinkAppleCalendar,
  doGetTotalChatCount,
} from 'store/actionCreators/profile';
import { doChangeUserProfile } from 'store/actionCreators/currentCommunity/users';
import { getFullAuthUser } from 'store/selectors/auth';
import {
  requestProfileEdit,
  getNotifications,
  markNotificationsAsRead,
  changeProfileSettings,
  deleteProfile,
  putCalendarToken,
  revokeCalendarToken,
} from 'services/user';
import {
  createEventReply,
  updateEventReply,
  deleteEventReply,
  createOverallEventReply,
  updateOverallEventReply,
  deleteOverallEventReply,
  getUnrespondedEventsCount,
  requestMyEvents,
} from 'services/events';
import { getUnSeenChatSeenCount } from 'services/chat';

function* onEditProfile({ payload }: ReturnType<typeof editProfile>) {
  try {
    const data = yield call(
      requestProfileEdit,
      payload.data,
      payload.id,
      payload.communityId,
    );
    if (!payload.isMemberProfileEdit) {
      yield put(editProfile.success(data));
    } else {
      yield put(doChangeUserProfile.success(data));
      yield put(editMemberProfile.success());
    }
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(editProfile.failure());
    if (payload.onFailure) {
      yield call(payload.onFailure);
    }
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

function* onGetTotalChatCountCount({
  payload,
}: ReturnType<typeof doGetTotalChatCount>) {
  try {
    const data = yield call(
      getUnSeenChatSeenCount,
      payload.userId,
      payload?.communityId,
    );
    yield put(doGetTotalChatCount.success(data));
  } catch ({ response }) {
    yield put(editProfile.failure());
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

function* onGetNotifications({
  payload,
}: ReturnType<typeof doGetNotifications>) {
  try {
    const data = yield call(getNotifications, payload.userId);
    yield put(doGetNotifications.success(data));
  } catch ({ response }) {
    yield put(editProfile.failure());
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

function* onMarkNotificationsAsRead({
  payload,
}: ReturnType<typeof doMarkNotificationsAsRead>) {
  try {
    yield call(markNotificationsAsRead, payload.userId, payload.notifications);
    yield put(doMarkNotificationsAsRead.success());
  } catch ({ response }) {
    yield put(doMarkNotificationsAsRead.failure());
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

function* onChangeProfileSettings({
  payload,
}: ReturnType<typeof doChangeProfileSettings>) {
  try {
    const data = yield call(
      changeProfileSettings,
      payload.userId,
      payload.data,
    );
    yield put(doChangeProfileSettings.success(data));
    if (payload.data.language) {
      yield put(doChangeProfileLanguage.trigger(payload.data.language));
      payload.i18n.changeLanguage(payload.data.language);
    }
    if (payload.onSuccess) {
      yield call(payload.onSuccess);
    }
  } catch ({ response }) {
    yield put(doChangeProfileSettings.failure());
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

function* onDeleteProfile() {
  try {
    const user = yield select(getFullAuthUser);
    const data = yield call(deleteProfile, user);
    yield put(doDeleteProfile.success(data));
    yield put(doLogout.trigger());
  } catch ({ response }) {
    yield put(doDeleteProfile.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

function* onCreateEventReply({
  payload,
}: ReturnType<typeof doCreateEventReply>) {
  try {
    let data;

    if (payload.groupId) {
      data = yield call(
        createEventReply,
        payload.groupId,
        payload.eventId,
        payload.user.id,
        payload.data,
        payload.eventSeriesId,
        payload.type,
        payload.from,
      );
    } else {
      data = yield call(
        createOverallEventReply,
        payload.communityId,
        payload.eventId,
        payload.data,
        payload.user.id,
        payload.eventSeriesId,
        payload.type,
        payload.from,
      );
    }

    yield put(
      doCreateEventReply.success({
        response: Array.isArray(data) ? data : [data],
        user: payload.user,
        eventId: payload.eventId,
      }),
    );
    yield put(doGetUnRespondedEventsCount.trigger());

    if (payload.filters.awaiting) {
      yield put(doClearMyEvents());
      const filters = payload.filters;
      yield put(
        doGetMyEvents.trigger({
          eventTypeId: filters.eventType.value,
          awaiting: filters.awaiting,
          from:
            filters.from instanceof Date
              ? filters.from.toISOString()
              : new Date(filters.from).toISOString(),
          key: filters.key,
          limit: 30,
          nomination: filters.nomination,
          page: 1,
          unrespondedCount: payload.unrespondedCount,
        }),
      );
    } else if (payload.filters) {
      const filters = payload.filters;
      yield put(
        doGetFilteredUnrespondedEventsCount.trigger({
          eventTypeId: filters.eventType.value,
          awaiting: filters.awaiting,
          from:
            filters.from instanceof Date
              ? filters.from.toISOString()
              : new Date(filters.from).toISOString(),
          key: filters.key,
          limit: 1,
          nomination: filters.nomination,
          page: 1,
        }),
      );
    }
  } catch ({ response }) {
    yield put(doCreateEventReply.failure(response.data));
  }
}

function* onUpdateEventReply({
  payload,
}: ReturnType<typeof doUpdateEventReply>) {
  try {
    let data;

    if (payload.groupId) {
      data = yield call(
        updateEventReply,
        payload.groupId,
        payload.eventId,
        payload.user.id,
        payload.eventReplyId,
        payload.data,
        payload.eventSeriesId,
        payload.type,
        payload.from,
      );
    } else {
      data = yield call(
        updateOverallEventReply,
        payload.communityId,
        payload.eventId,
        payload.eventReplyId,
        payload.data,
        payload.user.id,
        payload.eventSeriesId,
        payload.type,
        payload.from,
      );
    }

    yield put(
      doUpdateEventReply.success({
        response: Array.isArray(data) ? data : [data],
        user: payload.user,
        eventId: payload.eventId,
      }),
    );
    yield put(doGetUnRespondedEventsCount.trigger());
  } catch ({ response }) {
    if (response) {
      yield put(doUpdateEventReply.failure(response.data));
    }
  }
}

function* onDeleteEventReply({
  payload,
}: ReturnType<typeof doDeleteEventReply>) {
  try {
    let data;

    if (payload.groupId) {
      data = yield call(
        deleteEventReply,
        payload.groupId,
        payload.eventId,
        payload.userId,
        payload.eventReplyId,
        payload.eventSeriesId,
        payload.type,
        payload.from,
      );
    } else {
      data = yield call(
        deleteOverallEventReply,
        payload.communityId,
        payload.eventId,
        payload.eventReplyId,
        payload.userId,
        payload.eventSeriesId,
        payload.type,
        payload.from,
      );
    }

    yield put(
      doDeleteEventReply.success({
        ...data,
        userId: payload.userId,
      }),
    );
    yield put(doClearMyEvents());
    const filters = payload.filters;
    yield put(
      doGetMyEvents.trigger({
        eventTypeId: filters.eventType.value,
        awaiting: filters.awaiting,
        from:
          filters.from instanceof Date
            ? filters.from.toISOString()
            : new Date(filters.from).toISOString(),
        key: filters.key,
        limit: 30,
        nomination: filters.nomination,
        page: 1,
      }),
    );
    yield put(doGetUnRespondedEventsCount.trigger());
  } catch ({ response }) {
    yield put(doDeleteEventReply.failure(response.data));
  }
}

function* onLinkGoogleCalendar({
  payload,
}: ReturnType<typeof doLinkGoogleCalendar>) {
  try {
    const res = yield call(putCalendarToken, payload.code);
    yield put(doLinkGoogleCalendar.success(res.googleCalendarRefreshToken));
    yield call(payload.SetAllCommunities);
    yield call(payload.onSuccess);
  } catch (_) {
    yield call(payload.onFailure);
  }
}

function* onUnlinkGoogleCalendar({
  payload,
}: ReturnType<typeof doUnlinkGoogleCalendar>) {
  try {
    yield call(revokeCalendarToken);
    yield put(doUnlinkGoogleCalendar.success());
    yield put(
      doChangeProfileSettings.success({
        ...payload.settings,
        syncEventsGoogleFromCommunities: [],
        communityIdToCalendarId: {},
      }),
    );
    yield call(payload.onSuccess);
  } catch (_) {
    yield put(doUnlinkGoogleCalendar.failure());
    yield call(payload.onFailure);
  }
}

function* onLinkAppleCalendar({
  payload,
}: ReturnType<typeof doLinkAppleCalendar>) {
  try {
    const data = yield call(
      changeProfileSettings,
      payload.userId,
      payload.data,
    );
    yield put(doLinkAppleCalendar.success(data));
    if (payload.onSuccess) yield call(payload.onSuccess);
  } catch (err) {
    console.log(err);
  }
}

function* onUnlinkAppleCalendar({
  payload,
}: ReturnType<typeof doLinkAppleCalendar>) {
  try {
    const data = yield call(
      changeProfileSettings,
      payload.userId,
      payload.data,
    );
    yield put(doUnlinkAppleCalendar.success(data));
    if (payload.onSuccess) yield call(payload.onSuccess);
  } catch (err) {
    console.log(err);
  }
}

function* onGetUnrespondedEventsCount() {
  try {
    const res = yield call(getUnrespondedEventsCount);
    yield put(doGetUnRespondedEventsCount.success(res.totalCount));
  } catch (_) {
    yield put(doGetUnRespondedEventsCount.failure());
  }
}

function* onGetFilteredUnrespondedEventsCount({
  payload,
}: ReturnType<typeof doGetFilteredUnrespondedEventsCount>) {
  try {
    const eventsData = yield call(
      requestMyEvents,
      payload.from,
      payload.eventTypeId,
      payload.key,
      payload.page,
      payload.limit,
      payload.awaiting,
      payload.nomination,
    );
    yield put(
      doGetFilteredUnrespondedEventsCount.success({
        filteredUnrespondedEventsCount: eventsData.totalUnrespondedEventsCount,
      }),
    );
  } catch (_) {
    yield put(doGetFilteredUnrespondedEventsCount.failure());
  }
}

export default function* () {
  yield takeEvery(editProfile, onEditProfile);
  yield takeEvery(doDeleteProfile, onDeleteProfile);
  yield takeLatest(doGetNotifications, onGetNotifications);
  yield takeLatest(doMarkNotificationsAsRead, onMarkNotificationsAsRead);
  yield takeLatest(doChangeProfileSettings, onChangeProfileSettings);
  yield takeLatest(doCreateEventReply, onCreateEventReply);
  yield takeLatest(doUpdateEventReply, onUpdateEventReply);
  yield takeLatest(doDeleteEventReply, onDeleteEventReply);
  yield takeLatest(doLinkGoogleCalendar, onLinkGoogleCalendar);
  yield takeLatest(doUnlinkGoogleCalendar, onUnlinkGoogleCalendar);
  yield takeLatest(doGetUnRespondedEventsCount, onGetUnrespondedEventsCount);
  yield takeLatest(doGetTotalChatCount, onGetTotalChatCountCount);
  yield takeLatest(
    doGetFilteredUnrespondedEventsCount,
    onGetFilteredUnrespondedEventsCount,
  );
  yield takeLatest(doLinkAppleCalendar, onLinkAppleCalendar);
  yield takeLatest(doUnlinkAppleCalendar, onUnlinkAppleCalendar);
}
