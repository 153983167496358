import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 14px;
  flex-direction: column;
  position: sticky;
  cursor: pointer;
  top: 20px;
  z-index: 20;
`;

export const DateLabel = styled.span`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: -12px;
  /* min-width: 210px; */
  z-index: 1;
  font-size: 12px;
  font-weight: 400;
  border-radius: 999999px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  padding: 4px 16px;
  background: ${({ theme }) => theme.color.blackSqueeze};
`;
