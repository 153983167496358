import styled from 'styled-components';
import { breakpoints } from 'theme';

export const MainContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${({ reverse }) => (reverse ? 'row' : 'row-reverse')};
  align-items: center;
  justify-content: center;
  border-radius: ${({ reverse }) =>
    reverse ? '0px 6px 6px 6px' : '6px 0px 6px 6px'};
  margin: 22px 0px;
  gap: 20px;
  background-color: ${({ theme, reverse }) =>
    !reverse ? theme.color.blackSqueeze : '#F1F9F9'};
  width: fit-content;
  max-width: ${({ isOpen }) => (isOpen ? '78%' : '60%')};
  padding: 22px 18px 22px 18px;
  @media ${breakpoints.downLgPlus} {
    /* padding: 16px 12px 16px 12px; */
    margin: 16px 0px;
    gap: 12px;
  }
  @media ${breakpoints.downLg} {
    max-width: 80%;
  }
  @media ${breakpoints.downSmPlus} {
    max-width: 90%;
  }
`;

export const MessagePieceWrapper = styled.div`
  position: absolute;
  top: ${({ top }) => (top ? '-2px' : '-4px')};
  right: ${({ reverse }) => (reverse ? 'auto' : '-8px')};
  left: ${({ reverse }) => (reverse ? '-8px' : 'auto')};
  transform: ${({ reverse }) =>
    reverse ? 'rotateY(180deg)' : 'rotateY(0deg)'};
  path {
    fill: ${({ theme, reverse }) =>
      !reverse ? theme.color.blackSqueeze : '#F1F9F9'};
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  margin-bottom: ${({ extraHeight }) => (extraHeight ? '4px' : '0px')};
  cursor: pointer;
  & > img,
  & > span {
    object-fit: cover;
    border-radius: 99999px;
    width: 46px;
    height: 46px;
    aspect-ratio: 1;
    outline: calc(46px / 2) solid #fff1;
    outline-offset: calc(46px / -2);
    cursor: pointer;
    transition: 0.3s;
    :hover {
      outline: 2px solid #33aba7;
      outline-offset: 2px;
    }
  }
`;

export const CommunityLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  position: absolute;
  bottom: ${({ bottom }) => (bottom ? '-8px' : '-10px')};
  right: -4px;
  & > img {
    object-fit: cover;
    border-radius: 99999px;
    width: 26px;
    border: 2.5px solid
      ${({ theme, reverse }) =>
        !reverse ? theme.color.blackSqueeze : '#F1F9F9'};
    height: 26px;
  }
`;

export const LogoText = styled.span`
  display: flex;
  width: ${({ size }) => size || '40px'};
  height: ${({ size }) => size || '40px'};
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: white;
  font-size: ${({ fontSize }) => fontSize || '16px'};
  line-height: ${({ lineHeight }) => lineHeight || '16px'};
  color: ${({ theme }) => theme.color.black};
  border-radius: 99999px;
  text-transform: uppercase;
  border: 2px solid ${({ theme }) => theme.color.brightTurquoise};
`;

export const MessageContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  gap: 10px;
  overflow: hidden;
  padding: ${({ reverse, extraPadding }) =>
    reverse
      ? extraPadding
        ? '0px 58px 0px 4px'
        : '0px 10px 0px 4px'
      : extraPadding
      ? '0px 58px 0px 0px'
      : '0px 10px 0px 0px'};
  color: ${({ theme }) => theme.color.pickledBluewood};
  @media ${breakpoints.downLg} {
    /* padding: ${({ reverse }) =>
      reverse ? '0px 4px 0px 4px' : '0px 4px 0px 0px'}; */
  }
`;

export const TitleTime = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const MessageTitle = styled.div`
  font-weight: bold;
  display: flex;
  text-align: left;
  line-height: 1.2;
  font-size: 14px;
`;

export const MessageContent = styled.p`
  font-size: 14px;
  font-weight: 400;
  overflow-wrap: break-word;
  line-height: 17px;
  text-align: left;
  width: 100%;
  line-break: normal;
  white-space: pre-wrap;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.brightTurquoise} !important;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const MessageTime = styled.p`
  font-size: 10px;
  display: flex;
  font-weight: 600;
  color: ${({ theme, reverse }) => (reverse ? '#304659' : '#304659')};
  white-space: nowrap;
  position: absolute;
  right: 17px;
  bottom: 6px;
  line-height: 10px;
  /* @media ${breakpoints.downLgPlus} {
    bottom: 6px;
    right: 12px;
  } */
`;

export const SameAuthorMessageContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${({ reverse }) => (reverse ? 'row' : 'row-reverse')};
  align-items: center;
  font-size: 14px;
  max-width: ${({ isOpen }) => (isOpen ? '78%' : '60%')};
  justify-content: center;
  color: ${({ theme }) => theme.color.pickledBluewood};
  border-radius: ${({ reverse }) =>
    reverse ? '0px 6px 6px 6px' : '6px 0px 6px 6px'};
  gap: 20px;
  /* margin: ${({ reverse }) =>
    reverse ? '0px  0px 16px 0px ' : '0px 0px 16px 0px'}; */
  margin: 22px 0px;
  background-color: ${({ theme, reverse }) =>
    !reverse ? theme.color.blackSqueeze : '#F1F9F9'};
  width: fit-content;
  padding: 22px 18px 22px 18px;
  @media ${breakpoints.downLgPlus} {
    /* padding: 16px 12px 16px 12px; */
    margin: 16px 0px;
    gap: 12px;
  }
  @media ${breakpoints.downLg} {
    max-width: 80%;
  }
  @media ${breakpoints.downSmPlus} {
    max-width: 90%;
  }
`;
export const ImageContent = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;
  z-index: 40;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
export const FileContainer = styled.a`
  padding: 16px;
  width: 100%;
  max-width: 400px;
  height: 100%;
  max-width: 100%;
  border-radius: 8px;
  text-decoration: none;
  color: ${({ theme }) => theme.color.pickledBluewood};
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
`;
export const FileName = styled.p`
  white-space: pre-wrap;
  line-clamp: 3;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 4px 0px;
`;

export const FileType = styled.p`
  display: block;
  margin-left: auto;
  padding: 2px 8px;
  white-space: pre-wrap;
  text-align: right;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 9999px;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  border-radius: 9999px;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  opacity: 0;
  transition: opacity 0.3s ease;
  :hover {
    background-color: ${({ theme }) => theme.color.brightTurquoise};
    svg {
      stroke: ${({ theme }) => theme.color.pickledBluewood};
    }
    p {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
  z-index: 50;
  p {
    color: ${({ theme }) => theme.color.baliHai};
    font-size: 12px;
    font-weight: 400;
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  &:hover ${IconWrapper} {
    opacity: 1;
  }
`;
