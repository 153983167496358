import React, { FC, useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { hasCommunityAdminPermissions, hasGroupAdminPermissions } from 'utils';
import { searchByProfileName } from 'utils/users';
import { GroupRolesList } from 'components';
import { useDebounce } from 'hooks';
import { useRoles } from './hooks';
import {
  getCurrentGroup,
  getCurrentUserInGroup,
  getGroupAdmins,
  getGroupLeaders,
  getGroupMembers,
} from 'store/selectors/currentGroup';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import { closeModal } from 'store/actionCreators/modal';
import { doDeleteGroupUser } from 'store/actionCreators/currentGroup';
import {
  getAdminsConfig,
  getGroupLeadersConfig,
  getMembersConfig,
} from './tableConfig';
import { GroupUser, GroupRoles } from 'types';

import {
  StyledAddMembersButton,
  Wrapper,
  StyledTableWrapper,
  BtnWarpper,
} from './styled';
import { DragDropContext } from 'react-beautiful-dnd';

interface GroupMembersProps {
  filterValue: string;
  headerHeight: number;
}

const GroupMembers: FC<GroupMembersProps> = ({ filterValue, headerHeight }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const admins = useSelector(getGroupAdmins);
  const leaders = useSelector(getGroupLeaders);
  const members = useSelector(getGroupMembers);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const currentUserInGroup = useSelector(getCurrentUserInGroup);
  const community = useSelector(getCurrentCommunity);
  const group = useSelector(getCurrentGroup);
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const debouncedValue = useDebounce(filterValue, 500);
  const [adminsData, setAdminsData] = useState<GroupUser[]>(admins);
  const [leadersData, setLeadersData] = useState<GroupUser[]>(leaders);
  const [membersData, setMembersData] = useState<GroupUser[]>(members);
  const adminsConfig = getAdminsConfig(t);
  const leadersConfig = getGroupLeadersConfig(t);
  const membersConfig = getMembersConfig(t);

  const headHeight = useMemo(() => {
    return headerHeight;
  }, [headerHeight]);

  const onSuccess = () => {
    dispatch(closeModal());
    addToast(t('group.deleteUserSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onDeleteGroupUser = (user: GroupUser) => {
    dispatch(
      doDeleteGroupUser({
        communityId: community.id,
        groupId: group.id,
        users: [user.id],
        onSuccess,
      }),
    );
  };

  const { onAdd, onEdit, onDragEnd } = useRoles(
    community,
    group,
    [...admins, ...leaders, ...members],
    onDeleteGroupUser,
    currentUserInGroup,
    currentUser,
  );

  useEffect(() => {
    if (hasMounted) {
      setAdminsData(() => searchByProfileName(filterValue, admins));
      setMembersData(() => searchByProfileName(filterValue, members));
      setLeadersData(() => searchByProfileName(filterValue, leaders));
    }
    setHasMounted(true);
  }, [admins, debouncedValue, filterValue, leaders, members]);
  const haveBtn =
    hasCommunityAdminPermissions(currentUser.id, community) ||
    hasGroupAdminPermissions(currentUser.id, community, group.id);
  return (
    <Wrapper>
      <BtnWarpper>
        {(hasCommunityAdminPermissions(currentUser.id, community) ||
          hasGroupAdminPermissions(currentUser.id, community, group.id)) && (
          <StyledAddMembersButton onClick={onAdd}>
            {t('group.addParticipants')}
          </StyledAddMembersButton>
        )}
      </BtnWarpper>
      <StyledTableWrapper headHeight={headHeight} haveBtn={haveBtn}>
        <DragDropContext onDragEnd={onDragEnd}>
          <GroupRolesList
            users={adminsData}
            config={adminsConfig}
            usersRole={GroupRoles.ADMIN}
            currentUser={currentUser}
            currentUserInGroup={currentUserInGroup}
            onEdit={onEdit}
            onDelete={onDeleteGroupUser}
            search={debouncedValue}
          />
          <GroupRolesList
            users={leadersData}
            config={leadersConfig}
            usersRole={GroupRoles.GROUP_LEAD}
            currentUser={currentUser}
            currentUserInGroup={currentUserInGroup}
            onEdit={onEdit}
            onDelete={onDeleteGroupUser}
            search={debouncedValue}
          />
          <GroupRolesList
            users={membersData}
            config={membersConfig}
            usersRole={GroupRoles.MEMBER}
            currentUser={currentUser}
            currentUserInGroup={currentUserInGroup}
            onEdit={onEdit}
            onDelete={onDeleteGroupUser}
            search={debouncedValue}
          />
        </DragDropContext>
      </StyledTableWrapper>
    </Wrapper>
  );
};

export default GroupMembers;
