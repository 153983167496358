import styled, { css } from 'styled-components';

import { EditIcon } from 'static';

export const defaultTextStyle = () => `
  color: ${({ theme }) => theme.color.shark};
`;

export const Label = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.baliHai};
`;

export const Text = styled.span`
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  ${defaultTextStyle};
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  padding-right: 10px;
  text-align: left;
`;

export const FieldEditIcon = styled(EditIcon)`
  padding: 1px;
  width: 18px;
  height: 18px;
  position: absolute;
  right: 4%;
  cursor: pointer;
  &:active {
    border: 1px dashed black;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: 1px solid #00000021;
  border-radius: 5px;
  width: 100%;
  gap: 3px;
  background-color: #f7faf9;
  ${({ addMaxWidth }) =>
    addMaxWidth &&
    css`
      @media (max-width: 1460px) {
        max-width: 48% !important;
      }
      @media (max-width: 728px) {
        max-width: 80% !important;
      }
      @media (max-width: 428px) {
        max-width: 100% !important;
      }
    `}
  &:hover ${FieldEditIcon} {
    path {
      fill: ${({ theme }) => theme.color.black};
    }
  }
  /* &:active ${FieldEditIcon} {
    border: 1px dashed black;
  } */

  &:first-child {
    margin-top: 0;
  }
`;

export const StyledText = styled.p`
  white-space: pre-wrap;
  line-break: normal;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  ${defaultTextStyle};
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  padding-right: 10px;
  text-align: left;
  a {
    color: ${({ theme }) => theme.color.brightTurquoise};
    word-wrap: break-word;
  }
`;
