// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { assoc, compose } from 'lodash/fp';

import {
  doChangeProfileSettings,
  doGetNotifications,
  doMarkNotificationsAsRead,
  editProfile,
  doAddNotification,
  doChangeProfileLanguage,
  editMemberProfile,
  doLinkGoogleCalendar,
  doUnlinkGoogleCalendar,
  doLinkAppleCalendar,
  doUnlinkAppleCalendar,
  doGetTotalChatCount,
  doUpdateTotalChatCount,
} from 'store/actionCreators/profile';
import {
  doCheckIsAuthorized,
  doLogin,
  doLogout,
} from 'store/actionCreators/auth';
import { FullUser } from 'types';

type AuthStateLoaders = {
  editProfile: boolean;
  linkGoogleCalendar: boolean;
  unlinkGoogleCalendar: boolean;
  getNotifications: boolean;
  changeProfileSettings: boolean;
  linkAppleCalendar: boolean;
  unLinkAppleCalendar: boolean;
  unSeenMessageCount: boolean;
};

export interface AuthState {
  user: FullUser | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  loaders: AuthStateLoaders;
}

const initialState: AuthState = {
  isLoading: false,
  isAuthenticated: false,
  user: null,
  loaders: {
    editProfile: false,
    linkGoogleCalendar: false,
    unlinkGoogleCalendar: false,
    getNotifications: false,
    changeProfileSettings: false,
    linkAppleCalendar: false,
    unLinkAppleCalendar: false,
    unSeenMessageCount: false,
  },
};

export default (
  state: AuthState = initialState,
  { type, payload },
): AuthState => {
  switch (type) {
    case doCheckIsAuthorized.TRIGGER:
    case doLogin.TRIGGER:
      return compose(
        assoc(['isLoading'], true),
        assoc(['isAuthenticated'], false),
      )(state);
    case doCheckIsAuthorized.FAILURE:
    case doLogin.FAILURE:
    case doLogout.SUCCESS:
      return compose(
        assoc(['isLoading'], false),
        assoc(['isAuthenticated'], false),
        assoc(['user'], null),
      )(state);
    case doCheckIsAuthorized.SUCCESS:
    case doLogin.SUCCESS:
      return compose(
        assoc(['isLoading'], false),
        assoc(['isAuthenticated'], true),
        assoc(['user'], payload),
      )(state);
    case editProfile.TRIGGER:
      return compose(assoc(['loaders', 'editProfile'], true))(state);
    case editProfile.SUCCESS:
      return compose(
        assoc(['user', 'profile'], payload),
        assoc(['loaders', 'editProfile'], false),
      )(state);
    case editProfile.FAILURE:
      return compose(assoc(['loaders', 'editProfile'], false))(state);
    case doLinkGoogleCalendar.TRIGGER:
      return compose(assoc(['loaders', 'linkGoogleCalendar'], true))(state);
    case doLinkGoogleCalendar.SUCCESS:
      return compose(
        assoc(['loaders', 'linkGoogleCalendar'], false),
        assoc(['user', 'googleCalendarRefreshToken'], payload),
      )(state);
    case doLinkGoogleCalendar.FAILURE:
      return compose(assoc(['loaders', 'linkGoogleCalendar'], false))(state);
    case doUnlinkGoogleCalendar.TRIGGER:
      return compose(assoc(['loaders', 'unlinkGoogleCalendar'], true))(state);
    case doUnlinkGoogleCalendar.SUCCESS:
      return compose(
        assoc(['loaders', 'unlinkGoogleCalendar'], false),
        assoc(['user', 'googleCalendarRefreshToken'], null),
      )(state);
    case doUnlinkGoogleCalendar.FAILURE:
      return compose(assoc(['loaders', 'unlinkGoogleCalendar'], false))(state);
    case editMemberProfile.SUCCESS:
      return compose(assoc(['loaders', 'editProfile'], false))(state);
    case doGetNotifications.TRIGGER:
      return compose(assoc(['loaders', 'getNotifications'], true))(state);
    case doGetNotifications.SUCCESS:
      return compose(
        assoc(['user', 'notifications'], payload),
        assoc(['loaders', 'getNotifications'], false),
      )(state);

    case doGetTotalChatCount.TRIGGER:
      return compose(assoc(['loaders', 'unSeenMessageCount'], true))(state);
    case doGetTotalChatCount.SUCCESS:
      return compose(
        assoc(['user', 'unseenChatCount'], payload.totalUnreadCount),
        assoc(['loaders', 'unSeenMessageCount'], false),
      )(state);
    case doUpdateTotalChatCount.SUCCESS:
      return compose(
        assoc(
          ['user', 'unseenChatCount'],
          state.user.unseenChatCount > 0
            ? state.user.unseenChatCount - payload.count
            : null,
        ),
      )(state);
    case doMarkNotificationsAsRead.SUCCESS:
      return compose(
        assoc(
          ['user', 'notifications', 'data'],
          state.user.notifications.data.map((n) => ({ ...n, isRead: true })),
        ),
        assoc(['user', 'notifications', 'unreadCount'], 0),
      )(state);
    case doChangeProfileLanguage.TRIGGER:
      return compose(assoc(['user', 'settings', 'language'], payload))(state);
    case doChangeProfileSettings.TRIGGER:
      return compose(assoc(['loaders', 'changeProfileSettings'], true))(state);
    case doChangeProfileSettings.SUCCESS:
      return compose(
        assoc(['user', 'settings'], payload),
        assoc(['loaders', 'changeProfileSettings'], false),
      )(state);
    case doAddNotification.TRIGGER:
      return compose(
        assoc(
          ['user', 'notifications', 'data'],
          [payload, ...state.user.notifications.data],
        ),
      )(state);
    case doLinkAppleCalendar.TRIGGER:
      return assoc(['loaders', 'linkAppleCalendar'], true)(state);
    case doLinkAppleCalendar.SUCCESS:
      return compose(
        assoc(['user', 'settings'], payload),
        assoc(['loaders', 'linkAppleCalendar'], false),
      )(state);
    case doUnlinkAppleCalendar.TRIGGER:
      return assoc(['loaders', 'unLinkAppleCalendar'], true)(state);
    case doUnlinkAppleCalendar.SUCCESS:
      return compose(
        assoc(['user', 'settings'], payload),
        assoc(['loaders', 'unLinkAppleCalendar'], false),
      )(state);
    default:
      return state;
  }
};
