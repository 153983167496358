import React, { FC, useState } from 'react';
import {
  Control,
  FieldError,
  useController,
  UseFormMethods,
} from 'react-hook-form';
import { debounce } from 'lodash';

import EmojiPicker from './EmojiPicker';

import {
  StyledLabel,
  StyledArea,
  StyledErrorMessage,
  StyledWrapper,
  LabelWrapper,
} from './styled';

interface EmojiAreaProps extends Partial<Pick<UseFormMethods, 'register'>> {
  width?: string;
  disabled?: boolean;
  name: string;
  placeholder?: string;
  label?: string;
  error?: FieldError;
  control: Control;
  blockBreakLines?: boolean;
  positionRight?: string;
  positionTop?: string;
  rows?: number;
  withEmojiIcon?: boolean;
  maxLength?: number;
  countFontSize?: number;
  countFontWeight?: number;
  top?: string;
  fontSize?: string;
  fontWeightLabel?: number;
  autoFocus?: boolean;
}

const EmojiArea: FC<EmojiAreaProps> = ({
  register,
  width,
  disabled,
  name,
  placeholder,
  label,
  error,
  control,
  blockBreakLines,
  positionRight,
  positionTop,
  rows = 10,
  withEmojiIcon,
  maxLength,
  countFontSize,
  fontSize,
  countFontWeight,
  top,
  fontWeightLabel,
  autoFocus = false,
}) => {
  const [position, setPosition] = useState<number>(0);
  const { field } = useController({
    name,
    control,
  });

  const setCursorLocation = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPosition(e.target.selectionStart);
    },
    250,
  );

  return (
    <StyledWrapper>
      <LabelWrapper>
        {label && (
          <StyledLabel
            htmlFor={name}
            error={error}
            fontSize={fontSize}
            fontWeight={fontWeightLabel}
          >
            {label ? label : ''}
          </StyledLabel>
        )}
        {maxLength && (
          <StyledLabel
            htmlFor={name}
            error={error}
            fontSize={countFontSize}
            fontWeight={countFontWeight}
          >
            ({field.value.length} / {maxLength})
          </StyledLabel>
        )}
      </LabelWrapper>
      <StyledArea
        disabled={disabled}
        rows={rows}
        width={width}
        id={name}
        name={name}
        placeholder={placeholder}
        {...register}
        error={error}
        borderRadius={4}
        value={field.value}
        onChange={field.onChange}
        onSelect={setCursorLocation}
        withEmojiIcon={withEmojiIcon}
        maxLength={maxLength}
        autoFocus={autoFocus}
      />
      {error && <StyledErrorMessage>{error.message}</StyledErrorMessage>}
      <EmojiPicker
        top={top}
        value={field.value}
        setValue={field.onChange}
        cursorPosition={position}
        setCursorPosition={setPosition}
        positionRight={positionRight}
        positionTop={positionTop}
      />
    </StyledWrapper>
  );
};

export default EmojiArea;
