import React, { FC, useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash/fp';
import Linkify from 'react-linkify';
import { axiosInstance as axios } from 'services';

import {
  AvatarContainer,
  CommunityLogo,
  Container,
  FileContainer,
  FileName,
  FileType,
  IconWrapper,
  ImageContent,
  LogoText,
  MainContainer,
  MessageContainer,
  MessageContent,
  MessagePieceWrapper,
  MessageTime,
  MessageTitle,
  SameAuthorMessageContent,
  TitleTime,
} from './styled';
import { FileIcon, IconDownload, MessagePiece } from 'static';
import { Type } from 'store/reducers/chat';
import ImageModal from './ImageModal/ImageModal';
import toast from 'react-hot-toast';

interface MessageProps {
  content: string;
  sameAuthor: boolean;
  date: string;
  userId: string;
  sameDay: boolean;
  time: string;
  urlUser: string;
  senderId: string;
  senderLastName: string;
  senderFirstName: string;
  isOpen: boolean;
  openViewMemberSidebar: (userId: string) => void;
  mediaType: Type;
  mediaUrl: string;
  // communityData: {
  //   logo: string;
  //   name: string;
  // };
}

const Message: FC<MessageProps> = ({
  content,
  sameAuthor,
  sameDay,
  time,
  urlUser,
  senderId,
  isOpen,
  senderFirstName,
  senderLastName,
  userId,
  openViewMemberSidebar,
  mediaType,
  mediaUrl,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [modalCaption, setModalCaption] = useState('');
  const isReverse = senderId !== userId;

  const handleDownloadImage = async (url) => {
    const name = url.split('/').pop();
    await fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        console.log('blob', blob);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        const bloburl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = bloburl;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error('Failed to download image, please try again!');
        console.log('catch', err);
      });
  };

  const linkifyDecorator = useCallback(
    (decoratedHref, decoratedText, key) => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={decoratedHref}
        key={key}
      >
        {decoratedText}
      </a>
    ),
    [],
  );

  const messageContent = useMemo(
    () =>
      content && (
        <Linkify componentDecorator={linkifyDecorator}>
          <MessageContent>{content}</MessageContent>
        </Linkify>
      ),
    [content, linkifyDecorator],
  );

  const messageTime = useMemo(
    () => <MessageTime reverse={isReverse}>{time}</MessageTime>,
    [isReverse, time],
  );
  const handleImageClick = (src: string, alt: string) => {
    setModalImage(src);
    setModalCaption(alt);
    setIsModalOpen(true);
  };

  const MediaRenderer = useMemo(() => {
    const name = mediaUrl.split('/').pop();
    const type = name.split('.').pop();
    switch (mediaType) {
      case 'image':
        return (
          <ImageContent
            src={mediaUrl}
            alt={name}
            onClick={() => handleImageClick(mediaUrl, name)}
          />
        );
      case 'file':
        return (
          <FileContainer href={mediaUrl} target="_blank" rel="noreferrer">
            <FileIcon width={26} height={26} />
            {name && <FileName>{name.split('_').pop()}</FileName>}
            {type && <FileType>.{type}</FileType>}
          </FileContainer>
        );
    }
  }, [mediaType, mediaUrl]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const mediaContent = useMemo(() => {
    if (mediaUrl) {
      return (
        <Container>
          <IconWrapper onClick={() => handleDownloadImage(mediaUrl)}>
            <IconDownload width="16" height="16" />
            <p>Download</p>
          </IconWrapper>
          {MediaRenderer}
        </Container>
      );
    }
    return null;
  }, [mediaType, mediaUrl]);

  if (sameAuthor && sameDay) {
    return (
      <SameAuthorMessageContent reverse={isReverse} isOpen={isOpen}>
        <MessagePieceWrapper reverse={isReverse} top>
          <MessagePiece />
        </MessagePieceWrapper>
        {/* <AvatarContainer>
          {isEmpty(communityData?.logo) ? (
            <LogoText size="46px">{communityData?.name}</LogoText>
          ) : (
            <img src={communityData?.logo} />
          )}
        </AvatarContainer> */}
        <MessageContainer extraPadding={true} reverse={isReverse}>
          {/* {!!title && (
            <TitleTime>
              <MessageTitle>{title} </MessageTitle>
            </TitleTime>
          )} */}
          {mediaContent}
          {messageContent}
        </MessageContainer>
        {messageTime}
        <ImageModal
          isOpen={isModalOpen}
          imgSrc={modalImage}
          altText={modalCaption}
          onClose={closeModal}
        />
      </SameAuthorMessageContent>
    );
  }

  return (
    <MainContainer reverse={isReverse} isOpen={isOpen}>
      <MessagePieceWrapper reverse={isReverse}>
        <MessagePiece />
      </MessagePieceWrapper>
      <AvatarContainer
        extraHeight={false}
        onClick={() => openViewMemberSidebar(senderId)}
      >
        {isEmpty(urlUser) ? (
          <LogoText size="46px">
            {senderFirstName?.charAt(0) + senderLastName?.charAt(0)}
          </LogoText>
        ) : (
          <img src={urlUser} />
        )}
        {/* {!!title && (
          <CommunityLogo reverse={senderId !== userId} bottom={title}>
            {isEmpty(communityData?.logo) ? (
              <LogoText size="26px" fontSize="12px" lineHeight="14px">
                {communityData?.name}
              </LogoText>
            ) : (
              <img src={communityData?.logo} />
            )}
          </CommunityLogo>
        )} */}
      </AvatarContainer>
      <MessageContainer reverse={isReverse}>
        {/* {!!title && (
          <TitleTime>
            <MessageTitle>{title}</MessageTitle>
          </TitleTime>
        )} */}
        {mediaContent}
        {messageContent}
      </MessageContainer>
      {messageTime}
      <ImageModal
        isOpen={isModalOpen}
        imgSrc={modalImage}
        altText={modalCaption}
        onClose={closeModal}
      />
    </MainContainer>
  );
};

export default React.memo(Message);
