import styled from 'styled-components';
import { ShareIcon } from 'static';

export const StyledInput = styled.input`
  height: 36px;
  width: ${({ width }) => width || '340px'};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.color.bittersweet : theme.color.botticelli};
  border-radius: 4px;
  outline: none;
  padding-left: 14px;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.baliHai};
  padding-right: 100px;
  text-overflow: ellipsis;

  &:focus:enabled {
    border-color: ${({ theme }) => theme.color.brightTurquoise};
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  overflow: auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const defaultButtonStyles = () => `
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
`;

export const CopyButton = styled.button`
  ${defaultButtonStyles};
  margin-right: 13px;
  margin-left: 13px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.brightTurquoise};
  font-weight: 600;
`;

export const ShareButton = styled.div`
  ${defaultButtonStyles};
  margin-top: 3px;
  margin-right: 5px;

  &:hover svg {
    path {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;

export const StyledShareIcon = styled(ShareIcon)``;
