import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import { v4 as uuid } from 'uuid';
import { Redirect } from 'static';
import event from 'static/animations/event.json';
import chat from 'static/animations/chat.json';
import { Divider } from 'components';
import useOutsideClick from 'hooks/outsideClick';
import { CommunityRoles, ModalTypes } from 'types';
import { closeModal, openModal } from 'store/actionCreators/modal';
import { CREATE_GROUP, CREATE_GROUP_CHAT } from 'constants/sidebars';
import Lottie from 'react-lottie';
import CloseButton from 'components/Buttons/CloseButton';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import { useToasts } from 'react-toast-notifications';

import { breakpoints } from 'theme';
import {
  CloseButtonWrapper,
  HeaderIcon,
  StyledAddMemberPopup,
  StyledAddMemberPopupWrapper,
} from './styled';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import { initialFiltersState } from 'sidebars/CreateEventOverall/utils';
import { hasCommunityOrGroupAdminPermissions } from 'utils';

interface CreateEventGroupPopupProps {
  isOpen: boolean;
  createChat?: boolean;
}

const CreateEventGroupPopup: FC<CreateEventGroupPopupProps> = ({
  isOpen,
  createChat,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const isMobileView = useMedia(breakpoints.downSmPlus);
  const popupRef = useRef<HTMLDivElement>();
  const [copiedLink, setCopiedLink] = useState<number | null>(null);
  const { communityId } = useParams();
  const currentUser = useSelector(getCurrentUserInCommunity);
  const { addToast } = useToasts();

  const navigate = useNavigate();
  const location = useLocation();
  const handleClose = (event?: MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    dispatch(closeModal());
  };

  const openCreateEventSidebar = () => {
    if (location.pathname.includes('/event/')) {
      navigate(location.pathname.split('/event/')[0], { replace: true });
    }
    community.overallScheduler &&
      dispatch(
        openModal({
          type: ModalTypes.CREATE_EVENT_OVERALL,
          placement: 'right',
          data: {
            overallScheduler: community.overallScheduler,
            filterFrom: initialFiltersState().from,
            filterTo: initialFiltersState().to,
          },
        }),
      );
  };

  const createGroupLinkNav = () => {
    handleClose();
    navigate(
      `${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.SETTINGS}#members`,
    );
  };

  const onCreateEventHandler = (e) => {
    e.stopPropagation();
    handleClose();
    if (createChat) {
      navigate(`${ROUTES.COMMUNITY}/${communityId}/overall/#members`);
    } else {
      openCreateEventSidebar();
    }
  };

  const showPopUp = () => {
    if (hasCommunityOrGroupAdminPermissions(currentUser.id, community)) {
      return;
    }
    addToast(t('errors.onlyForGroupAndAdmin'), {
      appearance: 'info',
      autoDismiss: true,
    });
  };

  const onCreateGroupHandler = (e) => {
    e.stopPropagation();
    if (hasCommunityOrGroupAdminPermissions(currentUser.id, community)) {
      handleClose();
      dispatch(
        openModal({
          type: createChat ? CREATE_GROUP_CHAT : CREATE_GROUP,
          placement: 'right',
        }),
      );
    } else {
      showPopUp();
    }
  };

  const supportLink = useMemo(() => {
    if (i18n.language === 'de') {
      return 'https://gumb.app/support/terminplanung/';
    }

    return 'https://gumb.app/en/support/create-events/';
  }, [i18n]);

  const openSupportLinkInNewPage = () => {
    window.open(supportLink, '_blank');
  };

  const data = [
    {
      title: createChat
        ? t('community.communityPopup.oneToOne.title')
        : t('community.communityPopup.createEvent.title'),
      subTitle: createChat
        ? t('community.communityPopup.oneToOne.infoText')
        : t('community.communityPopup.createEvent.infoText'),
      btnTitle: createChat
        ? t('community.communityPopup.oneToOne.btntitle')
        : t('community.communityPopup.createEvent.btntitle'),
      linkText: createChat
        ? t('community.communityPopup.oneToOne.linkText')
        : t('community.communityPopup.createEvent.linkText'),
      onClick: onCreateEventHandler,
      navLink: openSupportLinkInNewPage,
    },
    {
      title: createChat
        ? t('community.communityPopup.createChatGroup.title')
        : t('community.communityPopup.createGroup.title'),
      subTitle: createChat
        ? t('community.communityPopup.createChatGroup.infoText')
        : t('community.communityPopup.createGroup.infoText'),
      btnTitle: createChat
        ? t('community.communityPopup.createChatGroup.btntitle')
        : t('community.communityPopup.createGroup.btntitle'),
      linkText: createChat
        ? t('community.communityPopup.createChatGroup.linkText')
        : t('community.communityPopup.createGroup.linkText'),
      onClick: onCreateGroupHandler,
      navLink: createGroupLinkNav,
    },
  ];

  useEffect(() => {
    if (copiedLink !== null) {
      setCopiedLink(null);
    }
  }, [community.invitationCode]);

  useOutsideClick(popupRef, handleClose);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AnimatePresence>
      {isOpen && (
        <StyledAddMemberPopupWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <StyledAddMemberPopup
            ref={popupRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <CloseButtonWrapper>
              <CloseButton hidden={false} onClick={handleClose} />
            </CloseButtonWrapper>
            <HeaderIcon createChat={createChat}>
              {createChat ? (
                <Lottie
                  options={{
                    animationData: chat,
                    loop: true,
                    autoPlay: true,
                  }}
                  width={isMobileView ? '180px' : '42vh'}
                  height={isMobileView ? '180px' : '42vh'}
                />
              ) : (
                <Lottie
                  options={{
                    animationData: event,
                    loop: true,
                    autoPlay: true,
                  }}
                  width={isMobileView ? '180px' : '42vh'}
                  height={isMobileView ? '180px' : '42vh'}
                />
              )}
            </HeaderIcon>
            <StyledAddMemberPopup.Header>
              <h2>
                {createChat
                  ? t('community.communityPopup.chatTitle')
                  : t('community.communityPopup.title')}
              </h2>
              <div>
                <p>
                  {createChat
                    ? t('community.communityPopup.chatSubTitle')
                    : t('community.communityPopup.subTitleOne')}
                </p>
              </div>
            </StyledAddMemberPopup.Header>
            <Divider customMargin="20px 0px 10px 0px" />
            <StyledAddMemberPopup.CardWrapper>
              {data.map((li) => {
                return (
                  <StyledAddMemberPopup.CardWrapper.Card key={uuid()}>
                    <StyledAddMemberPopup.CardWrapper.Card.Header>
                      <StyledAddMemberPopup.CardWrapper.Card.subTitle>
                        {li.subTitle}{' '}
                        {!createChat && (
                          <span onClick={() => li.navLink()}>
                            {li.linkText}
                            <Redirect />
                          </span>
                        )}
                      </StyledAddMemberPopup.CardWrapper.Card.subTitle>
                    </StyledAddMemberPopup.CardWrapper.Card.Header>
                    <StyledAddMemberPopup.CardWrapper.Card.BtnWrapper>
                      <StyledAddMemberPopup.CardWrapper.Card.Btn
                        onClick={li.onClick}
                      >
                        <span>+ </span> {li.btnTitle}
                      </StyledAddMemberPopup.CardWrapper.Card.Btn>
                    </StyledAddMemberPopup.CardWrapper.Card.BtnWrapper>
                  </StyledAddMemberPopup.CardWrapper.Card>
                );
              })}
            </StyledAddMemberPopup.CardWrapper>
          </StyledAddMemberPopup>
        </StyledAddMemberPopupWrapper>
      )}
    </AnimatePresence>
  );
};

export default CreateEventGroupPopup;
