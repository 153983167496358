import styled from 'styled-components';

import { SettingsIcon } from 'static';
import { breakpoints } from 'theme';

export const SettingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const StyledButton = styled.div`
  display: flex;
  width: 26px;
  height: 26px;
  margin-right: 30px;
  border-radius: 99999px;
  &:hover {
    cursor: pointer;
  }
  background-color: #16330014;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #16330021;
    path:nth-child(1) {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }

    path:nth-child(2) {
      stroke: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media ${breakpoints.downMd} {
    margin-right: 5px;
  }
`;

export const StyledLabel = styled.span`
  margin-right: 12px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.baliHai};
`;

export const StyledIcon = styled(SettingsIcon)`
  user-select: none;
  width: 22px;
  height: 22px;
`;
