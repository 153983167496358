import React from 'react';
import { shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Loader } from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { useGeneralGroupSettings } from './hooks';
import { FIELDS } from './form';

import { StyledWrapper, StyledButtonBlock } from './styled';
import { getDateAndTime } from 'utils/dates';

const SettingsGeneral = ({ group, isLoading }) => {
  const { t } = useTranslation();
  const { onSubmit, register, errors } = useGeneralGroupSettings(group);

  return (
    <StyledWrapper>
      <form onSubmit={onSubmit}>
        <TextInput
          register={register}
          name={FIELDS.GROUP_NAME}
          width="100%"
          label={t('group.groupName')}
          error={errors[FIELDS.GROUP_NAME]}
        />
        <TextInput
          register={register}
          defaultValue={getDateAndTime(group?.createdAt)}
          name={'Created At'}
          width="100%"
          disabled
          label="Created At"
        />
        <TextInput
          register={register}
          defaultValue={getDateAndTime(group?.updatedAt)}
          name={'Last Updated At'}
          width="100%"
          disabled
          label="Last Updated At"
        />
        <StyledButtonBlock>
          <Button
            type="submit"
            variant={isLoading ? 'secondary' : null}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader type="button" size="28px" thickness={2} />
            ) : (
              t('common.saveChanges')
            )}
          </Button>
        </StyledButtonBlock>
      </form>
    </StyledWrapper>
  );
};

SettingsGeneral.propTypes = {
  group: shape({
    name: string,
  }).isRequired,
};

export default SettingsGeneral;
