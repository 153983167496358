import { createSelector } from 'reselect';
import { find } from 'lodash/fp';

import { getMyId } from 'store/selectors/auth';
import { getAuthUser } from './auth';
import { IRootState } from '../reducers';
import { CommunityGroup, CommunityRoles } from 'types';
import { CurrentCommunityState } from '../reducers/currentCommunity';

export const selectGroupId = (state: IRootState, groupId: string): string =>
  groupId;
const getGroupById = (items: CommunityGroup[], id: string): CommunityGroup =>
  find({ id }, items);

export const currentCommunitySelector = (
  state: IRootState,
): CurrentCommunityState => state.currentCommunity;
const usersInCommunitySelector = (state: IRootState) =>
  state.currentCommunity.data?.users;

export const getCurrentUserInCommunity = createSelector(
  [usersInCommunitySelector, getAuthUser],
  (users, currentUser) => users?.filter((u) => u.id === currentUser.userId)[0],
);

export const getCurrentCommunity = createSelector(
  currentCommunitySelector,
  (community) => community?.data,
);

export const getUpdateCurrentCommunityLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.updateCommunity,
);

export const getOverallScheduler = createSelector(
  getCurrentCommunity,
  (community) => community.overallScheduler || null,
);

export const getGroups = createSelector(
  getCurrentCommunity,
  (community) => community.groups || null,
);

export const getGroup = createSelector(
  [getGroups, selectGroupId],
  getGroupById,
);

export const getCommunityTotal = createSelector(
  getOverallScheduler,
  (overallScheduler) => overallScheduler?.total || {},
);

export const getCurrentMembers = createSelector(
  getCurrentCommunity,
  (community) => community.users || [],
);

// export const getCurrentMembersChat = createSelector(
//   getCurrentCommunity,
//   (community) => community.chatUsers || [],
// );

export const getCommunityOwner = createSelector(
  getCurrentCommunity,
  (community) => {
    const data = community.users || [];
    return data.filter(
      (user) => user.communityPermission.role === CommunityRoles.OWNER,
    );
  },
);

export const getCommunityAdmins = createSelector(
  getCurrentCommunity,
  (community) => {
    const data = community.users || [];
    return data.filter(
      (user) => user.communityPermission.role === CommunityRoles.ADMIN,
    );
  },
);

export const getCommunityMembers = createSelector(
  getCurrentCommunity,
  (community) => {
    const data = community.users || [];
    return data.filter(
      (user) => user.communityPermission.role === CommunityRoles.MEMBER,
    );
  },
);

export const getCommunityBlockedMembers = createSelector(
  getCurrentCommunity,
  (community) => {
    const data = community.users || [];
    return data.filter(
      (user) => user.communityPermission.role === CommunityRoles.BLOCKED,
    );
  },
);

export const isCurrentUserOwner = createSelector(
  getCommunityOwner,
  getMyId,
  (owners, myId) => {
    const ownerId = owners[0]?.profile.id;
    return ownerId === myId;
  },
);

export const getCurrentCommunityError = createSelector(
  currentCommunitySelector,
  (community) => community.errors.currentCommunity,
);

export const getOverallStatistic = createSelector(
  getCurrentCommunity,
  (community) => community.statistic || null,
);

export const getIsMenuOpen = createSelector(
  currentCommunitySelector,
  (community) => !!community?.isMenuOpen,
);

export const getInfo = createSelector(
  currentCommunitySelector,
  (community) => community.data?.info || null,
);

// LOADERS

export const getCreateGroupLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.createGroup,
);

export const getAddCommunityMember = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.addCommunityMember,
);

export const isChangeUserRoleLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.changeUserRole,
);

export const isDeleteCommunityUserLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.deleteCommunityUser,
);

export const getCurrentCommunityLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.currentCommunity,
);

export const getApproveRequestsLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.approveRequests,
);

export const getRejectRequestsLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.rejectRequests,
);

export const getMembersToGroupsLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.membersByGroups,
);

export const isAddMemberToGroupLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.addMemberToGroup,
);

export const isRemoveMemberFromGroupLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.removeMemberFromGroup,
);

export const getOverallStatisticLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.getStatistic,
);

export const getOverallSchedulerLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.overallScheduler,
);

export const getCommunityUserGroupsLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.communityUserGroups,
);

export const getSendRequestLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.sendRequests,
);

export const getAddMembersToGroupLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.membersByGroups,
);

export const getInviteRequestsLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.getRequests,
);

export const getNewInvitationCodeFetching = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.renewInvitationCode,
);

export const getUserPushTemplates = createSelector(
  currentCommunitySelector,
  (community) => community.data.userPushTemplates || [],
);

export const getUserPushTemplateLoaders = createSelector(
  currentCommunitySelector,
  (community) => ({
    createTemplate: community.loaders.createTemplate,
    getTemplates: community.loaders.getTemplates,
    deleteTemplate: community.loaders.deleteTemplate,
    sendPushNotification: community.loaders.sendPushNotification,
  }),
);

export const getBulkUploadStatus = createSelector(
  currentCommunitySelector,
  (community) => community?.data?.bulkEventUpload,
);

export const getBulkUploadLoader = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.getBulkUploadStatus,
);
