import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ width }) => `${width}`};
  width: ${({ width }) => `${width}`};
  background: ${({ contained, theme }) =>
    contained ? theme.color.lightGradient : theme.color.casper};
  background: ${({ contained, theme }) =>
    contained ? theme.color.brightTurquoise : theme.color.casper};
  border: none;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  outline: none;
  transition: transform 0.3s;
  cursor: pointer;

  &:hover:enabled {
    transform: scale(1.1);
    svg {
      polygon:last-of-type {
        fill: ${({ theme }) => theme.color.white};
      }

      ${({ isMinusIcon }) =>
        isMinusIcon
          ? css`
              g:last-of-type {
                fill: ${({ theme }) => theme.color.white};
              }
            `
          : ''}
    }
  }

  &:active:enabled {
    background: ${({ contained, theme }) =>
      contained && theme.color.darkGradient};
    opacity: 80%;
    cursor: pointer;
  }

  &:disabled {
    opacity: 20%;
  }

  svg {
    width: 50%;

    polygon:last-of-type {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;
