import React, { useCallback, useRef, useState } from 'react';
import { isEmpty } from 'lodash/fp';
import {
  Button,
  Description,
  ImageIcon,
  ImageWrapper,
  InfoWrapper,
  LabelWrapper,
  Layout,
  MenuItem,
  MenuItemWrapper,
  SvgWrapper,
} from '../styled';
import {
  DeleteIcon,
  EditIcon,
  GroupIcon,
  HomePageIcon,
  LeftCloseArrow,
  ThreeDotIcon,
} from 'static';
import { getProfileNameCapital } from 'utils/common';
import { ChatUser } from 'store/reducers/chat';
import { noop } from 'lodash/fp';
import DropdownMenu from 'components/DropdownMenu';
import { openModal } from 'store/actionCreators/modal';
import { CommunityUser, ModalTypes } from 'types';
import { useDispatch } from 'react-redux';
import { deleteChatGroupLoading } from 'store/selectors/chat';
import { useSelector } from 'react-redux';
import { doDeleteChatGroup } from 'store/actionCreators/chat';
import { useToasts } from 'react-toast-notifications';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'configs';
import { COMMUNITY_ROUTES, PROFILE_ROUTES } from 'configs/routes';
import CommunityTitle from 'components/CommunityTitle';

interface HeaderProps {
  isSidebarOpen: boolean;
  openUserList: boolean;
  toggleSidebar: noop;
  selectedPeerUser: ChatUser | null;
  communityId: string;
  member: CommunityUser;
  talkId: string;
  peerId: string;
  isGroup: boolean;
  members: ChatUser[];
  groupName: string;
  description: string;
  logo: string;
  createdAt: string;
  userId: string;
  admin: string;
  openViewMemberSidebar: (userId: string) => void;
}

const Header: React.FC<HeaderProps> = ({
  isSidebarOpen,
  openUserList,
  toggleSidebar,
  selectedPeerUser,
  communityId,
  talkId,
  peerId,
  isGroup,
  member,
  members,
  groupName,
  description,
  createdAt,
  logo,
  userId,
  admin,
  openViewMemberSidebar,
}) => {
  const dispatch = useDispatch();
  const childRef = useRef<{ closeMenu: () => void }>(null);
  const { addToast } = useToasts();
  const [isClickedFirst, setIsClickedFirst] = useState(false);
  const deleteGroupLoading = useSelector(deleteChatGroupLoading);
  const { communityId: communityIdFromParams } = useParams<{
    communityId: string;
  }>();
  const navigate = useNavigate();
  //TODO: get selected chat user
  // const selectedChatUser = useSelector((state: IRootState) =>
  //   getSelectedChatUser(state, talkId),
  // );

  const setIsClickedFirstFalse = useCallback(() => {
    if (isClickedFirst) setIsClickedFirst(false);
  }, [isClickedFirst]);

  const onAboutModal = () => {
    if (childRef?.current?.closeMenu) childRef.current?.closeMenu();
    setIsClickedFirstFalse();
    dispatch(
      openModal({
        type: ModalTypes.ABOUT_CHAT_GROUP,
        placement: 'right',
        data: {
          chat: {
            id: talkId,
            chatName: groupName,
            description: description,
            logo: logo || null,
          },
          createdAt: createdAt,
          members,
          communityId,
        },
      }),
    );
  };

  const onUpdateGroupHandler = () => {
    if (admin !== userId) {
      addToast('admin only can edit this group', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    setIsClickedFirstFalse();
    dispatch(
      openModal({
        type: ModalTypes.UPDATE_GROUP_CHAT,
        placement: 'right',
        data: {
          chat: {
            id: talkId,
            chatName: groupName,
            description: description,
            logo: logo || null,
          },
          members,
          communityId,
          isChatUpdate: true,
        },
      }),
    );
  };

  const onUpdateGroupMember = () => {
    if (admin !== userId) {
      addToast('admin only can edit this group', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    setIsClickedFirstFalse();
    dispatch(
      openModal({
        type: ModalTypes.ADD_GROUP_MEMBERS_CHAT,
        placement: 'right',
        data: {
          communityId,
          isUpdate: true,
          chat: {
            id: talkId,
            chatName: groupName,
            description: description,
          },
          admin,
          members,
        },
      }),
    );
  };

  const onDeleteGroup = () => {
    if (admin !== userId) {
      addToast('admin only can delete this group', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    if (!isClickedFirst) {
      setIsClickedFirst(true);
      return;
    }
    setTimeout(() => {
      setIsClickedFirst(false);
    }, 5000);
    dispatch(
      doDeleteChatGroup.trigger({
        userId,
        chatId: talkId,
        onSuccess: () => {
          if (childRef?.current?.closeMenu) childRef.current?.closeMenu();
          setIsClickedFirst(false);
          addToast('Group deleted successfully', {
            appearance: 'success',
            autoDismiss: true,
          });
          if (communityIdFromParams) {
            navigate(
              `${ROUTES.COMMUNITY}/${communityIdFromParams}/${COMMUNITY_ROUTES.CHAT}`,
            );
          } else {
            navigate(`${ROUTES.PROFILE}/${PROFILE_ROUTES.CHAT}`);
          }
        },
      }),
    );
  };

  const image = logo || selectedPeerUser?.logo || member?.profile?.logo;
  const title = isGroup
    ? groupName.charAt(0).toUpperCase()
    : selectedPeerUser?.firstName.charAt(0).toUpperCase() +
        selectedPeerUser?.lastName.charAt(0).toUpperCase() ||
      member?.profile?.firstName.charAt(0).toUpperCase() +
        member?.profile?.lastName.charAt(0).toUpperCase() ||
      '';
  const name = isGroup
    ? groupName
    : getProfileNameCapital({
        firstName: selectedPeerUser?.firstName || member?.profile?.firstName,
        lastName: selectedPeerUser?.lastName || member?.profile?.lastName,
      }) || 'Loading';
  const descriptionText = description || '';

  const data = [
    {
      name: 'About',
      id: 1,
      onClick: onAboutModal,
      icon: <HomePageIcon width={14} height={14} />,
    },
    {
      name: 'Edit Group',
      id: 2,
      icon: <EditIcon width={14} height={14} />,
      onClick: onUpdateGroupHandler,
    },
    {
      name: 'Edit Group Member',
      id: 3,
      icon: <GroupIcon width={14} height={14} />,
      onClick: () => onUpdateGroupMember(),
    },
    {
      name: deleteGroupLoading
        ? 'Deleting...'
        : isClickedFirst
        ? 'Are you sure?'
        : 'Delete Group',
      id: 4,
      icon: <DeleteIcon width={14} height={14} />,
      onClick: () => onDeleteGroup(),
    },
  ];

  return (
    <Layout.Header isOpen={isSidebarOpen || openUserList}>
      {!openUserList && (
        <Layout.Header.Close onClick={toggleSidebar}>
          <LeftCloseArrow width={20} height={20} />
        </Layout.Header.Close>
      )}
      {!(isEmpty(talkId) && isEmpty(peerId)) && (
        <>
          <InfoWrapper>
            <ImageWrapper
              onClick={() =>
                openViewMemberSidebar(
                  selectedPeerUser?.userId || member?.profile?.userId,
                )
              }
            >
              {image || title ? (
                <CommunityTitle
                  image={image}
                  title={title}
                  isMenuOpen={true}
                  showTitle={false}
                />
              ) : (
                <span className="skeleton skeleton-image" />
              )}
            </ImageWrapper>
            <LabelWrapper>
              <Layout.Header.Name>
                {name !== 'Loading' ? (
                  name
                ) : (
                  <div className="skeleton skeleton-text" />
                )}
              </Layout.Header.Name>
              <Description>
                {!descriptionText && name === 'Loading' ? (
                  <div className="skeleton skeleton-text" />
                ) : (
                  descriptionText
                )}
              </Description>
            </LabelWrapper>
          </InfoWrapper>
          {isGroup && (
            <DropdownMenu
              ref={childRef}
              trigger={
                <Button>
                  <SvgWrapper>
                    <ThreeDotIcon width={18} height={18} />
                  </SvgWrapper>
                  <p>SETTING</p>
                </Button>
              }
              position="bottom"
            >
              <MenuItemWrapper>
                {data.map((data) => {
                  return (
                    <MenuItem
                      key={data.id}
                      onClick={data.onClick}
                      canDelete={4 === data.id}
                      isLoading={deleteGroupLoading}
                    >
                      <ImageIcon>{data.icon}</ImageIcon>
                      <p>{data.name}</p>
                    </MenuItem>
                  );
                })}
              </MenuItemWrapper>
            </DropdownMenu>
          )}
        </>
      )}
    </Layout.Header>
  );
};

export default Header;
