import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Tab, SearchInput, Divider } from 'components';
import { getIsMenuOpen } from 'store/selectors/currentCommunity';
import { SchedulerFilter } from 'types';

import { StyledTabsBlock, StyledTabsList } from './styled';
import { ROUTES } from 'configs';
import PrefrencePopup from 'components/Popups/prefrencePopup/PrefrencePopup';
import { useLocalStorage } from 'react-use';

interface TabsProps {
  children: JSX.Element[];
  width?: string;
  filterValue?: string;
  invitesCount?: number;
  tabsRef?: React.MutableRefObject<HTMLDivElement>;
  noApply?: boolean;
  filters?: SchedulerFilter;
  setFilters?: (values: SchedulerFilter) => void;
  setFilterValue?: (value: string) => void;
  applyFilters?: () => void;
  resetFilters?: () => void;
  initialFiltersState?: SchedulerFilter;
  isSidebar?: boolean;
}

const Tabs: FC<TabsProps> = ({
  width,
  children,
  filterValue,
  invitesCount,
  tabsRef = () => {},
  noApply,
  filters,
  setFilters,
  setFilterValue = () => {},
  applyFilters = () => {},
  resetFilters = () => {},
  initialFiltersState,
  isSidebar,
}) => {
  const { groupId } = useParams();
  const isMenuOpen = useSelector(getIsMenuOpen);
  const { communityId } = useParams();
  const navigate = useNavigate();
  const availableChildren = children.filter((c) => !!c);
  const [activeTab, setActiveTab] = useState(0);
  const [preferenceFeatureVisible, setPreferenceFeatureVisible] =
    useState<boolean>(false);
  const [withSearch, setWithSearch] = useState(
    availableChildren[0].props.withSearch,
  );
  const [withFilter, setWithFilter] = useState(
    !!availableChildren[0].props.withFilter,
  );
  const [defaultFeaturePopup, setDefaultFeaturePopup] =
    useLocalStorage<boolean>('defaultEventPopUp', false);
  useEffect(
    () =>
      availableChildren.filter(
        (tab) => tab.props.label === availableChildren[activeTab].props.label,
      )[0].props.withSearch
        ? setWithSearch(true)
        : setWithSearch(false),
    [activeTab, availableChildren],
  );

  useEffect(
    () =>
      availableChildren.filter(
        (tab) => tab.props.label === availableChildren[activeTab].props.label,
      )[0].props.withFilter
        ? setWithFilter(true)
        : setWithFilter(false),
    [activeTab, availableChildren],
  );

  useEffect(() => {
    if (
      window.location.hash === '#update-email' ||
      window.location.hash === '#notifications'
    ) {
      onClickTab(1);
    } else if (window.location.hash === '#members') {
      setActiveTab(5);
    } else if (window.location.hash === '#requests') {
      setActiveTab(6);
    } else if (window.location.hash === '#event-types') {
      setActiveTab(2);
    } else if (window.location.hash === '#scheduler') {
      setActiveTab(0);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (
      window.location.hash === '#statistics' ||
      window.location.hash === '#memberPresence'
    ) {
      setActiveTab(groupId ? 4 : 4);
    }
    if (
      window.location.pathname.includes('overall') &&
      window.location.hash === ''
    ) {
      setActiveTab(0);
    }
    if (
      window.location.pathname.includes('overall') &&
      window.location.hash === '#members'
    ) {
      setActiveTab(2);
    }
    if (window.location.hash === '#import') {
      if (groupId) {
        navigate(`${ROUTES.COMMUNITY}/${communityId}/overall/#import`, {
          replace: true,
        });
      }
      setActiveTab(1);
    }
  }, [window.location.hash]);

  const onClickTab = (tab: number) => {
    if (tab === 2) {
      if (!defaultFeaturePopup) {
        setDefaultFeaturePopup(true);
        setTimeout(() => setPreferenceFeatureVisible(true), 1000);
      }
    }
    setActiveTab(tab);
  };

  const handleFilterChange = (value: string) => setFilterValue(value);

  const renderSearch = (search, filter) => {
    if (search) {
      if (filter) {
        return (
          <SearchInput
            value={filterValue}
            onChange={({ target }) => handleFilterChange(target.value)}
            withFilter
            noApply={noApply}
            filters={filters}
            setFilters={setFilters}
            applyFilters={applyFilters}
            resetFilters={resetFilters}
            initialFiltersState={initialFiltersState}
          />
        );
      }

      return (
        <SearchInput
          value={filterValue}
          filters={filters}
          noApply={noApply}
          setFilters={setFilters}
          onChange={({ target }) => handleFilterChange(target.value)}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
        />
      );
    }
    return null;
  };

  return (
    <>
      <StyledTabsBlock
        isMenuOpen={isMenuOpen}
        ref={tabsRef}
        isSidebar={isSidebar}
      >
        <PrefrencePopup
          isOpen={preferenceFeatureVisible}
          onClose={() => setPreferenceFeatureVisible(false)}
        />
        <StyledTabsList
          length={availableChildren.length}
          width={width}
          isSidebar={isSidebar}
        >
          {availableChildren.map((child) => {
            const { label, iconLabel, grow, tabId, index } = child.props;
            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                iconLabel={iconLabel}
                grow={grow}
                invitesCount={invitesCount}
                tabId={tabId}
                index={index}
                onClickTab={onClickTab}
                isSidebar={isSidebar}
              />
            );
          })}
        </StyledTabsList>
        {renderSearch(withSearch, withFilter)}
      </StyledTabsBlock>
      <Divider customMargin="0px 0px 16px 0px" />
      <div>
        {availableChildren.map((child) => {
          if (child.props.label !== availableChildren[activeTab].props.label)
            return undefined;
          return child.props.children;
        })}
      </div>
    </>
  );
};

export default Tabs;
