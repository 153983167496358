import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Sidebar,
  SidebarHeader,
  SidebarContent,
  ColumnInfoBlock,
  Loader,
} from 'components';

import { closeModal } from 'store/actionCreators/modal';

import {
  ListWrapper,
  Wrapper,
  ImageWrapper,
  SkeletonListWrapper,
} from './styled';
import { Title } from 'pages/UserProfile/Content/styled';
import ParticipantsTab from 'sidebars/EventDetails/Participants';
import { useSelector } from 'react-redux';
import {
  getCurrentCommunity,
  getCurrentCommunityLoading,
} from 'store/selectors/currentCommunity';
import { Chat, ChatUser } from 'store/reducers/chat';
import { getData } from 'store/selectors/modal';
import ImageBox from 'components/Inputs/ImageInput/ImageBox';
import { useParams } from 'react-router-dom';
import { doRequestCommunityForChat } from 'store/actionCreators/currentCommunity';
import { doClearCommunity } from 'store/actionCreators/currentCommunity';

const AboutChatGroup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { communityId: paramsCommunityId } = useParams<{
    communityId: string;
  }>();
  const community = useSelector(getCurrentCommunity);
  const currentCommunityLoading = useSelector(getCurrentCommunityLoading);
  const { chat, members, communityId, createdAt } = useSelector(getData) as {
    chat: Chat;
    members: ChatUser[];
    communityId: string;
    createdAt: string;
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    if (!paramsCommunityId) {
      dispatch(doRequestCommunityForChat({ communityId }));
    }
    return () => {
      if (!paramsCommunityId) {
        dispatch(doClearCommunity());
      }
    };
  }, [communityId]);

  const groupUsers = useMemo(() => {
    if (!community?.users || !members?.length) return [];

    const memberIds = new Set(members.map((m) => m.userId));

    return community.users
      .filter((user) => memberIds.has(user.profile.userId))
      .map((user) => ({
        ...user.profile,
        user,
      }));
  }, [community, members]);

  return (
    <Sidebar>
      <SidebarHeader label={'About Group'} onCloseClick={handleClose} />
      <SidebarContent fullHeight>
        <Wrapper>
          <Title>
            <p>{t('profile.basicInformation')}</p>
          </Title>
          <ImageWrapper>
            <ImageBox src={chat.logo} image={chat.logo} />
          </ImageWrapper>
          <ColumnInfoBlock
            label={'Chat group name'}
            text={chat.chatName}
            name={chat.chatName}
            preventEdit
          />
          <ColumnInfoBlock
            label={'Description'}
            text={chat.description}
            name={chat.description}
            preventEdit
            isDescription
          />
          {/* <ColumnInfoBlock
            label={'Created At'}
            text={createdAt}
            name={createdAt}
            preventEdit
          /> */}
        </Wrapper>
        <Wrapper>
          <Title>
            <p>Members</p>
          </Title>
        </Wrapper>
        <ListWrapper>
          {currentCommunityLoading ? (
            <SkeletonListWrapper>
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className="skeleton skeleton-list" />
              ))}
            </SkeletonListWrapper>
          ) : (
            <ParticipantsTab users={groupUsers} height="auto" />
          )}
        </ListWrapper>
      </SidebarContent>
    </Sidebar>
  );
};

export default AboutChatGroup;
