import styled from 'styled-components';

export const DropDownRoot = styled.div`
  position: relative;
  display: inline-block;
`;
export const DropDownTrigger = styled.div`
  cursor: pointer;
  & > button {
    background-color: ${({ isOpen }) => (isOpen ? '#16330021' : '#16330014')};
    & > div {
      & > svg {
        fill: ${({ theme, isOpen }) =>
          isOpen ? theme.color.pickledBluewood : theme.color.baliHai};
        polygon:last-of-type {
          fill: ${({ theme, isOpen }) =>
            isOpen ? theme.color.pickledBluewood : theme.color.baliHai};
        }
      }
    }
    p {
      color: ${({ theme, isOpen }) =>
        isOpen ? theme.color.pickledBluewood : theme.color.baliHai};
    }
  }
`;

export const MenuContainer = styled.div`
  cursor: pointer;
  width: 18rem;
  border-radius: 4px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  background-color: #ffffff;
  z-index: 50;
`;

export const MenuWrapper = styled.div`
  cursor: pointer;
  padding: 8px;
`;
