import React, { useCallback, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useLocalStorage } from 'react-use';
import i18n from 'i18next';
import { Page404, Page500 } from 'pages';
import { COMMUNITY_ROUTES, PROFILE_ROUTES, ROUTES } from 'configs/routes';
import {
  getAuthAuthenticated,
  getAuthLoading,
  getAuthUser,
} from 'store/selectors/auth';
import { doCheckIsAuthorized } from 'store/actionCreators/auth';
import { setHeaders } from 'services';
import { Loader } from 'components';
import AppRouter from './AppRouter';
import { default as AuthRouter } from 'pages/Auth';
import { logInGoogle, logInFacebook } from 'utils/auth';
import { getProfileName } from '../utils';
import { FullUser, IN_APP_NOTIFICATION_TYPE } from '../types';
// import { getClient } from 'store/selectors/chat';
import { useToasts } from 'react-toast-notifications';
// import { doAddNotification } from 'store/actionCreators/profile';
// import { getMessage } from 'utils/common';
// import { getNotifications } from 'store/selectors/auth';
import { doGetChatUsers } from 'store/actionCreators/chat';
import { getChatNotification } from 'store/selectors/chat';
import { ChatUser } from 'store/reducers/chat';

const Router = () => {
  // const [filteredChannels, setFilteredChannels] = useState([]);
  const dispatch = useDispatch();
  const { addToast, removeAllToasts } = useToasts();
  const user = useSelector(getAuthUser);
  // const notifications = useSelector(getNotifications) || [];
  // const chatClient = useSelector(getClient);
  const notificationUser = useSelector(getChatNotification);
  const isAuthenticated = useSelector(getAuthAuthenticated);
  const isLoading = useSelector(getAuthLoading);
  const [prevAuth, setPrevAuth] = useLocalStorage<{ [key: string]: string }>(
    'socialSignIns',
    {},
  );

  const onAuthSuccess = (user: FullUser, session) => {
    if (user?.profile && session?.idToken?.payload?.identities) {
      const profileName = getProfileName(user.profile);
      const newIdentities: { [key: string]: string } = {};

      session.idToken.payload.identities.forEach((i) => {
        if (i.providerName === 'Google') {
          newIdentities.google = profileName;
        } else if (i.providerName === 'Facebook') {
          newIdentities.facebook = profileName;
        } else if (i.providerName === 'SignInWithApple') {
          newIdentities.apple = profileName;
        }
      });

      setPrevAuth({ ...prevAuth, ...newIdentities });
    }
  };

  // const sendNotification = useCallback((notification) => {
  //   addToast(notification, {
  //     appearance: 'success',
  //     autoDismiss: true,
  //     autoDismissTimeout: 10000,
  //   });
  // }, []);

  //todo
  // const sendNotificationSticky = useCallback((notification) => {
  //   addToast(notification, {
  //     appearance: 'success',
  //     autoDismiss: false,
  //   });
  // }, []);

  // useEffect(() => {
  //   if (user) {
  //     dispatch(
  //       doGetChatUsers({
  //         userId: user.userId,
  //         type: true,
  //         forNotification: true,
  //       }),
  //     );
  //   }
  // }, [dispatch, user]);

  //todo
  // const createAndDispatchNotification = async (
  //   event: ChatUser,
  //   dispatch,
  //   i18n,
  // ) => {
  //   try {
  //     const { peerUserId, profile, latestMessage, communityId } = event;
  //     const { userId, firstName, lastName, logo } = profile;

  //     const link = communityId
  //       ? `${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.CHAT}/${peerUserId}`
  //       : `${ROUTES.PROFILE}/${PROFILE_ROUTES.CHAT}/${peerUserId}`;

  //     const notification = {
  //       id: peerUserId,
  //       userId,
  //       message: `💬  •  ${firstName} ${lastName} \n <span>${latestMessage.replaceAll(
  //         '\n',
  //         ' ',
  //       )}</span>`,
  //       image: logo,
  //       // link: `${window?.location.origin}${ROUTES.PROFILE}/${ROUTES.MESSAGE}/${peerUserId}`,
  //       link,
  //       type: IN_APP_NOTIFICATION_TYPE.MESSAGE,
  //       isRead: false,
  //     };

  //     // Dispatch the notification action
  //     // await dispatch(doAddNotification(notification));

  //     // Send the notification
  //     sendNotificationSticky(notification);
  //   } catch (error) {
  //     console.error('Failed to create and dispatch notification:', error);
  //     // Optional: Add fallback action here, e.g., log to a monitoring service
  //   }
  // };

  //todo
  // const processNotifications = useCallback(
  //   async (notifications: ChatUser[]) => {
  //     try {
  //       await Promise.all(
  //         notifications.map((event) =>
  //           createAndDispatchNotification(event, dispatch, i18n),
  //         ),
  //       );
  //     } catch (error) {
  //       console.error('Error processing notifications:', error);
  //     }
  //   },
  //   [dispatch, i18n],
  // );

  //todo
  // useEffect(() => {
  //   if (notificationUser) {
  //     processNotifications(notificationUser);
  //   }
  // }, [notificationUser, processNotifications]);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const error = search.get('error_description');

    if (
      error &&
      /already.found.an.entry.for.username.google/gi.test(error.toString())
    ) {
      logInGoogle();
    } else if (
      error &&
      /already.found.an.entry.for.username.facebook/gi.test(error.toString())
    ) {
      logInFacebook();
    } else if (
      !window.location.pathname.includes('change-email') &&
      !window.location.pathname.includes('verify')
    ) {
      dispatch(doCheckIsAuthorized({ onSuccess: onAuthSuccess }));
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(refreshSession, 1000 * 60 * 20);

    return () => clearInterval(interval);
  }, []);

  const refreshSession = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const cognitoSession = await Auth.currentSession();

      cognitoUser.refreshSession(
        cognitoSession['refreshToken'],
        (err, session) => {
          if (session) {
            const { idToken } = session;

            setHeaders(idToken.jwtToken);
          }
        },
      );
    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  };

  return !isLoading ? (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.PAGE404} element={<Page404 />} />
        <Route path={ROUTES.PAGE500} element={<Page500 />} />
        {isAuthenticated &&
        !window.location.pathname.includes('change-email') &&
        !window.location.pathname.includes('verify') ? (
          <Route path="*" element={<AppRouter />} />
        ) : (
          <Route path="*" element={<AuthRouter />} />
        )}
      </Routes>
    </BrowserRouter>
  ) : (
    <Loader />
  );
};

export default Router;
