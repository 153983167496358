import styled, { css } from 'styled-components';

export const TextInputBlock = styled.div``;

export const CommunitySelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BackBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 26px;
`;
