import styled from 'styled-components';

export const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5%;
`;

export const ImageWrapper = styled.div`
  margin-top: -50%;
`;

export const Text = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.color.brightTurquoise};
  :hover {
    text-decoration: underline;
  }
`;
