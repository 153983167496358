import React, { CSSProperties, FC, useMemo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import {
  BoxWrapper,
  ChatBlockWrapper,
  SkeletonBoxWrapper,
  TextWrapper,
  UserBlock,
} from './styled';

import CommunityTitle from 'components/CommunityTitle';
import { getIsMenuOpen } from 'store/selectors/currentCommunity';
import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import { Chat } from 'store/reducers/chat';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { UnrespondedEventsCircle } from 'components/ProfileMenu/styled';

interface CommunityChatsListProps {
  iconStyles?: CSSProperties;
  commonMember: Chat[];
  chatUserLoading: boolean;
  communityId: string;
  listMaxHeight: number;
  listRef: React.MutableRefObject<any>;
  onClick?: (data?: string) => void;
}

const CommunityChatsList: FC<CommunityChatsListProps> = ({
  iconStyles,
  commonMember,
  chatUserLoading,
  communityId,
  listRef,
  listMaxHeight,
  onClick,
}) => {
  const { t } = useTranslation();
  const isMenuOpen = useSelector(getIsMenuOpen);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const talkId = queryParams.get('talkId');
  const peerId = queryParams.get('peerId');

  const membersWithPlaceholders = useMemo(
    () => [
      ...commonMember,
      ...Array(Math.max(0, 3 - commonMember.length)).fill(null),
    ],
    [commonMember],
  );

  const handleBoxWrapperClick = useCallback(() => {
    if (onClick) {
      onClick('chat');
    }
  }, [onClick]);

  return (
    <ChatBlockWrapper
      listMaxHeight={listMaxHeight}
      isShow={!isEmpty(membersWithPlaceholders) || chatUserLoading}
      ref={listRef}
    >
      {chatUserLoading ? (
        <SkeletonBoxWrapper>
          <div className="skeleton skeleton-box">
            <Loader size="40px" type="button" />
          </div>
        </SkeletonBoxWrapper>
      ) : (
        <>
          {membersWithPlaceholders.map((user: Chat, index) =>
            user ? (
              <UserBlock
                to={`${ROUTES.COMMUNITY}/${communityId}/${
                  COMMUNITY_ROUTES.CHAT
                }?talkId=${user?.chatId || user?.id}`}
                key={user?.chatId || user?.id}
                selected={
                  talkId === user?.chatId ||
                  talkId === user?.id ||
                  peerId === user?.id ||
                  peerId === user?.chatId
                }
                isUnseen={user?.chatUnreadCount > 0}
              >
                <UserBlock.ImageWrapper>
                  <CommunityTitle
                    title={
                      user.isGroupChat
                        ? user?.chatName.charAt(0).toUpperCase() || ''
                        : user?.peerUsers?.firstName.charAt(0).toUpperCase() +
                            user?.peerUsers?.lastName.charAt(0).toUpperCase() ||
                          ''
                    }
                    image={user?.logo || user?.peerUsers?.logo || ''}
                    showTitle={false}
                    isMenuOpen={isMenuOpen}
                    frame
                  />
                </UserBlock.ImageWrapper>
                <UserBlock.Name>
                  {user.isGroupChat
                    ? user?.chatName
                    : user?.peerUsers?.firstName || ''}
                </UserBlock.Name>
                <UserBlock.Name>
                  {user?.peerUsers?.lastName || ''}
                </UserBlock.Name>
                {user?.chatUnreadCount > 0 && (
                  <UnrespondedEventsCircle small positionRight="16px">
                    <span>{user?.chatUnreadCount}</span>
                  </UnrespondedEventsCircle>
                )}
              </UserBlock>
            ) : index === commonMember.length ? (
              <BoxWrapper onClick={handleBoxWrapperClick} key="box-wrapper">
                <span className="plus-icon">+</span>
                <TextWrapper>
                  <UserBlock.Name className="plus-title">
                    {t('chat.new')}
                  </UserBlock.Name>
                  <UserBlock.Name className="plus-title">
                    {t('chat.chat')}
                  </UserBlock.Name>
                </TextWrapper>
              </BoxWrapper>
            ) : null,
          )}
        </>
      )}
    </ChatBlockWrapper>
  );
};

export default React.memo(CommunityChatsList);
