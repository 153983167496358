import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledContentWrapper = styled.div`
  width: calc(100% - ${({ isMenuOpen = true }) => (isMenuOpen ? 360 : 60)}px);
  background-color: white !important;
  height: 100svh;
  overflow: hidden;
  margin-left: ${({ isMenuOpen }) => (isMenuOpen ? 360 : 60)}px;
  transition: all 0.3s;
  @media ${breakpoints.downMd} {
    width: 100%;
    margin-left: 0;
  }
`;
export const TabWrapper = styled.div`
  overflow-y: auto;
  height: calc(100svh - 155px);
  @media ${breakpoints.downMd} {
    height: calc(100svh - 144px);
  }
  @media ${breakpoints.downSmPlus} {
    height: calc(100svh - 110px);
  }
`;
export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px 16px 24px;

  @media ${breakpoints.downMd} {
    padding: 10px 32px 10px 24px;
    & > button {
      margin-right: 30px;
    }
  }
`;

export const StyledTitle = styled.h1`
  line-height: 19px;
  font-size: 16px;
  font-weight: 700;
  text-align: justify;
  color: ${({ theme }) => theme.color.pickledBluewood};
  margin-left: ${({ isMenuOpen }) => (isMenuOpen ? 0 : 55)}px;
  transition: margin-left 0.3s;

  @media ${breakpoints.downMd} {
    margin-left: 0;
  }
`;

export const TabListContainer = styled.div`
  width: 100%;
  padding: 20px 20px 0px 20px;
  margin: auto;
  height: calc(100vh - 186px);
  overflow: auto;
  max-width: 900px;
  @media ${breakpoints.downMd} {
    height: calc(100vh - 174px);
  }
  @media ${breakpoints.downSmPlus} {
    padding: 20px 10px 0px 10px;
    height: calc(100vh - 139px);
  }
`;
