import React, { FC } from 'react';

import { StyledButton, StyledIcon, StyledIconContainer } from './styled';

interface CloseButtonProps {
  hidden?: boolean;
  onClick: () => void;
  width?: string;
  height?: string;
  style?: { [key: string]: string | number };
}

const CloseButton: FC<CloseButtonProps> = ({
  hidden,
  onClick,
  height,
  width,
  style,
}) => (
  <StyledButton
    className="close-btn"
    hidden={hidden}
    type="button"
    onClick={onClick}
    style={style}
  >
    <StyledIconContainer>
      <StyledIcon width={width} height={height} />
    </StyledIconContainer>
  </StyledButton>
);

export default CloseButton;
