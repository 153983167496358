import React, { FC, useCallback, useMemo } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import yellowStar from 'static/animations/yellowStar.json';
import Lottie from 'react-lottie';
import Dot from '../../components/Grid/icons/Oval.png';
import Team from '../../components/Grid/icons/Team.png';
import { getZoomedValue } from 'utils';
import { Tooltip } from 'components';
import { EventReply, EventResponse, MyAppointmentsEvent } from 'types';
import { CommentIcon, NoResponse } from 'static';
import { getProfilesListInnerData } from './utils';
import { ReplyFormData } from './form';
import { CommunityRoles, GroupRoles } from 'types';

import {
  EventCard,
  ProfilesList,
  RepliesCount,
  RepliesCountResponse,
} from './styled';

interface ReplyBlockProps {
  zoom: number;
  eventResponses: { [p: string]: EventResponse[] };
  event: MyAppointmentsEvent;
  replyFieldProps: ControllerRenderProps<ReplyFormData>;
  usersEventReply: {
    reply: EventReply & { tasks: string[] };
    communityResponse: EventResponse;
  };
  responsesVisible: boolean;
  setResponsesVisible: (value: boolean) => void;
  getValues: () => ReplyFormData;
}

const ReplyBlock: FC<ReplyBlockProps> = ({
  zoom,
  eventResponses,
  event,
  replyFieldProps,
  usersEventReply,
  responsesVisible,
  setResponsesVisible,
  getValues,
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const toggleReplies = (forceShowDeadlineToast?: boolean) => {
    setResponsesVisible(!responsesVisible);

    const conditionOne = event.isAfterRegDeadline && !!forceShowDeadlineToast;
    const conditionTwo =
      (event.isDisabled || event.isAfterRegDeadline) && !responsesVisible;
    if (conditionOne || conditionTwo) {
      addToast(t('event.deadlineExpired'), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const getFilteredReplies = useCallback(
    (response: EventResponse): EventReply[] => {
      return event.subEventReplies.filter(
        (r) => r.eventResponseId === response?.id,
      );
    },
    [event],
  );

  const overrideReplyPermission: boolean = useMemo(() => {
    //conditions to get community Permission:
    //if event?.communityPermission === undefined. use event.isDisabled

    if (
      (event?.communityPermission &&
        [CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(
          event.communityPermission.role,
        )) ||
      !event.isDisabled
    )
      return true;
    else if (
      event?.groupPermission &&
      event.groupPermission.role === GroupRoles.ADMIN
    )
      return true;
    else return false;
  }, [event]);

  return (
    <Tooltip
      text={
        <EventCard.ResponsesBlock zoom={zoom}>
          <div>
            {eventResponses[
              event.community?.id || event.group?.community?.id
            ]?.map((response) => (
              <EventCard.ResponseButton
                key={response.id + 'responses'}
                zoom={zoom}
              >
                <EventCard.ResponseRadio
                  color={response.color}
                  type="radio"
                  id={response.id + event.id}
                  value={response.id}
                  checked={replyFieldProps.value === response.id}
                  zoom={zoom}
                  disabled={event.isDisabled}
                  onClick={() => {
                    if (replyFieldProps.value === response.id) {
                      replyFieldProps.onChange(null);
                    } else {
                      replyFieldProps.onChange(response.id);
                    }
                    // setResponsesVisible(false);
                    toggleReplies(true);
                  }}
                />
                <EventCard.ResponseLabel
                  htmlFor={response.id + event.id}
                  zoom={zoom}
                  disabled={event.isDisabled}
                  color={response.color}
                >
                  {response.title}
                  {response.isCommentRequired && (
                    <CommentIcon width={18} height={18} />
                  )}
                </EventCard.ResponseLabel>
                {getFilteredReplies(response).length > 0 && (
                  <Tooltip
                    text={
                      <ProfilesList zoom={zoom}>
                        {getProfilesListInnerData(
                          getFilteredReplies(response),
                          zoom,
                          'reply',
                        )}
                      </ProfilesList>
                    }
                    zoom={zoom}
                    isVisible
                    offset={5 * zoom}
                    position="top-center"
                    noArrow
                    opacity="1"
                    tooltipStyle={{
                      backgroundColor: '#FFF',
                      boxShadow: '0px 0px 4px 0px #6a6a6a7d',
                      width:
                        (getFilteredReplies(response).length > 1 ? 270 : 135) *
                          zoom +
                        'px',
                    }}
                  >
                    <RepliesCount zoom={zoom}>
                      <span>{getFilteredReplies(response).length}</span>
                    </RepliesCount>
                  </Tooltip>
                )}
              </EventCard.ResponseButton>
            ))}
          </div>
        </EventCard.ResponsesBlock>
      }
      zoom={zoom}
      isVisible={responsesVisible}
      isHover={false}
      tooltipStyle={{
        backgroundColor: '#FFF',
        width: Math.floor(268 * zoom) + 'px',
        zIndex: 1000,
      }}
      noArrow
      opacity="1"
      offset={1}
      position="bottom-center"
      auto={false}
    >
      <EventCard.Reply
        color={
          eventResponses[
            event.community?.id || event.group?.community?.id
          ]?.find((r) => r.id === replyFieldProps.value)?.color || '#8298ab'
        }
        zoom={zoom}
        disabled={event.isDisabled}
        onClick={() => {
          toggleReplies();
        }}
      >
        {usersEventReply?.reply?.penalty && (
          <img style={{ transform: `scale(${zoom})` }} src={Dot} alt="dot" />
        )}
        {!usersEventReply?.reply?.eventResponseId &&
          !getValues()?.eventResponseId && (
            <div>
              <NoResponse />
            </div>
          )}
        <RepliesCountResponse zoom={zoom}>
          {getFilteredReplies(
            eventResponses[
              event.community?.id || event.group?.community?.id
            ]?.find((r) => r.id === replyFieldProps.value),
          ).length > 0 && (
            <Tooltip
              text={
                <ProfilesList zoom={zoom}>
                  {getProfilesListInnerData(
                    getFilteredReplies(
                      eventResponses[
                        event.community?.id || event.group?.community?.id
                      ]?.find((r) => r.id === replyFieldProps.value),
                    ),
                    zoom,
                    'reply',
                  )}
                </ProfilesList>
              }
              zoom={zoom}
              isVisible
              offset={5 * zoom}
              position="top-center"
              noArrow
              opacity="1"
              tooltipStyle={{
                backgroundColor: '#FFF',
                boxShadow: '0px 0px 4px 0px #6a6a6a7d',
                width:
                  (getFilteredReplies(
                    eventResponses[
                      event.community?.id || event.group?.community?.id
                    ]?.find((r) => r.id === replyFieldProps.value),
                  ).length > 1
                    ? 270
                    : 135) *
                    zoom +
                  'px',
              }}
            >
              <span>
                <img className="gumb-grid-cell-icon" src={Team} alt="team" />
                <span>
                  {
                    getFilteredReplies(
                      eventResponses[
                        event.community?.id || event.group?.community?.id
                      ]?.find((r) => r.id === replyFieldProps.value),
                    ).length
                  }
                </span>
              </span>
            </Tooltip>
          )}
        </RepliesCountResponse>

        <div
          style={{
            width: `${getZoomedValue(20, zoom, 'px')}`,
            height: `${getZoomedValue(20, zoom, 'px')}`,
            position: 'relative',
          }}
        >
          {usersEventReply?.reply?.attended && (
            <Lottie
              options={{
                animationData: yellowStar,
                autoplay: true,
                loop: true,
              }}
              width={getZoomedValue(40, zoom, 'px')}
              height={getZoomedValue(40, zoom, 'px')}
              style={{
                position: 'absolute',
                top: `${getZoomedValue(-10, zoom, 'px')}`,
                transform: 'translateX(-30%)',
                zIndex: '132213',
              }}
            />
          )}
        </div>
      </EventCard.Reply>
    </Tooltip>
  );
};

export default ReplyBlock;
