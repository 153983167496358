const IMAGE_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp',
  'image/svg+xml',
  'image/tiff',
];

export function isImageFile(file: File | null) {
  return IMAGE_TYPES.includes(file.type);
}
