import styled from 'styled-components';
export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100000000000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100svh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
`;

export const ModalImage = styled.img`
  max-width: 90%;
  max-height: 100svh;
  margin: auto;
  display: block;
  animation: zoom 0.6s;

  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
`;

export const CloseButtonWrapper = styled.span`
  position: absolute;
  top: 15px;
  z-index: 500;
  right: 35px;
`;

export const Caption = styled.div`
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  font-size: 16px;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 22px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding: 12px 22px;
`;
