import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { noop } from 'lodash/fp';
import {
  ButtonWrapper,
  CloseIcon,
  CreateChatWrapper,
  Images,
  Label,
  PushMessage,
} from './styled';
import TextInputEmoji from 'components/Inputs/TextInput/TextInputEmoji';
import {
  Button,
  CloseButton,
  DocumentationPopup,
  EmojiArea,
  FileInputControlled,
  Loader,
  MenuList,
  Tooltip,
} from 'components';
import { Document } from 'types';
import { useTranslation } from 'react-i18next';
import {
  Control,
  ControllerRenderProps,
  DeepMap,
  FieldError,
  UseFormMethods,
} from 'react-hook-form';
import {
  DeviceIcon,
  DocumentationIcon,
  GreenPlusIcon,
  LeftCloseArrow,
} from 'static';
import { Layout } from '../ChatUserList/styled';
import DropdownMenu from 'components/DropdownMenu';
import { ImageIcon, MenuItem, MenuItemWrapper } from '../Chat/styled';
import { HiddenInput } from 'components/Inputs/ImageInput/styled';

import { useInfoPopup } from 'hooks';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { useSelector } from 'react-redux';
import { ChatFormValues, FORMFIELDS } from '../Chat/form';

interface ChatInputProps
  extends Partial<Pick<UseFormMethods<ChatFormValues>, 'register'>> {
  errors: DeepMap<ChatFormValues, FieldError>;
  control: Control<ChatFormValues>;
  onSubmit: () => void;
  isOpen: boolean;
  sendMessageLoader: boolean;
  isActiveSend: boolean;
  onDocumentsAttach: (documents: Document[]) => void;
  communityId: string;
  attachmentsFieldProps: ControllerRenderProps<ChatFormValues>;
  clearErrors: (name?: string | string[]) => void;
}

const AVAILABLE_SIZE = 20 * 1024;

const ChatInput: FC<ChatInputProps> = ({
  errors,
  register,
  control,
  onSubmit,
  isOpen,
  sendMessageLoader,
  isActiveSend,
  onDocumentsAttach,
  attachmentsFieldProps,
  communityId,
  clearErrors,
}) => {
  const community = useSelector(getCurrentCommunity);
  const [documentsPopupOpen, setDocumentsPopupOpen] = useState<boolean>(false);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

  // const childRef = useRef<{ closeMenu: () => void }>(null);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();
  const [fileSizeTitle, fileSizeMessage] = t('errors.fileSizeCustom', {
    size: 20,
  }).split(':');
  const { showInfoPopup } = useInfoPopup();
  const validImageSize = (imageFile: File) =>
    Math.round(imageFile.size / 1024) < AVAILABLE_SIZE;

  // const dropdownData = useMemo(
  // () =>
  // [
  // TODO Remove
  // communityId
  //   ? {
  //       name: 'Document from your community',
  //       id: 1,
  //       onClick: () => setDocumentsPopupOpen(true),
  //       icon: <DocumentationIcon width={14} height={14} />,
  //     }
  //   : null,
  //       {
  //         name: 'Upload from your device',
  //         id: 2,
  //         onClick: () => {},
  //         icon: <DeviceIcon width={14} height={14} />,
  //       },
  //     ].filter(Boolean),
  //   [t],
  // );

  const handleChange = (e) => {
    const imageFile = e.target.files[0];
    inputRef.current.value = null;
    if (!imageFile) return false;
    clearErrors();
    if (!validImageSize(imageFile)) {
      showInfoPopup({
        title: fileSizeTitle,
        message: fileSizeMessage,
        loop: true,
        type: 'warning',
      });
    }
    if (!validImageSize(imageFile)) return false;
    attachmentsFieldProps.onChange(imageFile);
    return true;
  };

  useEffect(() => {
    if (!sendMessageLoader) {
      document.getElementById('message').focus();
    }
  }, [sendMessageLoader]);

  const handleLabelClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const tooltipProps = {
    tooltipStyle: {
      fontSize: 12,
      maxWidth: 200,
      whiteSpace: 'pre-wrap',
      lineBreak: 'normal',
    },
    text: t('common.attachFile'),
  };

  return (
    <CreateChatWrapper>
      <DocumentationPopup
        isOpen={documentsPopupOpen}
        data={community.documentation?.documents}
        selectedDocuments={selectedDocuments}
        groups={community.groups}
        setSelectedDocuments={setSelectedDocuments}
        onRemoveDocument={() => {}}
        onSubmit={onDocumentsAttach}
        closePopup={() => setDocumentsPopupOpen(false)}
      />
      <PushMessage>
        <ButtonWrapper>
          <HiddenInput
            type="file"
            name="uploadFile"
            onChange={handleChange}
            accept="*/*"
            ref={inputRef}
          />
          <Tooltip {...tooltipProps} position="top-center">
            <Layout.Header.Close
              height="40px"
              width="40px"
              disabled={sendMessageLoader}
              onClick={handleLabelClick}
            >
              <GreenPlusIcon width={18} height={18} />
            </Layout.Header.Close>
          </Tooltip>
        </ButtonWrapper>
        <PushMessage.Input isOpen={isOpen}>
          <EmojiArea
            disabled={sendMessageLoader}
            name={FORMFIELDS.MESSAGE}
            label={''}
            error={errors[FORMFIELDS.MESSAGE]}
            register={register}
            control={control}
            placeholder={t('chat.inputPlaceHolder')}
            positionRight="6px"
            top="-325px"
            // maxLength={175}
            width="100%"
            positionTop="1px"
            rows={3}
            withEmojiIcon
            countFontSize={10}
            countFontWeight={400}
            fontSize="10"
            fontWeightLabel={400}
          />
          {attachmentsFieldProps.value && (
            <Images disabled={sendMessageLoader || errors[FORMFIELDS.MESSAGE]}>
              <p>{attachmentsFieldProps.value.name}</p>
              <CloseIcon>
                <CloseButton
                  onClick={() => attachmentsFieldProps.onChange(null)}
                  height="12px"
                  width="12px"
                />
              </CloseIcon>
            </Images>
          )}
          {/* <FileInputControlled
            text={t('documentation.attachFiles')}
            tipText={t('email.maxAttachmentsSize')}
            error={
              errors[FIELDS.ATTACHMENTS] &&
              errors[FIELDS.ATTACHMENTS][0]?.message
            }
            setError={(e) =>
              setError(FIELDS.ATTACHMENTS, {
                type: 'required',
                message: e,
              })
            }
            value={attachmentsFieldProps.value}
            setValue={attachmentsFieldProps.onChange}
            maxSize={15 * 1024}
            width="100%"
            height="80px"
            isLoading={false}
            withPreview={true}
            allowMultiple={true}
          /> */}
        </PushMessage.Input>
        <ButtonWrapper>
          <Layout.Header.Close
            disabled={sendMessageLoader || errors[FORMFIELDS.MESSAGE]}
            height="40px"
            width="40px"
            rotate
            onClick={() => {
              onSubmit();
            }}
            isActive={isActiveSend}
            activeColor
          >
            {sendMessageLoader ? (
              <Loader size="28px" type="button" />
            ) : (
              <LeftCloseArrow width={28} height={28} />
            )}
          </Layout.Header.Close>
        </ButtonWrapper>
      </PushMessage>
    </CreateChatWrapper>
  );
};

export default ChatInput;
