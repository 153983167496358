import React, { FC, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

import {
  ProfileName,
  EditButton,
  DeleteButton,
  Loader,
  SearchInput,
  Button,
  Tooltip,
  PopupButtonMessage,
  ResetPasswordPopup,
} from 'components';
import SelectInputControlled from 'components/Inputs/SelectInputControlled/SelectInputControlledV2';
import SelectInputControlledMulti from 'components/Inputs/SelectInputControlled/SelectInputControlledMultiV2';
import { COMMUNITY_ROLES } from 'configs';
import { getProfileName } from 'utils/common';
import { Down, CSVIcon } from 'static';
import { EDIT_PROFILE, EDIT_USER_ROLE, VIEW_MEMBER } from 'constants/sidebars';
import {
  isAddMemberToGroupLoading,
  isDeleteCommunityUserLoading,
  isRemoveMemberFromGroupLoading,
} from 'store/selectors/currentCommunity';
import { openModal } from 'store/actionCreators/modal';
import { Community, CommunityUser, Group, ModalTypes, Profile } from 'types';
import { useInfoPopup } from '../../../hooks';

import {
  StyledTable,
  StyledHeaderRow,
  StyledHeaderRowToggler,
  StyledRow,
  StyledCell,
  StyledButtonsBox,
  StyledFloatingLoader,
  StyledSearchWrapper,
  StyledHeaderWrapper,
  StyledSelectLoaderWrapper,
  StyledSectionWrapper,
  StyledAddMember,
  StyledEmail,
  StyledExportMembers,
  StyledListWrapper,
  StyledProfileCell,
} from './styled';
import { HighlightText } from 'pages/PersonalChat/ChatUserList';

interface RolesProps {
  community: Community;
  tableGroupsState: {
    [key in COMMUNITY_ROLES]: boolean;
  };
  searchValue: string;
  users: CommunityUser[];
  rowsToShow: number;
  currentUser: Profile;
  csvLoader: boolean;
  usersToGroups: {
    [key: string]: Group[];
  };
  exportCSVData: any;
  onGroupToggle: (type: COMMUNITY_ROLES) => void;
  onGroupsChange: (
    groups: { [p: string]: any }[],
    prevGroups: { [p: string]: any }[],
    user: CommunityUser,
  ) => void;
  onRoleChange: (value: { [p: string]: any }, user: CommunityUser) => void;
  onUserDelete: (user: CommunityUser) => void;
  setSearchValue: (value: string) => void;
  onExportMembers: (communityId: string) => void;
  memberListWrapperRef?: React.MutableRefObject<any>;
}

const DeleteButtonComponent = () => {
  const { t } = useTranslation();

  return (
    <Tooltip text={t('common.tooltipDelete')} zoom={1} isVisible>
      <div>
        <DeleteButton outlined={true} />
      </div>
    </Tooltip>
  );
};

const Roles: FC<RolesProps> = ({
  community,
  tableGroupsState,
  searchValue,
  users,
  rowsToShow,
  currentUser,
  csvLoader,
  usersToGroups,
  exportCSVData,
  onGroupToggle,
  onGroupsChange,
  onRoleChange,
  onUserDelete,
  setSearchValue,
  memberListWrapperRef,
  onExportMembers,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [resetPasswordPopupVisible, setResetPasswordPopupVisible] =
    useState<boolean>(false);
  const [passwordResetting, setPasswordResetting] = useState<boolean>(false);
  const csvLink = useRef<CSVLink>();
  const addMemberToGroupLoading = useSelector(isAddMemberToGroupLoading);
  const removeMemberFromGroupLoading = useSelector(
    isRemoveMemberFromGroupLoading,
  );
  const deleteCommunityUserLoading = useSelector(isDeleteCommunityUserLoading);
  const { showInfoPopup } = useInfoPopup();

  const getUserGroups = (groups) => {
    return groups.map((group) => ({
      value: group.id,
      label: group.name,
    }));
  };

  const headers = [
    { label: t('profile.email'), key: 'email' },
    { label: t('profile.lastName'), key: 'lastName' },
    { label: t('profile.firstName'), key: 'firstName' },
    { label: t('common.role'), key: 'role' },
    { label: t('common.groups'), key: 'group_names' },
    { label: t('profile.dateOfBirth'), key: 'dob' },
    { label: t('profile.privatePhoneNumber'), key: 'privatePhone' },
    { label: t('profile.officePhoneNumber'), key: 'officePhone' },
    { label: t('profile.mobilePhoneNumber'), key: 'mobilePhone' },
    { label: t('common.facebook'), key: 'facebook' },
    { label: t('common.twitter'), key: 'twitter' },
    { label: t('common.linkedin'), key: 'linkedin' },
    { label: t('common.instagram'), key: 'instagram' },
    { label: t('common.youtube'), key: 'youtube' },
    { label: t('profile.city'), key: 'city' },
    { label: t('profile.region'), key: 'region' },
    { label: t('profile.street'), key: 'street' },
    { label: t('profile.postalCode'), key: 'postalCode' },
    { label: t('profile.country'), key: 'country' },
    { label: t('common.description'), key: 'description' },
  ];

  const onExportMembersClick = async () => {
    await onExportMembers(community.id);
    setTimeout(() => {
      csvLink.current.link.click();
    }, 500);
  };

  const handleAddMemberPopupOpen = () => {
    dispatch(
      openModal({
        type: ModalTypes.ADD_MEMBER,
      }),
    );
  };

  const handleResetPasswordClick = async (email: string) => {
    setPasswordResetting(true);

    try {
      await Auth.forgotPassword(email.toLowerCase());
      setResetPasswordPopupVisible(true);
    } catch (_) {
      showInfoPopup({
        type: 'error',
        size: 'lg',
        title: t('auth.resetPasswordErrorModalTitle'),
        message: t('auth.resetPasswordErrorModalText'),
      });
    } finally {
      setPasswordResetting(false);
    }
  };

  return (
    <StyledSectionWrapper>
      <ResetPasswordPopup
        isOpen={resetPasswordPopupVisible}
        onClose={() => setResetPasswordPopupVisible(false)}
      />
      {(addMemberToGroupLoading ||
        removeMemberFromGroupLoading ||
        deleteCommunityUserLoading ||
        passwordResetting) && (
        <StyledFloatingLoader>
          <Loader size="60px" type={null} />
        </StyledFloatingLoader>
      )}
      <StyledHeaderWrapper>
        <StyledSearchWrapper>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/* @ts-ignore*/}
          <SearchInput
            value={searchValue}
            width="100%"
            onChange={({ target }) => setSearchValue(target.value)}
          />
        </StyledSearchWrapper>
        <StyledAddMember>
          <Button
            width="100%"
            style={{ position: 'absolute', height: '100%' }}
            onClick={handleAddMemberPopupOpen}
          >
            {t('communitySettings.addMember')}
          </Button>
        </StyledAddMember>
        <StyledExportMembers>
          <Tooltip
            text={t('communitySettings.exportMembersTooltip')}
            zoom={1}
            isVisible
          >
            <div>
              {!csvLoader ? (
                <Button
                  variant="secondary"
                  IconComponent={CSVIcon}
                  width="100%"
                  onClick={() => onExportMembersClick()}
                >
                  {t('communitySettings.exportMembers')}
                </Button>
              ) : (
                <Button variant="secondary" width="225px">
                  <Loader type={null} size="20px" />
                </Button>
              )}
              <CSVLink
                data={exportCSVData}
                headers={headers}
                filename="community_members.csv"
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            </div>
          </Tooltip>
        </StyledExportMembers>
      </StyledHeaderWrapper>
      <StyledListWrapper ref={memberListWrapperRef}>
        {tableGroupsState &&
          Object.keys(tableGroupsState).map((group) => (
            <StyledTable key={group}>
              <tbody>
                <StyledHeaderRow>
                  <StyledCell>
                    <StyledHeaderRowToggler
                      isOpen={tableGroupsState[group]}
                      onClick={() => onGroupToggle(group as COMMUNITY_ROLES)}
                    >
                      <Down />
                    </StyledHeaderRowToggler>
                  </StyledCell>
                  <StyledCell>{t(`communitySettings.${group}`)}</StyledCell>
                  <StyledCell>{t('common.email')}</StyledCell>
                  <StyledCell>{t('profile.role')}</StyledCell>
                  <StyledCell>{t('common.groups')}</StyledCell>
                  <StyledCell />
                  <StyledCell />
                </StyledHeaderRow>
                {tableGroupsState[group] &&
                  users
                    .slice(0, rowsToShow)
                    .filter((u) => u.communityPermission?.role === group)
                    .map((user) => (
                      <StyledRow key={user.id}>
                        <StyledCell>{/* <CheckBox /> */}</StyledCell>
                        <StyledCell>
                          <StyledProfileCell>
                            <ProfileName
                              searchFilter={searchValue}
                              name={getProfileName(user.profile)}
                              avatar={
                                user.profile.smallLogo || user.profile.logo
                              }
                              onClick={() =>
                                dispatch(
                                  openModal({
                                    type: VIEW_MEMBER,
                                    placement: 'right',
                                    data: user,
                                  }),
                                )
                              }
                            />
                            {group !== COMMUNITY_ROLES.OWNER && (
                              <Tooltip
                                text={t('common.tooltipEdit')}
                                zoom={1}
                                isVisible
                              >
                                <div>
                                  <EditButton
                                    outlined={true}
                                    onClick={() =>
                                      dispatch(
                                        openModal({
                                          type:
                                            user.profile?.allowedToEdit?.includes(
                                              community.id,
                                            ) || user.id === currentUser.userId
                                              ? EDIT_PROFILE
                                              : EDIT_USER_ROLE,
                                          placement: 'right',
                                          data: {
                                            user,
                                            onDelete: () => onUserDelete(user),
                                          },
                                        }),
                                      )
                                    }
                                  />
                                </div>
                              </Tooltip>
                            )}
                          </StyledProfileCell>
                        </StyledCell>
                        <StyledCell>
                          <StyledEmail>
                            <HighlightText
                              searchText={searchValue}
                              text={user.profile.email as string}
                            />
                          </StyledEmail>
                        </StyledCell>
                        <StyledCell>
                          <SelectInputControlled
                            name={`community-role-${user.id}`}
                            value={{
                              value: user.communityPermission.role,
                              label: t(
                                `communitySettings.${user.communityPermission.role}`,
                              ),
                            }}
                            width="95%"
                            options={[
                              {
                                value: COMMUNITY_ROLES.ADMIN,
                                label: t(
                                  `communitySettings.${COMMUNITY_ROLES.ADMIN}`,
                                ),
                              },
                              {
                                value: COMMUNITY_ROLES.MEMBER,
                                label: t(
                                  `communitySettings.${COMMUNITY_ROLES.MEMBER}`,
                                ),
                              },
                              {
                                value: COMMUNITY_ROLES.BLOCKED,
                                label: t(
                                  `communitySettings.${COMMUNITY_ROLES.BLOCKED}`,
                                ),
                              },
                            ]}
                            isDisabled={
                              group === COMMUNITY_ROLES.OWNER ||
                              currentUser.userId === user.id
                            }
                            onChange={(value) => onRoleChange(value, user)}
                          />
                        </StyledCell>
                        <StyledCell>
                          {usersToGroups[user.id] ? (
                            <SelectInputControlledMulti
                              name={`group-role-${user.id}`}
                              value={getUserGroups(usersToGroups[user.id])}
                              options={community.groups?.map((group) => ({
                                value: group.id,
                                label: group.name,
                              }))}
                              width="95%"
                              isDisabled={
                                (group === COMMUNITY_ROLES.OWNER &&
                                  currentUser.userId !== users[0]?.id) ||
                                group === COMMUNITY_ROLES.BLOCKED
                              }
                              onChange={(values, prevValues) =>
                                onGroupsChange(values, prevValues, user)
                              }
                            />
                          ) : (
                            <StyledSelectLoaderWrapper>
                              <Loader type={null} size="40px" />
                            </StyledSelectLoaderWrapper>
                          )}
                        </StyledCell>
                        <StyledCell>
                          <div>
                            <label
                              onClick={() =>
                                handleResetPasswordClick(user.profile.email)
                              }
                            >
                              {t('auth.resetPassword')}
                            </label>
                            <Tooltip
                              text={t('auth.resetPasswordTooltip')}
                              zoom={1}
                              tooltipStyle={{
                                whiteSpace: 'pre-wrap',
                                maxWidth: '300px',
                                height: 'auto',
                                padding: '20px 10px',
                              }}
                              isVisible
                            >
                              <span>i</span>
                            </Tooltip>
                          </div>
                        </StyledCell>
                        <StyledCell>
                          {group !== COMMUNITY_ROLES.OWNER && (
                            <StyledButtonsBox>
                              {/* <Tooltip
                                text={t('common.tooltipEdit')}
                                zoom={1}
                                isVisible
                              >
                                <div>
                                  <EditButton
                                    outlined={true}
                                    onClick={() =>
                                      dispatch(
                                        openModal({
                                          type:
                                            user.profile?.allowedToEdit?.includes(
                                              community.id,
                                            ) || user.id === currentUser.userId
                                              ? EDIT_PROFILE
                                              : EDIT_USER_ROLE,
                                          placement: 'right',
                                          data: {
                                            user,
                                            onDelete: () => onUserDelete(user),
                                          },
                                        }),
                                      )
                                    }
                                  />
                                </div>
                              </Tooltip> */}
                              <PopupButtonMessage
                                ButtonComponent={DeleteButtonComponent}
                                popupHeaderText={t(
                                  'communitySettings.removeUser.title',
                                )}
                                popupMessageText={t(
                                  'communitySettings.removeUser.message',
                                )}
                                popupButtonText={t(
                                  'communitySettings.removeUser.okButton',
                                )}
                                cancelButtonText={t(
                                  'communitySettings.removeUser.cancelButton',
                                )}
                                onConfirm={() => onUserDelete(user)}
                                size="lg"
                              />
                            </StyledButtonsBox>
                          )}
                        </StyledCell>
                      </StyledRow>
                    ))}
              </tbody>
            </StyledTable>
          ))}
      </StyledListWrapper>
    </StyledSectionWrapper>
  );
};

export default Roles;
