import styled from 'styled-components';
import { NotificationsIcon } from 'static';

import { defaultIconButtonStyles } from '../shared';

export const StyledIcon = styled(NotificationsIcon)`
  user-select: none;
  width: 22px;
  height: 22px;
`;

export const StyledButton = styled.button`
  ${defaultIconButtonStyles};
  /* position: absolute; */
  /* right: 16px;
  top: 15px; */
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16330014;
  transition: all 0.2s ease-in-out;
  border-radius: 99999px;
  &:hover {
    background-color: #16330021;
    path:nth-child(1) {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;

export const UnreadNotificationIndicator = styled.div`
  width: 18px;
  height: 18px;
  position: absolute;
  top: -7px;
  right: -8px;
  background-color: ${({ theme }) => theme.color.bittersweet};
  border-radius: 50%;
  line-height: 18px;
  text-align: center;
  font-size: 10px;
  color: white;
  font-weight: 700;
`;
