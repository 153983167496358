import { createRoutine } from 'redux-saga-routines';

import {
  CommunityNotifications,
  CommunityRoles,
  CommunityUser,
  GroupRoles,
} from 'types';

export const doUpdateCommunityNotificationsSettings = createRoutine(
  'UPDATE_CURRENT_COMMUNITY_NOTIFICATIONS_SETTINGS',
  {
    trigger: (payload: {
      preventUpdateOnSuccess?: boolean;
      data: {
        id: string;
        category: string;
        notifications: CommunityNotifications;
      };
      onSuccess: () => void;
    }) => payload,
  },
);

export const doRequestCurrentCommunity = createRoutine(
  'REQUEST_CURRENT_COMMUNITY',
  {
    trigger: (payload: {
      communityId: string;
      invitationCode?: string;
      userId?: string;
    }) => payload,
  },
);

export const doRequestCommunityForChat = createRoutine('REQUEST_COMMUNITY', {
  trigger: (payload: { communityId: string }) => payload,
});

export const doClearCommunity = createRoutine('CLEAR_COMMUNITY');

export const doRequestOverallScheduler = createRoutine(
  'REQUEST_OVERALL_SCHEDULER',
  {
    trigger: (payload: {
      communityId: string;
      eventTypeId?: string;
      from: Date;
      to: Date;
      eventType: { [key: string]: string };
    }) => payload,
  },
);

export const doClearOverallScheduler = createRoutine('CLEAR_OVERALL_SCHEDULER');

export const doGetCommunityLanding = createRoutine('GET_COMMUNITY_LANDING', {
  trigger: (payload: string) => payload,
});

export const doClearCurrentCommunityError = createRoutine(
  'CLEAR_CURRENT_COMMUNITY_ERROR',
);

export const doGetCommunityTotal = createRoutine('GET_COMMUNITY_TOTAL', {
  trigger: (payload: { communityId: string; from: Date; to: Date }) => payload,
});

export const doGetCommunityMembersByGroups = createRoutine(
  'GET_COMMUNITY_MEMBERS_BY_GROUPS',
  {
    trigger: (payload: { communityId: string; withoutBlocked?: boolean }) =>
      payload,
  },
);

export const doGetCommunityUserGroups = createRoutine(
  'GET_COMMUNITY_USERS_GROUPS',
  {
    trigger: (payload: { communityId: string; userIds: string[] }) => payload,
  },
);

export const doClearCommunityUsersGroups = createRoutine(
  'CLEAR_COMMUNITY_USERS_GROUPS',
);

export const doAddMemberToGroup = createRoutine('ADD_MEMBER_TO_GROUP', {
  trigger: (payload: {
    communityId: string;
    user: CommunityUser;
    groupId: string;
    data: { users: string[] };
    onSuccess: () => void;
    onFailure: () => void;
  }) => payload,
});

export const doRemoveMemberFromGroup = createRoutine(
  'REMOVE_MEMBER_FROM_GROUP',
  {
    trigger: (payload: {
      communityId: string;
      user: CommunityUser;
      groupId: string;
      onSuccess: () => void;
      onFailure: () => void;
    }) => payload,
  },
);

export const doGetOverallScheduleStatistics = createRoutine(
  'GET_OVERALL_STATISTICS',
  {
    trigger: (payload: {
      id: string;
      fromDate: Date;
      toDate: Date;
      eventTypeId: string;
    }) => payload,
  },
);

export const doToggleMenu = createRoutine('TOGGLE_MENU');

export const doRearrangeGroups = createRoutine('RERANGE_GROUPS', {
  trigger: (payload: { communityId: string; start: number; end: number }) =>
    payload,
});

export const doInviteMember = createRoutine('INVITE_MEMBER', {
  trigger: (payload: {
    communityId: string;
    email: string;
    role: CommunityRoles;
    userId?: string;
    groups: { id: string; role: GroupRoles }[];
    firstName?: string;
    lastName?: string;
    onSuccess: () => void;
    onFailure: (e: any) => void;
  }) => payload,
});

export const doClearCurrentCommunity = createRoutine('CLEAR_CURRENT_COMMUNITY');

export const doRenewInvitationCode = createRoutine('RENEW_INVITATION_CODE', {
  trigger: (payload: string) => payload,
});
