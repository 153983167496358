import styled, { keyframes } from 'styled-components';
import { defaultIconButtonStyles } from '../shared';

const pulseRing = keyframes`
    0% {
        transform: scale(.33);
    }
    80%, 100% {
        opacity: 0;
    }
`;

const pulse = keyframes`
    0% {
        transform: scale(1.25);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.25);
    }
`;

export const StyledSpan = styled.span`
  display: flex;
  position: absolute;
  align-items: center;
  top: 0;
  right: 34px;
  ${({ addCss }) => addCss};
`;

export const StyledSettingsButton = styled.button`
  ${defaultIconButtonStyles};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 15px;
  span {
    color: ${({ theme }) => theme.color.white};
    font-size: 9px;
    animation: ${pulse} 1.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    z-index: 100;
  }
  &:before {
    content: '';
    z-index: 100;
    position: absolute;
    display: block;
    width: 19px;
    height: 19px;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: #f89387;
    animation: ${pulseRing} 1.7s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    animation-delay: 0.85s;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 19px;
    height: 19px;
    background-color: ${({ theme }) => theme.color.bittersweet};
    border-radius: 50%;
    box-shadow: 0 0 8px #f65542;
    animation: ${pulse} 1.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
`;

export const StyledSettingsBlinkButton = styled.button`
  ${defaultIconButtonStyles};
  width: 15px;
  height: 15px;
  background: ${({ theme }) => theme.color.brightTurquoise};
  border-radius: 15px;
  color: ${({ theme }) => theme.color.white};
  font-size: 8px;
  transition: right 0.3s;
  animation: blinker 2s linear infinite;
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;
