import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ChatBlockWrapper = styled.div`
  display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  align-items: center;
  flex-direction: row;
  gap: 4px;
  padding: 16px 10px 20px 10px;
  min-height: 132px;
  max-height: ${({ listMaxHeight }) => listMaxHeight + 'px'};
  overflow-x: auto;
  overflow-y: hidden;
`;

export const UserBlock = styled(NavLink)`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 16px 0px;
  border-radius: 6px;
  width: 100%;
  max-width: 88px;
  min-height: 112px;
  position: relative;
  &:hover {
    background-color: ${({ theme }) => theme.color.blackSqueeze};
    p {
      color: ${({ theme }) => theme.color.brightTurquoise} !important;
    }
  }
  p {
    color: ${({ theme, selected }) =>
      selected
        ? theme.color.brightTurquoise
        : theme.color.pickledBluewood} !important;
  }
  background-color: ${({ theme, selected, isUnseen }) =>
    selected || isUnseen ? theme.color.blackSqueeze : 'transparent'};
`;

UserBlock.ImageWrapper = styled.div`
  box-sizing: border-box;
  display: block;
  margin-bottom: 10px;
`;

UserBlock.Name = styled.p`
  cursor: pointer;
  line-height: 16px;
  display: inline-block;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  padding: 2px 8px 0px 8px;
  min-height: 7px;
`;

export const BoxWrapper = styled.div`
  display: flex;
  padding: 10px 0px 16px 0px;
  align-items: center;
  position: relative;
  flex-direction: column;
  max-width: 88px;
  border-radius: 6px;
  width: 100%;
  height: inherit;
  min-height: 112px;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  transition: border 0.3s ease-in-out;
  &:hover {
    border: 2px solid ${({ theme }) => theme.color.pickledBluewood};
    .plus-icon {
      background-color: #8298abb0;
      color: ${({ theme }) => theme.color.white};
    }
  }
  .plus-icon {
    font-weight: 400;
    width: 40px;
    color: ${({ theme }) => theme.color.pickledBluewood};
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #8298ab;
    font-size: 30px;
  }
  .plus-title {
    color: ${({ theme }) => theme.color.pickledBluewood};
    font-weight: 600;
    text-align: center;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SkeletonBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 88px;
  height: 112px;
  align-items: center;
  gap: 16px;
`;
