import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { breakpoints } from 'theme';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  flex-direction: column;
`;

export const MainInfoBlock = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 18px;
  justify-content: ${({ left }) => (left ? 'left' : 'center')};
  @media (max-width: 975px) {
    width: 100%;
    display: grid;
    justify-content: space-around;
  }
`;

export const SocialLinks = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 14px;
  align-items: center;
  @media ${breakpoints.downMdPlus} {
    column-gap: 12px;
  }
  @media (max-width: 975px) {
    display: inline-flex;
  }
`;

export const BtnTitle = styled.p`
  margin-left: 6px;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Open Sans', sans- serif;
`;

export const UpdateBtnWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  button {
    font-family: 'Open Sans', sans-serif;
  }
  @media ${breakpoints.downSm} {
    padding: 0px 12px;
    button {
      padding: 8px 8px !important;
      min-width: 100% !important;
      height: auto;
    }
  }
`;

export const DescriptionContainer = styled.div`
  width: 50%;
  display: flex;
  margin: 16px;
  justify-content: ${({ hasDescription }) =>
    hasDescription ? 'flex-start' : 'flex-end'};
  font-weight: normal;
  text-align: left;

  @media (max-width: 975px) {
    width: 65%;
    display: inline-block;
    justify-content: space-around;
    margin: 16px 0px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const HeadInfoContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 300px;
  height: 194px;
  padding-top: 50px;
  max-width: 1080px;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.dew};
  @media (max-width: 975px) {
    padding-top: 20px;
    display: inline-block;
    justify-content: space-around;
    height: auto;
  }
  @media ${breakpoints.downLgPlus} {
    padding-left: 36px;
    padding-right: 36px;
  }
`;

export const HeadInfoContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  padding: 16px 0px 20px;
  @media ${breakpoints.downMd} {
    gap: 12px;
  }
  @media ${breakpoints.downSm} {
    gap: 16px;
  }
`;

export const ProfileName = styled.h1`
  font-size: 24px;
  text-align: left;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-weight: 600;
`;

export const SocialInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 975px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2px;
    margin-left: 0;
  }
`;

export const UserImage = styled.img`
  width: 128px;
  height: 128px;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
`;

export const Description = styled.p`
  font-size: 14px;
  border-radius: 5px;
  font-weight: 400;
  overflow-wrap: break-word;
  overflow-y: auto;
  border: 1px solid #00000021;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #f7faf9;
  line-height: 20px;
  color: ${({ theme }) => theme.color.shark};
  width: 100%;
  line-break: normal;
  white-space: pre-wrap;
  a {
    color: ${({ theme }) => theme.color.brightTurquoise};
  }
  @media (max-width: 975px) {
    justify-content: space-around;
    max-height: 120px;
  }
  @media (max-width: 600px) {
    max-height: 92px;
  }
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ customPadding }) => customPadding || '8px 0px'};
  p {
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    color: ${({ theme }) => theme.color.pickledBluewood};
    border-bottom: 1px solid ${({ theme }) => theme.color.pickledBluewood};
  }
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 23px 0px 20vh 0px;
  max-width: 1080px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  column-gap: 12px;
  row-gap: 12px;
  @media ${breakpoints.downLgPlus} {
    padding: 36px 48px 20vh 48px;
  }
  @media (max-width: 1100px) {
    justify-content: space-around;
  }
  @media (max-width: 428px) {
    padding: 23px 27px 20vh 27px;
  }
`;

export const ColumWrapper = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  column-gap: 12px;
  row-gap: 12px;
  flex-wrap: wrap;
  @media (max-width: 580px) {
    div {
      max-width: 100% !important;
    }
  }

  div {
    flex: 1 0 calc(50%);
    max-width: calc(50% - 12px);
  }
`;

export const LinkEmail = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.brightTurquoise};
`;
