import React, { FC, useEffect, useMemo, useState } from 'react';
import { isEmpty, map } from 'lodash/fp';
import Linkify from 'react-linkify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { SignedLink, Button, Loader, SocialLinkButton } from 'components';
import {
  getCurrentUserInCommunity,
  getSendRequestLoading,
} from 'store/selectors/currentCommunity';
import { openModal } from 'store/actionCreators/modal';
import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import { CommunityRoles, CommunitySocialLink, ModalTypes } from 'types';

import {
  StyledWrapper,
  StyledDescriptionBlock,
  StyledDescriptionTitle,
  StyledDescriptionText,
  StyledLinksBlock,
  StyledInvitationBlock,
  StyledLinksContainer,
  SocialMedia,
} from './styled';
import { socialNetwork } from 'configs/socialNetworks';
import { capMap } from 'utils';

interface ContentProps {
  description: string;
  links: CommunitySocialLink[];
  isUserInCommunity: boolean;
  buttonDisabled: boolean;
  sendRequest: () => void;
  socialLinks: CommunitySocialLink[];
  isMenuOpen: boolean;
}

const Content: FC<ContentProps> = ({
  description,
  links = [],
  isUserInCommunity,
  buttonDisabled,
  sendRequest,
  isMenuOpen,
  socialLinks,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { communityId } = useParams();
  const currentUser = useSelector(getCurrentUserInCommunity);
  const userRole = currentUser?.communityPermission.role;
  const { addToast } = useToasts();
  const inviteRequestLoading = useSelector(getSendRequestLoading);
  const [socialLinksArray, setSocialLinksArray] = useState([]);
  const socialData = useMemo(() => {
    return socialNetwork
      .map((li) => {
        if (![...(socialLinks || [])].some(({ label }) => label === li)) {
          return { label: li, href: '' };
        }
      })
      .filter(Boolean);
  }, [socialNetwork, socialLinks]);

  useEffect(() => {
    setSocialLinksArray(socialData);
  }, [socialData]);

  const buttonText = useMemo(() => {
    return t(
      buttonDisabled
        ? 'community.requestSentButton'
        : 'community.joinCommunity',
    );
  }, [buttonDisabled]);

  const openCommunitySetting = () => {
    if ([CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(userRole))
      navigate(
        `${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.SETTINGS}`,
      );
  };

  const handleAddMemberPopupOpen = () => {
    if ([CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(userRole)) {
      navigate(
        `${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.SETTINGS}#members`,
      );
      dispatch(
        openModal({
          type: ModalTypes.ADD_MEMBER,
        }),
      );
    } else {
      addToast(t('errors.onlyForAdmin'), {
        appearance: 'info',
        autoDismiss: true,
      });
    }
  };

  return (
    <StyledWrapper>
      <StyledDescriptionBlock>
        <StyledDescriptionTitle>
          {t('community.descriptionTitle')}
        </StyledDescriptionTitle>
        <SocialMedia>
          {capMap(
            ({ value, label }) => (
              <SocialLinkButton
                key={value}
                openEditSidebar={openCommunitySetting}
                href={value}
                socialNetwork={label}
                width={value ? '18px' : '14px'}
                userRole={userRole}
                itsMe={false}
              />
            ),
            [...(socialLinks || []), ...(socialLinksArray || [])],
          )}
        </SocialMedia>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          <StyledDescriptionText>
            {description || t('community.descriptionPlaceholder')}
          </StyledDescriptionText>
        </Linkify>
      </StyledDescriptionBlock>
      <StyledLinksBlock>
        {isUserInCommunity ? (
          <StyledInvitationBlock>
            <Button width="100%" onClick={handleAddMemberPopupOpen}>
              {t('communitySettings.addMember')}
            </Button>
          </StyledInvitationBlock>
        ) : (
          <Button
            onClick={sendRequest}
            disabled={buttonDisabled}
            variant={inviteRequestLoading ? 'secondary' : null}
          >
            {inviteRequestLoading ? (
              <Loader type="button" size="28px" thickness={2} />
            ) : (
              buttonText
            )}
          </Button>
        )}
        {!isEmpty(links) && (
          <StyledLinksContainer>
            {map(
              ({ label, value }) => (
                <SignedLink
                  key={label}
                  name={value}
                  path={!value.includes('https') ? `https://${value}` : value}
                  signature={label}
                />
              ),
              links,
            )}
          </StyledLinksContainer>
        )}
      </StyledLinksBlock>
    </StyledWrapper>
  );
};

export default Content;
