import { EventReply } from './eventReply';
import { Event } from './event';
import { EventType, EventTypeAndDefaultEventSetting } from './eventType';
import { EventResponse } from './eventResponse';
import { EventTask } from './eventTask';
import { Group, GroupUser } from './group';
import { CommunityUser } from './community';
import { Profile } from './user';
import { Chat } from 'store/reducers/chat';

export enum ModalTypes {
  ABOUT_CHAT_GROUP = 'ABOUT_CHAT_GROUP',
  GIVE_REPLY = 'GIVE_REPLY',
  EVENT_DETAILS = 'EVENT_DETAILS',
  CREATE_EVENT = 'CREATE_EVENT',
  CREATE_EVENT_OVERALL = 'CREATE_EVENT_OVERALL',
  EDIT_COMM_EVENT_TYPE_AND_DEFAULT_SETTING = 'EDIT_COMM_EVENT_TYPE_AND_DEFAULT_SETTING',
  EDIT_COMMUNITY_RESPONSE = 'EDIT_COMMUNITY_RESPONSE',
  EDIT_COMMUNITY_TASK = 'EDIT_COMMUNITY_TASK',
  INVITE_MEMBER = 'INVITE_MEMBER',
  ADD_GROUP_MEMBERS = 'ADD_GROUP_MEMBERS',
  EDIT_USER_ROLE = 'EDIT_USER_ROLE',
  ADD_FIELD = 'ADD_FIELD',
  SHARE_POPUP = 'SHARE_POPUP',
  POPUP_MESSAGE = 'POPUP_MESSAGE',
  VIEW_MEMBER = 'VIEW_MEMBER',
  TASK_DETAILS = 'TASK_DETAILS',
  CREATE_CHAT = 'CREATE_CHAT',
  ADD_MEMBER = 'ADD_MEMBER',
  COMMUNITY_POPUP = 'COMMUNITY_POPUP',
  CHAT_POPUP = 'CHAT_POPUP',
  CREATE_GROUP_CHAT = 'CREATE_GROUP_CHAT',
  UPDATE_GROUP_CHAT = 'UPDATE_GROUP_CHAT',
  SUBSCRIPTION_OVER_POPUP = 'SUBSCRIPTION_OVER_POPUP',
  ADD_GROUP_MEMBERS_CHAT = 'ADD_GROUP_MEMBERS_CHAT',
}

export type EventDetailsSidebarData = {
  eventId: string;
  date: Date;
  width: number;
  eventResponseId: string;
  openTotal: boolean;
  filterFrom: Date;
  filterTo: Date;
};

export type GiveReplySidebarData = {
  eventId: string;
  userId: string;
  filterFrom: Date;
  filterTo: Date;
  date: Date;
  subReply: (EventReply & { tasks: string[] }) | null;
  isViewOnly: boolean;
  isAdmin: boolean;
  name: string;
};

export type CreateEventSidebarData = {
  filterFrom: Date;
  filterTo: Date;
  event?: Event;
  date?: Date;
  groupId?: string;
};

export type AddGroupMembersData = {
  communityId: string;
  group: Group;
  groupMembers?: GroupUser[];
  fromGroup?: boolean;
};

export type EditUserRoleData = {
  communityId: string;
  user: CommunityUser | GroupUser;
  currentUserInCommunity?: CommunityUser;
  currentUserInGroup?: GroupUser;
  isForGroup?: boolean;
  onDelete: () => void;
};

export type AddFieldData = {
  onAddField: (name: string) => void;
};

export type SharePopupData = {
  eventId?: string;
};

export type PopupMessageData = {
  popupHeaderText: string;
  popupMessageText?: string;
  popupButtonText: string;
  withoutCloseButton?: boolean;
  handleConfirm?: () => void;
};

export type ViewMemberData = {
  profile: Profile;
};

export type TaskDetailsData = {
  task: EventTask;
  taskMembersIds: string[];
};

interface ModalStateCommon {
  isOpen: boolean;
  isForGroup?: boolean;
  edit?: boolean;
  placement?: 'left' | 'right';
}

export interface EventDetailsSidebarState extends ModalStateCommon {
  type: ModalTypes.EVENT_DETAILS;
  data: EventDetailsSidebarData;
}

export interface GiveReplySidebarState extends ModalStateCommon {
  type: ModalTypes.GIVE_REPLY;
  data: GiveReplySidebarData;
}

export interface CreateEventSidebarState extends ModalStateCommon {
  type: ModalTypes.CREATE_EVENT;
  data: CreateEventSidebarData;
}

export interface EditCommunityEventTypeState extends ModalStateCommon {
  type: ModalTypes.EDIT_COMM_EVENT_TYPE_AND_DEFAULT_SETTING;
  data?: EventTypeAndDefaultEventSetting;
}

export interface EditCommunityEventResponseState extends ModalStateCommon {
  type: ModalTypes.EDIT_COMMUNITY_RESPONSE;
  data?: EventResponse;
}

export interface EditCommunityTaskState extends ModalStateCommon {
  type: ModalTypes.EDIT_COMMUNITY_TASK;
  data?: EventTask;
}

export interface AddGroupMembersState extends ModalStateCommon {
  type: ModalTypes.ADD_GROUP_MEMBERS;
  data: AddGroupMembersData;
}

export interface EditUserRoleState extends ModalStateCommon {
  type: ModalTypes.EDIT_USER_ROLE;
  data: EditUserRoleData;
}

export interface AddFieldState extends ModalStateCommon {
  type: ModalTypes.ADD_FIELD;
  data: AddFieldData;
}

export interface SharePopupState extends ModalStateCommon {
  type: ModalTypes.SHARE_POPUP;
  data?: SharePopupData;
}

export interface PopupMessageState extends ModalStateCommon {
  type: ModalTypes.POPUP_MESSAGE;
  data: PopupMessageData;
}

export interface ViewMemberState extends ModalStateCommon {
  type: ModalTypes.VIEW_MEMBER;
  data: ViewMemberData;
}

export interface TaskDetailsState extends ModalStateCommon {
  type: ModalTypes.TASK_DETAILS;
  data: TaskDetailsData;
}

export interface CreateChatState extends ModalStateCommon {
  type: ModalTypes.CREATE_CHAT;
  data?: null;
}

export interface AddMemberState extends ModalStateCommon {
  type: ModalTypes.ADD_MEMBER;
  data?: null;
}

export interface GroupAndEventState extends ModalStateCommon {
  type: ModalTypes.COMMUNITY_POPUP;
  data?: null;
}
export interface ChatState extends ModalStateCommon {
  type: ModalTypes.CHAT_POPUP;
  data?: null;
}
export interface createChatGroup extends ModalStateCommon {
  type: ModalTypes.CREATE_GROUP_CHAT;
  data?: null;
}
export interface UpdateChatGroup extends ModalStateCommon {
  type: ModalTypes.UPDATE_GROUP_CHAT;
  data?: {
    chat: Chat;
    members: GroupUser[];
    communityId: string;
    isChatUpdate: boolean;
  };
}
export interface SubscriptionOverState extends ModalStateCommon {
  type: ModalTypes.SUBSCRIPTION_OVER_POPUP;
  data?: null;
}
