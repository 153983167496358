import React, { useCallback, useEffect, useMemo } from 'react';

import FooterCell from '../cells/Footer';
import Group from '../cells/Group';
import { EventGridItem, FooterData } from 'types';
import { EventStatisticValue } from 'utils/schedule';
import { getColumnDivider } from '../utils';

const useFooterData = ({
  footer,
  zoom,
  statisticsTab,
  divideColumns,
  groups,
}: {
  footer: FooterData;
  zoom: number;
  statisticsTab: boolean;
  divideColumns?: number[][];
  groups: { [key: string]: EventGridItem & { isShow?: boolean } };
}): {
  filteredFooterData: { data: EventStatisticValue[]; label: string };
  footerHeight: number;
  footerRowHeight: number;
  footerCellRender: (params: any) => JSX.Element;
} => {
  const filteredFooterData = useMemo(() => {
    return {
      ...footer,
      data: groups.total.isShow ? footer.data : [],
    };
  }, [footer, groups]);

  // useEffect(() => {
  //   const elements = document.getElementsByClassName('ReactVirtualized__Grid');
  //   const targetElement = elements[2] as HTMLElement;

  //   if (targetElement?.offsetHeight > 0) {
  //     const elHeight = targetElement.offsetHeight;
  //     if (elHeight > 0) {
  //       targetElement.style.height = `${elHeight + 17}px`;
  //     }
  //   }
  // }, [groups.total.isShow]);

  const footerCellRender = useCallback(
    ({ key, style, rowIndex, columnIndex }) => {
      const divide = getColumnDivider(columnIndex, divideColumns);

      if (rowIndex === 0) {
        return (
          <Group
            eventId={
              filteredFooterData.data[rowIndex]?.eventIds
                ? filteredFooterData.data[rowIndex]?.eventIds[columnIndex - 1]
                : ''
            }
            dark
            key={key}
            style={style}
            group={groups.total}
            isFirst={columnIndex === 0}
            isOpen={groups.total.isShow}
            zoom={zoom}
            divide={divide}
            noArrow={footer?.data?.length < 1}
          />
        );
      }

      return (
        <FooterCell
          center={columnIndex !== 0}
          key={key}
          style={style}
          zoom={zoom}
          eventId={
            filteredFooterData.data[rowIndex - 1]?.eventIds &&
            filteredFooterData.data[rowIndex - 1]?.eventIds[columnIndex - 1]
          }
          date={
            filteredFooterData.data[rowIndex - 1]?.eventDates &&
            filteredFooterData.data[rowIndex - 1]?.eventDates[columnIndex - 1]
          }
          eventResponseId={
            filteredFooterData.data[rowIndex - 1]?.eventResponseId
          }
          statisticsTab={statisticsTab}
          divide={divide}
        >
          {columnIndex === 0
            ? filteredFooterData.data[rowIndex - 1]?.label
            : filteredFooterData.data[rowIndex - 1]?.values[columnIndex - 1]}
        </FooterCell>
      );
    },
    [filteredFooterData, groups, zoom, divideColumns],
  );

  const footerHeight = useMemo(
    () => (filteredFooterData.data.length * 39 + 39) * zoom,
    [filteredFooterData, zoom],
  );

  const footerRowHeight = useMemo(() => 39 * zoom, [zoom]);

  return {
    filteredFooterData,
    footerHeight,
    footerRowHeight,
    footerCellRender,
  };
};

export default useFooterData;
