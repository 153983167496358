import React, { useCallback, useMemo } from 'react';
import { filter } from 'lodash/fp';

import Head from '../cells/Head';
import Sticky from '../cells/Sticky';
import Cell from '../cells/Cell';
import StatisticsCell from '../cells/StatisticsCell';
import Group from '../cells/Group';
import GridScrollBlock from '../GridScrollBlock/GridScrollBlock';
import { EventGridItem, EventToRender, CommunityUser } from 'types';
import { getColumnDivider } from '../utils';

const useCommonData = ({
  data,
  header,
  zoom,
  statisticsTab,
  currentUser,
  handleScrollGrid,
  divideColumns,
  groups,
  filterValue,
}: {
  data: EventGridItem[];
  header: EventToRender[];
  zoom: number;
  statisticsTab: boolean;
  currentUser: CommunityUser;
  handleScrollGrid: (direction: 'start' | 'end') => void;
  divideColumns?: number[][];
  groups: { [key: string]: EventGridItem & { isShow?: boolean } };
  filterValue?: string;
}): {
  filteredData: any[];
  cellRendererCommon: (params: any) => JSX.Element;
} => {
  const combinedData = [
    {
      id: 'events',
      type: statisticsTab ? 'statistics' : 'event',
      values: header,
    } as EventGridItem,
    ...data,
  ];

  const filteredData = useMemo(
    () =>
      filter((item) => {
        if (
          item.type !== 'group' &&
          item.type !== 'event' &&
          Object.keys(groups).filter((g) => g !== 'total').length > 0
        ) {
          return groups[item.group]?.isShow;
        }
        return true;
      }, combinedData),
    [combinedData, groups],
  );

  const cellRendererCommon = useCallback(
    ({ rowIndex, key, style, columnIndex }) => {
      const props = { key, style, zoom };
      const divide = getColumnDivider(columnIndex, divideColumns);
      const headData = header && header[columnIndex - 1];
      if (rowIndex === 0) {
        if (columnIndex === 0) {
          return (
            <GridScrollBlock
              key={key}
              style={style}
              zoom={zoom}
              onClick={(direction) => handleScrollGrid(direction)}
            />
          );
        }

        return (
          <Head
            {...props}
            headData={headData}
            statisticsTab={statisticsTab}
            divide={divide}
            filterValue={filterValue}
          />
        );
      } else {
        const isCurrentUser = filteredData[rowIndex]?.id === currentUser.id;

        if (filteredData[rowIndex].type === 'group') {
          return (
            <Group
              eventId={headData && headData?.id}
              isMark
              key={key}
              style={style}
              group={filteredData[rowIndex]}
              isFirst={columnIndex === 0}
              isOpen={groups[filteredData[rowIndex].id]?.isShow}
              zoom={zoom}
              divide={divide}
            />
          );
        } else if (columnIndex === 0) {
          return (
            <Sticky
              userId={filteredData[rowIndex]?.id}
              data={filteredData[rowIndex]}
              zoom={zoom}
              isCurrentUser={isCurrentUser}
              statisticsTab={statisticsTab}
              divide={divide}
              {...props}
            />
          );
        } else if (filteredData[rowIndex].type === 'statistics') {
          const cellData =
            (filteredData[rowIndex].values &&
              filteredData[rowIndex].values[columnIndex - 1]) ||
            {};

          return (
            <StatisticsCell
              key={key}
              style={style}
              cell={{
                ...cellData,
                width: header[columnIndex - 1]?.width,
              }}
              zoom={zoom}
              isCurrentUser={isCurrentUser}
              divide={divide}
            />
          );
        }

        return (
          <Cell
            userId={filteredData[rowIndex]?.id}
            eventId={headData && headData?.id}
            key={key}
            style={style}
            cell={
              filteredData[rowIndex].values &&
              filteredData[rowIndex].values[columnIndex - 1]
            }
            zoom={zoom}
            isCurrentUser={isCurrentUser}
            divide={divide}
          />
        );
      }
    },
    [filteredData, groups, header, zoom, divideColumns],
  );

  return {
    filteredData,
    cellRendererCommon,
  };
};

export default useCommonData;
