import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Linkify from 'react-linkify';
import { useDispatch, useSelector } from 'react-redux';
import { EDIT_PROFILE } from 'constants/sidebars';

import {
  Sidebar,
  SidebarHeader,
  SidebarContent,
  SocialLinkButton,
  TextInput,
  HeaderEditButton,
  Divider,
} from 'components';
import { UserPlaceholder } from 'static';
import { capMap, getProfileName } from 'utils';
import { COMMUNITY_ROUTES, PROFILE_ROUTES, ROUTES } from 'configs';
import { selectIsChatsAvailable } from 'store/selectors/subscriptions';
import { closeModal, openModal } from 'store/actionCreators/modal';
import { getAuthUser, getMyId } from 'store/selectors/auth';
import { CommunityRoles, Profile } from 'types';

import {
  StyledImage,
  StyledMessageBlock,
  StyledSendMessageLabel,
  StyledDescriptionBlock,
  StyledDescriptionTitle,
  StyledDescriptionText,
  StyledLink,
  ContentWrapper,
  EditBtnWrapper,
  LinkAndUpdateBtnWrapper,
  MainBtnWrapper,
  IconSendMessage,
  SocialLinksWrapper,
} from './styled';
import { useClickOutsideClose } from 'hooks';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import moment from 'moment';
import { socialNetwork } from 'configs/socialNetworks';

const ViewMember = ({
  user: { profile: user },
}: {
  user: { profile: Profile };
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authId = useSelector(getMyId);
  const chatsEnabled = useSelector(selectIsChatsAvailable);
  const refModal = React.createRef<HTMLDivElement>();
  const community = useSelector(getCurrentCommunity);
  const [socialLinksArray, setSocialLinksArray] = useState([]);
  const currentUserData = useSelector(getCurrentUserInCommunity);
  const userRole = currentUserData?.communityPermission.role;
  const socialLinks =
    user.links?.filter(
      (l) =>
        l.label === 'facebook' ||
        l.label === 'linkedin' ||
        l.label === 'instagram' ||
        l.label === 'twitter' ||
        l.label === 'youtube',
    ) || [];
  const itsMe = authId === user.userId;
  const showChatButton = chatsEnabled && !itsMe;
  const extraStyle = {
    borderColor: '#8298ab',
    marginRight: '0px',
    width: '100%',
    justifyContent: 'center',
  };
  const closeSidebar = () => {
    dispatch(closeModal());
  };

  const socialData = useMemo(() => {
    return socialNetwork
      .map((li) => {
        if (![...(socialLinks || [])].some(({ label }) => label === li)) {
          return { label: li, href: '' };
        }
      })
      .filter(Boolean);
  }, [socialNetwork, socialLinks]);

  useEffect(() => {
    setSocialLinksArray(socialData);
  }, []);

  const handleSendingMessage = (e) => {
    e.preventDefault();
    closeSidebar();
    navigate(
      `${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.CHAT}?peerId=${
        user && user.userId
      }`,
    );
    // navigate(`${ROUTES.PROFILE}/${PROFILE_ROUTES.CHAT}/${user && user.userId}`);
  };

  const getDOB = (dob: string) => {
    return moment(dob)
      .tz(user.timeZone || 'Europe/Zurich')
      .format('YYYY-MM-DD');
  };
  const isVisible =
    ([CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(userRole) ||
      itsMe) &&
    (user.allowedToEdit?.includes(community.id) || itsMe);

  useClickOutsideClose({ ref: refModal, callback: closeSidebar });

  const handleUpdateProfile = () =>
    dispatch(
      openModal({
        type:
          (user.allowedToEdit?.includes(community.id) || itsMe) && EDIT_PROFILE,
        placement: 'right',
        data: {
          user: {
            profile: user,
          },
        },
      }),
    );

  const openEditSidebar = (data?: string) => {
    if (
      ![...(socialLinks || []), ...(socialLinksArray || [])].some(
        (li) => li?.label === data && li?.value,
      )
    )
      if (
        [CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(userRole) ||
        itsMe
      )
        dispatch(
          openModal({
            type:
              (user.allowedToEdit?.includes(community.id) || itsMe) &&
              EDIT_PROFILE,
            data: data && {
              user: { profile: { ...user, selectedFiled: data } },
            },
            placement: 'right',
          }),
        );
  };
  const openEdit = () => {};

  return (
    <Sidebar ref={refModal}>
      <SidebarHeader label={getProfileName(user)} onCloseClick={closeSidebar} />
      <SidebarContent fullHeight>
        <StyledImage src={user.logo ? user.logo : UserPlaceholder} />
        <ContentWrapper>
          {!showChatButton && (
            <EditBtnWrapper>
              {isVisible || itsMe ? (
                <>
                  <Divider customMargin="0px" />
                  <div style={{ padding: '0px 12px', width: '100%' }}>
                    <HeaderEditButton
                      style={extraStyle}
                      title={t('profile.editProfile')}
                      openEditSidebar={handleUpdateProfile}
                      titleVisible
                    />
                  </div>
                  <Divider customMargin="0px 0px 0px 0px" />
                </>
              ) : null}
            </EditBtnWrapper>
          )}
          {showChatButton && (
            <MainBtnWrapper>
              {showChatButton && <Divider customMargin="0px" />}
              <LinkAndUpdateBtnWrapper>
                {showChatButton && (
                  <StyledLink onClick={handleSendingMessage}>
                    <StyledMessageBlock>
                      <IconSendMessage />
                      <StyledSendMessageLabel>
                        {t('common.sendMessage')}
                      </StyledSendMessageLabel>
                    </StyledMessageBlock>
                  </StyledLink>
                )}
                {showChatButton && isVisible && (
                  <HeaderEditButton
                    titleVisible
                    style={extraStyle}
                    title={t('profile.editProfile')}
                    openEditSidebar={handleUpdateProfile}
                  />
                )}
              </LinkAndUpdateBtnWrapper>
              {showChatButton && <Divider customMargin="0px 0px 0px 0px" />}
            </MainBtnWrapper>
          )}
          {isVisible ? (
            <>
              <SocialLinksWrapper>
                {capMap(
                  ({ value, label }, index) => (
                    <SocialLinkButton
                      key={`${label} + ${index}`}
                      openEditSidebar={openEditSidebar}
                      href={value}
                      socialNetwork={label}
                      width={value ? '18px' : '14px'}
                      userRole={userRole}
                      itsMe={itsMe}
                    />
                  ),
                  [...(socialLinks || []), ...(socialLinksArray || [])],
                )}
              </SocialLinksWrapper>
              <Divider customMargin="0px 0px 0px 0px" />
            </>
          ) : (
            socialLinks.length > 0 && (
              <>
                <SocialLinksWrapper>
                  {socialLinks.map((l, index) => (
                    <SocialLinkButton
                      key={`${l.label} + ${index}`}
                      href={l.value}
                      socialNetwork={l.label}
                      width="16px"
                      openEditSidebar={openEdit}
                      userRole={userRole}
                      itsMe={itsMe}
                    />
                  ))}
                </SocialLinksWrapper>
                <Divider customMargin="0px 0px 0px 0px" />
              </>
            )
          )}
          {user.description && (
            <StyledDescriptionBlock>
              <StyledDescriptionTitle>
                {t('common.aboutMe')}
              </StyledDescriptionTitle>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                <StyledDescriptionText>
                  {user.description}
                </StyledDescriptionText>
              </Linkify>
            </StyledDescriptionBlock>
          )}
          {isVisible && (
            <>
              {!!user.profileName && (
                <TextInput
                  disabled
                  extraWrapperStyle={{ justifyContent: 'center' }}
                  label={t('profile.profileName')}
                  value={user.profileName}
                />
              )}
              {!!user.firstName && (
                <TextInput
                  inputValueStyle={{ textTransform: 'capitalize' }}
                  disabled
                  extraWrapperStyle={{ justifyContent: 'center' }}
                  label={t('profile.firstName')}
                  value={user.firstName}
                />
              )}
              {!!user.lastName && (
                <TextInput
                  disabled
                  inputValueStyle={{ textTransform: 'capitalize' }}
                  extraWrapperStyle={{ justifyContent: 'center' }}
                  label={t('profile.lastName')}
                  value={user.lastName}
                />
              )}
              {!!user.email && (
                <TextInput
                  disabled
                  extraWrapperStyle={{ justifyContent: 'center' }}
                  label={t('profile.email')}
                  value={user.email}
                />
              )}
              {!!user.dob && (
                <TextInput
                  disabled
                  extraWrapperStyle={{ justifyContent: 'center' }}
                  label={t('profile.dateOfBirth')}
                  value={getDOB(user.dob)}
                />
              )}
              {!!user?.phones &&
                user?.phones?.length > 0 &&
                user?.phones.map((numberList, index) => {
                  return (
                    <TextInput
                      disabled
                      extraWrapperStyle={{ justifyContent: 'center' }}
                      key={`${numberList} + ${index}`}
                      label={t(`profile.${numberList.label}`)}
                      value={numberList.value}
                    />
                  );
                })}
              {!!user.street && (
                <TextInput
                  disabled
                  inputValueStyle={{ textTransform: 'capitalize' }}
                  extraWrapperStyle={{ justifyContent: 'center' }}
                  label={t('profile.street')}
                  value={user.street}
                />
              )}
              {!!user.region && (
                <TextInput
                  disabled
                  inputValueStyle={{ textTransform: 'capitalize' }}
                  extraWrapperStyle={{ justifyContent: 'center' }}
                  label={t('profile.region')}
                  value={user.region}
                />
              )}
              {!!user.postalCode && (
                <TextInput
                  disabled
                  extraWrapperStyle={{ justifyContent: 'center' }}
                  label={t('profile.postalCode')}
                  value={user.postalCode}
                />
              )}
              {!!user.city && (
                <TextInput
                  disabled
                  inputValueStyle={{ textTransform: 'capitalize' }}
                  extraWrapperStyle={{ justifyContent: 'center' }}
                  label={t('profile.city')}
                  value={user.city}
                />
              )}
              {!!user.country && (
                <TextInput
                  disabled
                  inputValueStyle={{ textTransform: 'capitalize' }}
                  extraWrapperStyle={{ justifyContent: 'center' }}
                  label={t('profile.country')}
                  value={user.country}
                />
              )}
            </>
          )}
        </ContentWrapper>
      </SidebarContent>
    </Sidebar>
  );
};

export default ViewMember;
