import Lottie from 'react-lottie';
import React, { FC } from 'react';
import selectUser from 'static/animations/selectuser.json';
import { PlaceholderWrapper, ImageWrapper, Text, Link } from './styled';
import { useTranslation } from 'react-i18next';
interface placeholderProps {
  text: string;
  imageUrl: string;
  linkText: string;
  openCreateChatPopUp: () => void;
}

const Placeholder: FC<placeholderProps> = ({
  imageUrl,
  text,
  linkText,
  openCreateChatPopUp,
}) => {
  const { t } = useTranslation();
  return (
    <PlaceholderWrapper>
      <ImageWrapper>
        <Lottie
          options={{
            animationData: selectUser,
            loop: true,
            autoplay: true,
          }}
          width="400px"
          height="400px"
        />
      </ImageWrapper>
      <Text>{text}</Text>
      {linkText && (
        <Text>
          {t('chat.emptyStatus.noChatSelected.secondLine')}{' '}
          <Link onClick={openCreateChatPopUp}>{linkText}</Link>
        </Text>
      )}
    </PlaceholderWrapper>
  );
};

export default Placeholder;
