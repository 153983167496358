import React, { FC, useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  Button,
  DefaultCommunityImage,
  NumberInput,
  PopupEventWrapper,
  TextArea,
  Tooltip,
  EventShareOptionsTooltip,
} from 'components';
import { getDateString, getTimeInterval } from 'utils';
import { generatePdf } from 'utils/pdf';
import { FIELDS, ReplyFormData, useReplyForm } from './form';
import { SHARE_POPUP } from '../../constants/popups';
import { useSelector, useDispatch } from 'react-redux';
import { getSettings } from '../../store/selectors/auth';
import { openModal } from 'store/actionCreators/modal';
import {
  CommunityListItem,
  EventReply,
  EventResponse,
  EventTask,
  EventType,
  FullUser,
  MyAppointmentsEvent,
} from 'types';
import { EditEventType } from 'constants/sidebars';
import TasksList from './TasksList';
import ReplyBlock from './ReplyBlock';
import { CommunityTitle, GroupTitle, EventCard } from './styled';
import { HighlightText } from 'pages/PersonalChat/ChatUserList';
interface EventCardProps {
  event: MyAppointmentsEvent;
  currentUser: FullUser;
  community: CommunityListItem;
  eventResponses: { [p: string]: EventResponse[] };
  tasks: EventTask[];
  eventTypes: EventType[];
  isLoading: boolean;
  zoom: number;
  onSubmit: (
    type: EditEventType,
    data: ReplyFormData,
    event: MyAppointmentsEvent,
    reply: {
      reply: EventReply & { tasks: string[] };
      communityResponse: EventResponse;
    },
  ) => void;
  onEventHeaderClick: (event: MyAppointmentsEvent) => void;
  onEventClick: (event: MyAppointmentsEvent) => void;
  search: string;
}

const EventCardComponent: FC<EventCardProps> = ({
  event,
  currentUser,
  community,
  eventResponses,
  tasks,
  eventTypes,
  isLoading,
  zoom = 1,
  onSubmit,
  onEventHeaderClick,
  onEventClick,
  search,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settings = useSelector(getSettings);

  const uniqueEventReplies = useMemo((): EventReply[] => {
    const userIds = [...new Set(event.subEventReplies.map((r) => r.userId))];

    return userIds
      .map((id) => {
        const userReplies = event.subEventReplies.filter(
          (r) => r.userId === id,
        );

        userReplies.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );

        if (userReplies.length > 0) {
          return userReplies[0];
        }

        return null;
      })
      .filter((r) => !!r);
  }, [event]);

  const usersEventReply: {
    reply: EventReply & { tasks: string[] };
    communityResponse: EventResponse;
  } = useMemo(() => {
    const reply = uniqueEventReplies.find((r) => r.userId === currentUser.id);
    const taskReplies = event.subEventTaskReplies.filter(
      (r) => r.userId === currentUser.id,
    );
    const communityResponse = reply
      ? eventResponses[event.community?.id || event.group?.community?.id]?.find(
          (r) => r.id === reply.eventResponseId,
        )
      : null;

    return {
      reply: {
        ...reply,
        tasks: taskReplies.map((r) => r.eventTaskId),
      },
      communityResponse,
    };
  }, [event, uniqueEventReplies, eventResponses]);

  const [responsesVisible, setResponsesVisible] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    errors,
    formState: { isDirty },
    guestsFieldProps,
    replyFieldProps,
    tasksFieldProps,
    reset,
    getValues,
    values,
  } = useReplyForm(usersEventReply);

  useEffect(() => {
    if (!usersEventReply?.reply?.eventResponseId && !event.isDisabled) {
      setResponsesVisible(true);
    }
  }, []);

  useEffect(() => {
    if (usersEventReply?.reply?.eventResponseId) {
      setResponsesVisible(false);
    }
  }, [uniqueEventReplies]);

  useEffect(() => {
    const {
      reply: {
        eventResponseId = null,
        comment = '',
        tasks = [],
        guests = 0,
        penalty = false,
        attended = false,
      },
    } = usersEventReply;

    reset({
      [FIELDS.EVENT_RESPONSE_ID]: eventResponseId,
      [FIELDS.COMMENT]: comment,
      [FIELDS.TASKS]: tasks,
      [FIELDS.GUESTS]: guests,
      [FIELDS.PENALTY]: penalty,
      [FIELDS.ATTENDED]: attended,
    });
  }, [event]);

  const submitButtonDisabled = useMemo(() => {
    if (isLoading) {
      return true;
    }

    if (!isDirty) {
      //If for was not changed
      return true;
    } else {
      if (replyFieldProps.value) {
        const eventResponse = eventResponses[
          event.community?.id || event.group?.community?.id
        ]?.find((r) => r.id === replyFieldProps.value);

        if (eventResponse.isCommentRequired && !values.comment) {
          //If user selected response, where comment is required, but there's no comment entered
          return true;
        }
      } else if (!usersEventReply?.reply?.eventResponseId) {
        //If user did not select any response and there was no selected response before
        //(if there was a selected response before, button must be shown, because in that case user is trying to delete an existing response)
        return true;
      }
    }

    return false;
  }, [isDirty, isLoading, replyFieldProps]);

  const submitForm = (type: EditEventType) => {
    handleSubmit((data) => {
      onSubmit(type, data, event, usersEventReply);
    })();
  };

  const editPopupButtons = [
    {
      popupButtonText: t('event.eventOperations.eventOnly'),
      handleConfirm: () => submitForm(EditEventType.SINGLE_EVENT),
    },
    {
      popupButtonText: t('event.eventOperations.eventAndFollowUp'),
      handleConfirm: () => submitForm(EditEventType.SINGLE_AND_FOLLOW),
    },
    {
      popupButtonText: t('event.eventOperations.all'),
      handleConfirm: () => submitForm(EditEventType.FULL_EVENT),
    },
  ];

  const handleExportPdf = () => {
    return generatePdf(
      event,
      community,
      eventTypes,
      (eventResponses && eventResponses[community.id]) || [],
      tasks,
      settings.timeFormat,
      true,
    );
  };

  const communityImage: JSX.Element = useMemo(() => {
    if (event.community?.logo) {
      return <img src={event.community.logo} />;
    } else if (event.group?.community?.logo) {
      return <img src={event.group.community.logo} />;
    }

    return (
      <DefaultCommunityImage
        title={event.community?.name || event.group?.community?.name}
        zoom={zoom}
      />
    );
  }, [event, zoom]);

  return (
    <EventCard zoom={zoom}>
      <form>
        <EventCard.Community
          zoom={zoom}
          onClick={() => onEventHeaderClick(event)}
        >
          <EventCard.Community.ImageBlock zoom={zoom}>
            {communityImage}
          </EventCard.Community.ImageBlock>
          <EventCard.Community.TitleBlock zoom={zoom}>
            <Tooltip
              text={event.community?.name || event.group?.community?.name}
              zoom={zoom}
              isVisible
              tooltipStyle={{
                fontSize: 12 * zoom,
                height: 23 * zoom + 'px',
              }}
              visibleAfterWidth={185 * zoom}
              offset={10 * zoom}
              position="top-center"
            >
              <CommunityTitle zoom={zoom}>
                {event.community?.name || event.group?.community?.name}
              </CommunityTitle>
            </Tooltip>
            {event.group && (
              <GroupTitle zoom={zoom}>{event.group?.name}</GroupTitle>
            )}
          </EventCard.Community.TitleBlock>
        </EventCard.Community>
        <EventCard.Event
          color={event.eventType?.color}
          zoom={zoom}
          onClick={() => onEventClick(event)}
        >
          <EventCard.Event.Header>
            <Tooltip
              text={event.eventType?.title}
              zoom={zoom}
              isVisible
              tooltipStyle={{
                fontSize: 12 * zoom,
                height: 23 * zoom + 'px',
              }}
              visibleAfterWidth={235 * zoom}
              offset={10 * zoom}
              position="top-center"
            >
              <EventCard.Event.EventType zoom={zoom}>
                <span>{event.eventType?.title}</span>
              </EventCard.Event.EventType>
            </Tooltip>
            <div
              className="grid-header-share"
              style={{
                right: 8 * zoom,
                top: 10 * zoom,
                position: 'initial',
              }}
            >
              <EventShareOptionsTooltip
                zoom={zoom}
                onShareClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    openModal({
                      type: SHARE_POPUP,
                      data: {
                        eventId: event.id,
                      },
                    }),
                  );
                }}
                onExportPDFClick={handleExportPdf}
              />
            </div>
          </EventCard.Event.Header>
          <Tooltip
            text={event.title}
            zoom={zoom}
            isVisible
            tooltipStyle={{
              fontSize: 12 * zoom,
              height: 23 * zoom + 'px',
            }}
            visibleAfterHeight={Math.floor(18 * zoom) * 3}
            offset={35 * zoom}
            position="top-center"
          >
            <EventCard.Event.EventTitle zoom={zoom}>
              <HighlightText searchText={search} text={event.title as string} />
            </EventCard.Event.EventTitle>
          </Tooltip>
          <EventCard.Event.Footer>
            <div>
              <EventCard.Event.DateTime zoom={zoom}>
                {`${t(
                  `dayOfWeek.day${moment(event.from).day()}`,
                )}, ${getDateString(new Date(event.from))}`}
              </EventCard.Event.DateTime>
              {!event.allDay && (
                <EventCard.Event.DateTime zoom={zoom}>
                  {getTimeInterval(
                    new Date(event.from),
                    new Date(event.to),
                    settings.timeFormat,
                  )}
                </EventCard.Event.DateTime>
              )}
            </div>
            {event.description && (
              <Tooltip
                text={event.description}
                zoom={zoom}
                isVisible
                tooltipStyle={{
                  fontSize: 12 * zoom,
                  maxWidth: 200 * zoom,
                  whiteSpace: 'pre-wrap',
                  overflow: 'hidden',
                  lineBreak: 'normal',
                }}
                position="top-center"
              >
                <div
                  className="icon-description"
                  style={{
                    width: 20 * zoom,
                    height: 20 * zoom,
                    fontSize: 12 * zoom + 'px',
                    borderWidth: 2 * zoom,
                  }}
                >
                  <span>i</span>
                </div>
              </Tooltip>
            )}
          </EventCard.Event.Footer>
        </EventCard.Event>
        <EventCard.GreyDivider zoom={zoom} />
        <TasksList
          tasks={tasks}
          zoom={zoom}
          event={event}
          tasksFieldProps={tasksFieldProps}
        />
        <EventCard.GreyDivider zoom={zoom} />
        <ReplyBlock
          eventResponses={eventResponses}
          event={event}
          zoom={zoom}
          replyFieldProps={replyFieldProps}
          usersEventReply={usersEventReply}
          responsesVisible={responsesVisible}
          setResponsesVisible={setResponsesVisible}
          getValues={getValues}
        />
        <TextArea
          width="100%"
          label={t('common.addComment')}
          name={FIELDS.COMMENT}
          disabled={
            event.isDisabled && !usersEventReply?.reply?.eventResponseId
          }
          register={register}
          error={errors[FIELDS.COMMENT]}
          zoom={zoom}
          rows={Math.floor(zoom * 5)}
        />
        <EventCard.Divider zoom={zoom} />
        <NumberInput
          label={t('event.addGuests')}
          height={Math.floor(39 * zoom) + 'px'}
          width="100%"
          value={guestsFieldProps.value}
          zoom={zoom}
          onAddNumber={() => {
            guestsFieldProps.onChange(guestsFieldProps.value + 1);
          }}
          onSubtractNumber={() => {
            if (guestsFieldProps.value > 0) {
              guestsFieldProps.onChange(guestsFieldProps.value - 1);
            }
          }}
          disabled={event.isDisabled}
        />
        <EventCard.Footer zoom={zoom}>
          {event.eventSeriesId ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <PopupEventWrapper
              popupButtons={editPopupButtons}
              popupMessageText={t('event.eventOperations.editReplyText')}
            >
              <Button
                type="button"
                width="100%"
                disabled={submitButtonDisabled}
                zoom={zoom}
              >
                {t('common.answer')}
              </Button>
            </PopupEventWrapper>
          ) : (
            <Button
              type="button"
              width="100%"
              disabled={submitButtonDisabled}
              zoom={zoom}
              onClick={() => submitForm(EditEventType.SINGLE_EVENT)}
            >
              {t('common.answer')}
            </Button>
          )}
        </EventCard.Footer>
      </form>
    </EventCard>
  );
};

export default EventCardComponent;
