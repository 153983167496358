import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import {
  Sidebar,
  Button,
  SidebarFooter,
  SidebarContent,
  SidebarHeader,
  Loader,
  CommunityUsersByGroups,
} from 'components';
import {
  doClearCommunity,
  doRequestCommunityForChat,
} from 'store/actionCreators/currentCommunity';
import { closeModal } from 'store/actionCreators/modal';
import { getData } from 'store/selectors/modal';
import {
  getCurrentCommunity,
  getCurrentCommunityLoading,
} from 'store/selectors/currentCommunity';
import { AddGroupMembersData } from 'types';
import { Chat, ChatUser } from 'store/reducers/chat';
import { doUpdateChatGroup } from 'store/actionCreators/chat';

import { updateChatGroupLoading } from 'store/selectors/chat';
import { getMyId } from 'store/selectors/auth';
import { useParams } from 'react-router-dom';
import { SkeletonListWrapper } from 'sidebars/AboutGroup/styled';

const AddGroupMembersChat = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const userId = useSelector(getMyId);
  const { communityId: paramsCommunityId } = useParams<{
    communityId: string;
  }>();
  const currentCommunityLoading = useSelector(getCurrentCommunityLoading);
  const data = useSelector(getData) as AddGroupMembersData & {
    chat: Chat;
    members: ChatUser[];
    isUpdate: boolean;
    communityId: string;
    admin: string;
  };
  const { chat, isUpdate, communityId, members, admin } = data;

  const community = useSelector(getCurrentCommunity);
  const updateGroupLoading = useSelector(updateChatGroupLoading);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  useEffect(() => {
    if (members) {
      setSelectedUsers(members.map((m) => m.userId));
    }
  }, [members]);

  useEffect(() => {
    if (!paramsCommunityId) {
      dispatch(doRequestCommunityForChat({ communityId }));
    }
    return () => {
      if (!paramsCommunityId) {
        dispatch(doClearCommunity());
      }
    };
  }, [communityId]);

  const filteredMembersByGroups = useMemo(() => {
    if (community?.groups) {
      return (
        community.groups.map((g) => ({
          ...g,
          users: g.users,
        })) || []
      );
    }

    return [];
  }, [community?.groups, paramsCommunityId]);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const onSuccess = () => {
    handleClose();
    addToast('Group chat updated successfully', {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onSubmit = useCallback(() => {
    dispatch(
      doUpdateChatGroup.trigger({
        userId,
        chatId: chat.id,
        communityId,
        payload: {
          chatName: chat.chatName,
          description: chat.description,
          logo: chat.logo,
          members: selectedUsers,
        },
        onSuccess,
      }),
    );
  }, [selectedUsers, chat, communityId]);

  return (
    <Sidebar>
      <SidebarHeader
        label={isUpdate ? 'Update group members' : t('group.addParticipants')}
        onCloseClick={handleClose}
      />
      <SidebarContent fullHeight>
        {currentCommunityLoading ? (
          <SkeletonListWrapper>
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="skeleton skeleton-list" />
            ))}
          </SkeletonListWrapper>
        ) : (
          <CommunityUsersByGroups
            value={selectedUsers}
            membersByGroups={filteredMembersByGroups}
            users={community?.users || []}
            height="calc(100svh - 269px)"
            onChange={setSelectedUsers}
            disabledId={admin}
          />
        )}
        {/* {isEmpty(community.users) && !updateGroupLoading && (
          <ListPlaceholder>{t('common.noUsers')}</ListPlaceholder>
        )} */}
      </SidebarContent>
      <SidebarFooter>
        <Button
          disabled={isEmpty(community.users) || updateGroupLoading}
          onClick={onSubmit}
        >
          {updateGroupLoading ? (
            <Loader type="button" size="28px" thickness={2} />
          ) : isUpdate ? (
            'Update'
          ) : (
            t('group.addParticipants')
          )}
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
};

export default AddGroupMembersChat;
