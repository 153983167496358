import styled from 'styled-components';

export const StyledList = styled.div`
  position: relative;
`;

export const StyledMenuListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PlusButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  right: ${({ right = '5px' }) => right};
`;

export const StyledArrow = styled.div`
  display: flex;
  position: absolute;
  right: ${({ right = '5px' }) => right};
`;
