const CREATE_COMMUNITY = 'CREATE_COMMUNITY';
const ADD_GROUP_MEMBERS = 'ADD_GROUP_MEMBERS';
const CREATE_GROUP = 'CREATE_GROUP';
const CREATE_CHAT = 'CREATE_CHAT';
const EDIT_COMM_EVENT_TYPE_AND_DEFAULT_SETTING =
  'EDIT_COMM_EVENT_TYPE_AND_DEFAULT_SETTING';
const EDIT_COMMUNITY_RESPONSE = 'EDIT_COMMUNITY_RESPONSE';
const EDIT_COMMUNITY_TASK = 'EDIT_COMMUNITY_TASK';
const EDIT_PROFILE = 'EDIT_PROFILE';
const EDIT_USER_ROLE = 'EDIT_USER_ROLE';
const ADD_FIELD = 'ADD_FIELD';
const CREATE_EVENT = 'CREATE_EVENT';
const CREATE_EVENT_OVERALL = 'CREATE_EVENT_OVERALL';
const VIEW_MEMBER = 'VIEW_MEMBER';
const EVENT_DETAILS = 'EVENT_DETAILS';
const GIVE_REPLY = 'GIVE_REPLY';
const TASK_DETAILS = 'TASK_DETAILS';
const FULL_EVENT = 'FULL_EVENT';
const SINGLE_EVENT = 'SINGLE_EVENT';
const SINGLE_AND_FOLLOW = 'SINGLE_AND_FOLLOW';
const INVITE_MEMBER = 'INVITE_MEMBER';
const ADD_MEMBER = 'ADD_MEMBER';
const COMMUNITY_POPUP = 'COMMUNITY_POPUP';
const CHAT_POPUP = 'CHAT_POPUP';
const SUBSCRIPTION_OVER_POPUP = 'SUBSCRIPTION_OVER_POPUP';
const CREATE_GROUP_CHAT = 'CREATE_GROUP_CHAT';
const UPDATE_GROUP_CHAT = 'UPDATE_GROUP_CHAT';
const ABOUT_CHAT_GROUP = 'ABOUT_CHAT_GROUP';
const ADD_GROUP_MEMBERS_CHAT = 'ADD_GROUP_MEMBERS_CHAT';

export enum EditEventType {
  SINGLE_EVENT = 'SINGLE_EVENT',
  SINGLE_AND_FOLLOW = 'SINGLE_AND_FOLLOW',
  FULL_EVENT = 'FULL_EVENT',
}

export {
  CREATE_COMMUNITY,
  CREATE_GROUP,
  CREATE_GROUP_CHAT,
  CREATE_CHAT,
  ADD_GROUP_MEMBERS,
  EDIT_PROFILE,
  EDIT_USER_ROLE,
  ADD_FIELD,
  CREATE_EVENT,
  CREATE_EVENT_OVERALL,
  VIEW_MEMBER,
  EDIT_COMM_EVENT_TYPE_AND_DEFAULT_SETTING,
  EDIT_COMMUNITY_RESPONSE,
  EDIT_COMMUNITY_TASK,
  EVENT_DETAILS,
  GIVE_REPLY,
  TASK_DETAILS,
  FULL_EVENT,
  SINGLE_EVENT,
  SINGLE_AND_FOLLOW,
  INVITE_MEMBER,
  ADD_MEMBER,
  COMMUNITY_POPUP,
  CHAT_POPUP,
  ABOUT_CHAT_GROUP,
  SUBSCRIPTION_OVER_POPUP,
  ADD_GROUP_MEMBERS_CHAT,
  UPDATE_GROUP_CHAT,
};
