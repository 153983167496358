import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'static/icons/close.svg';

export const Container = styled.div`
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.color.porcelain};
  border-radius: 8px;
  width: 80vw;
  min-height: 55px;
  height: auto;
  padding: 10px 0 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3;
  position: sticky;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);

  a {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 55px;
  }
`;

export const Message = styled.div`
  color: ${(props) => props.theme.color.pickledBluewood};
  margin-left: 20px;
  display: flex;
  margin-right: 20%;
  font-size: 14px;
  flex: 1;
  flex-direction: column;
  overflow: hidden; /* Ensures the content doesn’t overflow the container */

  span {
    font-weight: 400;
    display: block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Bage = styled.div`
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ appearance, theme }) =>
    appearance === 'error'
      ? theme.color.bittersweet
      : theme.color.brightTurquoise};
`;

export const StyledClose = styled(CloseIcon)`
  user-select: none;
  inset: 0px;
  margin: auto;
`;

export const StyledImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 20px;
`;

export const CloseBtnWrapper = styled.div`
  padding: 12px;
  border-radius: 999999px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  position: absolute;
  z-index: 100000;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  :hover {
    background-color: rgb(207, 207, 207);
    svg {
      path {
        fill: ${({ theme }) => theme.color.white};
      }
    }
  }
`;
